import React, { useEffect, useState } from "react";
import { startApiCall } from "../../../Components/GlobalFunction/GlobalFunction";
import OtherApiCall from "../../../api/OtherApiCall";
import { UserRelatedEndpoints } from "../../../api/Endpoints";
import Skeleton from "@mui/material/Skeleton";
import { toast } from "react-toastify";

const UserInfo = ({ login_data }) => {
  const [userData, setUserData] = useState({});
  const [loader, setloader] = useState(false);
  const [loader2, setloader2] = useState(false);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");

  const handleGetUserData = async () => {
    startApiCall(null, setloader);
    const data = {
      page: 1,
      limit: 5,
      filters: {
        role: "customer",
        isDeleted: false,
        _id: login_data?.user?._id,
      },
    };
    const res = await OtherApiCall(
      "post",
      UserRelatedEndpoints.getUserData,
      data
    );
    if (res?.success) {
      const user = res.result.data[0];
      setUserData(user);
      setName(user?.name || "");
      setEmail(user?.email || "");
      setPhone(user?.mobile_no || "");
      sessionStorage.setItem("username", user?.name);
      setloader(false);
    } else {
      setloader(false);
    }
  };

  useEffect(() => {
    handleGetUserData();
  }, []);

  const handleEditUser = async () => {
    startApiCall(null, setloader2);
    const data = {
      staffId: userData?._id,
      name,
      email,
      mobile_no: phone,
      is_active: true,
      role: "customer",
    };
    const res = await OtherApiCall(
      "post",
      UserRelatedEndpoints.editStaff,
      data
    );
    if (res.success) {
      toast.success("Profile update successfully!");
      handleGetUserData();
      setloader2(false);
    } else {
      setloader2(false);
    }
  };

  return (
    <div className="card rounded-10p custom-card-shadow h-100">
      <div className="card-body">
        <p className="h4 text-capitalize text-primary-dark-theme mb-3">
          User Information :
        </p>
        {loader && (
          <>
            <Skeleton variant="text" sx={{ fontSize: "1.5rem" }} height={50} />
            <Skeleton variant="text" sx={{ fontSize: "1.5rem" }} height={50} />
            <Skeleton variant="text" sx={{ fontSize: "1.5rem" }} height={50} />
            <div className="d-flex justify-content-end">
              <Skeleton
                variant="text"
                sx={{ fontSize: "1.5rem" }}
                width={100}
                height={50}
              />
            </div>
          </>
        )}
        {!loader && (
          <div className="d-flex flex-column gap-3 mt-0 shadow-none">
            <input
              type="text"
              className="form-control border-0 border-bottom w-100"
              placeholder="Full name"
              required
              value={name}
              onChange={(event) => setName(event.target.value)}
            />
            <input
              type="email"
              className="form-control border-0 border-bottom w-100"
              placeholder="Email address"
              value={email}
              disabled
              onChange={(event) => setEmail(event.target.value)}
            />
            <input
              type="number"
              className="form-control border-0 border-bottom w-100"
              placeholder="Contact"
              required
              value={phone}
              onChange={(event) => setPhone(event.target.value)}
            />
            <div className="d-flex justify-content-end">
              <button
                className="btn btn-rounded theme-btn-primary px-4"
                type="submit"
                onClick={handleEditUser}
              >
                {loader2 ? "Submitting......" : "Submit"}
              </button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default UserInfo;
