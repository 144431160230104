import React, { useState } from "react";
import Upcoming from "./Upcoming";
import Completed from "./Completed";
import Canceled from "./Canceled";

const Bookings = ({ login_data }) => {
  const [isSelected, setIsSelected] = useState("upcoming");

  return (
    <div className="card rounded-10p custom-card-shadow h-100">
      <div className="card-body">
        <ul class="nav nav-tabs">
          <li class="nav-item">
            <span
              className={`nav-link pointer ${
                isSelected === "upcoming"
                  ? "text-primary-theme active"
                  : "text-secondary"
              }`}
              aria-current="page"
              onClick={() => setIsSelected("upcoming")}
            >
              Upcoming
            </span>
          </li>
          <li class="nav-item">
            <span
              className={`nav-link pointer ${
                isSelected === "completed"
                  ? "text-primary-theme active"
                  : "text-secondary"
              }`}
              aria-current="page"
              onClick={() => setIsSelected("completed")}
            >
              Completed
            </span>
          </li>
          <li class="nav-item">
            <span
              className={`nav-link pointer ${
                isSelected === "cancel"
                  ? "text-primary-theme active"
                  : "text-secondary"
              }`}
              aria-current="page"
              onClick={() => setIsSelected("cancel")}
            >
              Cancel
            </span>
          </li>
        </ul>
        <div className="pt-1">
          {isSelected === "upcoming" && <Upcoming login_data={login_data} />}
          {isSelected === "completed" && <Completed login_data={login_data} />}
          {isSelected === "cancel" && <Canceled login_data={login_data} />}
        </div>
      </div>
    </div>
  );
};

export default Bookings;
