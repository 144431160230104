import React from "react";

const FlightsPageLoader = ({ origin, destination}) => {
  return (
    <div class="loader-container">
      <div class="row align-items-center justify-conter-center w-100">
        <div class="col-xs-12">
          <div class="row">
            <div class="col-xs-12 departure_city w-100">{origin}</div>
            <div class="col-xs-12 loader-earth">
              <div class="earth-wrapper">
                <div class="earth"></div>
              </div>
              <div class="plane">
                <img
                  src="https://zupimages.net/up/19/34/4820.gif"
                  class="plane-img"
                  loading="lazy"
                />
              </div>
            </div>
            <div class="col-xs-12 arrival_city w-100">{destination}</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FlightsPageLoader;
