import React from "react";
import StarRating from "../../Components/StarRating/StarRating";

const BeautifulPlaces = () => {
  const cardArray = [
    {
      imgUrl:
        "https://images.unsplash.com/photo-1454496522488-7a8e488e8606?q=80&w=1776&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
      title: "Paris",
      rating: 5,
      dasc: "Paris, France's capital, is synonymous with romance, iconic landmarks like the Eiffel.",
    },
    {
      imgUrl:
        "https://images.unsplash.com/photo-1454496522488-7a8e488e8606?q=80&w=1776&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
      title: "Paris",
      rating: 5,
      dasc: "Paris, France's capital, is synonymous with romance, iconic landmarks like the Eiffel.",
    },
    {
      imgUrl:
        "https://images.unsplash.com/photo-1454496522488-7a8e488e8606?q=80&w=1776&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
      title: "Paris",
      rating: 5,
      dasc: "Paris, France's capital, is synonymous with romance, iconic landmarks like the Eiffel.",
    },
  ];

  return (
    <div className="custom-container py-4">
      <h4 className="text-primary-dark-theme mb-0">Explore the</h4>
      <p className="text-primary-dark-theme mb-3 h1 fw-bold">
        Beautiful Places
      </p>
      <div className="row g-3">
        {cardArray?.map((card) => {
          return (
            <div className="col-lg-4 col-md-6" key={card?.title}>
              <div
                className="card rounded-10p overflow-hidden"
                style={{ maxWidth: "22rem", marginInline: "auto" }}
              >
                <img
                  loading="lazy"
                  src={card?.imgUrl}
                  className="card-img-top"
                  alt="..."
                />
                <div className="card-body">
                  <h5 className="card-title mb-0">{card?.title}</h5>
                  <StarRating initialRating={card?.rating} />
                  <p className="card-text mb-0 text-secondary">{card?.dasc}</p>
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default BeautifulPlaces;
