import React from "react";

const TermsAndConditions = () => {
  return (
    <div className="bg-theme-secondary custom-container py-3">
      <div className="container d-flex flex-column gap-3">
        <div className="card rounded-10p custom-card-shadow">
          <div className="card-body">
            <p className="card-title text-primary-dark-theme h2 border-doubled-theme mb-3">
              Terms & Conditions
            </p>
            <div className="mb-4">
              <p className="mb-2 fw-bold">
                RELATING TO THE USAGE OF www.nilhans.com
              </p>
              <p className="mb-2 text-secondary">
                These Terms and Conditions (“the Terms and Conditions”) govern
                your (“the User”) use ofthe website located at the domain
                www.NILHANS.com (“the Website”), which for the purposes of this
                agreement will include all of the information and content
                contained therein.
              </p>
              <p className="mb-2 text-secondary">
                There are terms and conditions set apart for the purchase of
                travel related products as well as reservations as you are bound
                by terms and condition of NILHANS.
              </p>
              <p className="mb-2 text-secondary">
                In making any payments and abiding by the regulations and
                caveats in connection with availability, use of products and
                fares, you are bound by the terms and conditions of usage.
              </p>
              <p className="mb-2 text-secondary">
                By accessing and using the Website, the User agrees to be bound
                by the Terms and Conditions set out in this agreement.
              </p>
            </div>
            <div className="mb-4">
              <p className="mb-2 fw-bold text-uppercase">Change to Agreement</p>
              <p className="mb-2 text-secondary">
                NILHANS reserves the right to make changes to the Website and
                the Terms and Conditions at any time without notice.
              </p>
            </div>
            <div className="mb-4">
              <p className="mb-2 fw-bold text-uppercase">
                Copyright & Intellectual Property Rights
              </p>
              <p className="mb-2 text-secondary">
                The Website, which for the avoidance of any doubt includes all
                information and content therein (including text, graphics, logos
                and icons), is the intellectual property of NILHANS, and as such
                is protected by United States and International Intellectual
                Property Law. All rights in and to the Website are reserved and
                retained by NILHANS.
              </p>
              <p className="mb-2 text-secondary">
                Except as specified in these Terms and Conditions, the User is
                not granted a license or any other right including without
                limitation under Copyright, Trademark, Patent or other
                Intellectual Property Rights in or to the Website.
              </p>
              <p className="mb-2 text-secondary">
                NILHANS grants to the User, subject to the further terms of
                these Terms and Conditions, a non-exclusive, non-transferable,
                limited and revocable right to access, display, use, download
                and otherwise copy the current and future content for personal,
                non-commercial and information purposes only.
              </p>
              <p className="mb-2 text-secondary">
                This Website may not be reproduced, duplicated, copied, resold,
                visited or otherwise exploited for any commercial or non-private
                purpose without the express prior written consent of NILHANS.
              </p>
            </div>
            <div className="mb-4">
              <p className="mb-2 fw-bold text-uppercase">Your Account</p>
              <p className="mb-2 text-secondary">
                If you utilize the Website, you are accountable for maintaining
                the confidentiality of your account and password and you accept
                responsibility for all activities that happen under your account
                and password. You also accept not to reveal any personally
                identifiable information, consisting of, however not limited to,
                first and last names, credentials, or various other details of a
                personal nature ("Personal Data") from the Site. Your disclosure
                of any Personal Data on the website might result in the
                immediate termination of your account. NILHANS additionally
                reserves the right to refuse service, terminate accounts, and
                remove or edit Content at its sole discernment.
              </p>
              <p className="mb-2 text-secondary">
                NILHANS does not guarantee the truthfulness or represent,
                precision, or dependability of Content on the site, consisting
                of Personal Data. Each Individual is accountable for upgrading
                and changing any pertinent account info when essential to
                preserve the truthfulness, precision, or reliability of the
                details.
              </p>
            </div>
            <div className="mb-4">
              <p className="mb-2 fw-bold text-uppercase">Legal Compliance</p>
              <p className="mb-2 text-secondary">
                You agree to comply with all applicable domestic and
                international laws, statutes, ordinances, and regulations
                regarding your use of our Website. NILHANS reserves the right to
                investigate complaints or reported violations of our Legal Terms
                and to take any action we deem appropriate, including but not
                limited to canceling your Member account, reporting any
                suspected unlawful activity to law enforcement officials,
                regulators, or other third parties and disclosing any
                information necessary or appropriate to such persons or entities
                relating to your profile, email addresses, usage history, posted
                materials, IP addresses and traffic information, as allowed
                under our Privacy Policy.
              </p>
            </div>
            <div className="mb-4">
              <p className="mb-2 fw-bold text-uppercase">
                NO SHOW POLICY ON SCHEDULED FLIGHTS
              </p>
              <p className="mb-2 text-secondary">
                If you have booked a scheduled carrier and choose not to travel
                on the flights in the same order / sequence as booked, then your
                return flight will automatically be cancelled by the airline.
                The price you have paid is based on the routing you originally
                booked. Once the airline have cancelled the ticket, then it
                cannot be reinstated or refunded. This is not something that we
                can change, it is an automated process that happens with the
                airlines directly. Therefore, if you know you cannot travel on
                the flights in the order booked you may need to amend or cancel
                the booking. This will be done under the ticket rules as you
                purchased.
              </p>
            </div>
            <div className="mb-4">
              <p className="mb-2 fw-bold text-uppercase">TRAVEL LOCATIONS</p>
              <p className="mb-2 text-secondary">
                Please be aware that the degrees of travel risk in each country
                varies and that natural disasters could coincide with your
                travel itinerary. You are therefore urged to take all necessary
                precautions, and measures as well as heed to announcements by
                relevant authorities. NILHANS will not be held responsible for
                the injuries suffered, nor loss or damage that may arise out of
                travel to places of risk. NILHANS will not be held to
                responsible for acts of God, force majeure, acts of government
                or authority that might impede your travel.
              </p>
              <p className="mb-2 text-secondary">
                Wakanow, its subsidiaries, agents, partners do not represent
                that the dissemination of information through this website would
                be accurate. Wakanow would take all responsible steps to
                transmit up to date information on its website but would not
                held responsible for errors on pages, booking prices, products
                and packages. We will take all responsible steps to correct
                errors made on pricing list and reservation made based on those
                errors and we grant that you will be able to keep such
                reservation at the available price out at your option and at no
                cost.
              </p>
              <p className="mb-2 text-secondary">
                The choice for opting for any of these products and services
                contained on this website are yours andNILHANS is not obliged
                and does not represent or make recommendations of products and
                services displayed on its website.
              </p>
            </div>
            <div className="mb-4">
              <p className="mb-2 fw-bold text-uppercase">Payment Terms</p>
              <p className="mb-2 text-secondary">
                The full payment is due immediately by Visa, Master Card, or
                Amex Card , Electronic Funds Transfer, Cash Deposit at a bank or
                whichever is applicable. If the full payment is not received by
                on the day that the Booking was made, NILHANS reserves the full
                right to cancel the Booking. In the event that payment has been
                made after the aforementioned deadline, you will be liable for
                any difference in price if you still want to proceed with the
                Booking at the altered price.
              </p>
              <p className="mb-2 text-secondary">
                NILHANS is a American company and all transactions are processed
                in United States Dollars. All payments shall therefore be made
                in the equivalent local currency in accordance with the
                prevailing conversion rate as published from time to time.
                Therefore conversion charges shall be levied if payment is made
                from another country and/or in another currency other than
                United Staes Dollars. This fee is driven by the global treasury
                of the applicable credit card and is impacted by the change in
                daily exchange rates.
              </p>
            </div>
            <div className="mb-4">
              <p className="mb-2 fw-bold text-uppercase">
                Valid Travel Documents
              </p>
              <p className="mb-2 text-secondary">
                It is the entirely the your responsibility to ensure that all
                passports and visas are current, valid, obtained on time, and
                will be valid for six months after return to their home country
                and that any vaccinations, inoculations, prophylactic (e.g. for
                malaria) and the like, where required, have been obtained. You
                are strongly advised to check the relevant requirements with
                NILHANS before travelling.
              </p>
            </div>
            <div id="cancel_refund" className="mb-4">
              <p className="mb-2 fw-bold text-uppercase">
                Cancel/Refund/Exchange Policy
              </p>
              <p className="mb-2 text-secondary">
                Most of our airline tickets are 100% non-refundable however if
                you cancel within 24 hrs of booking it is completely refundable
                if the airlines allows us. In certain cases where the airline
                may allow cancellations, a credit may be valid towards a future
                ticket purchase by the same traveler on the same airline.
                Usually the credit issued by the airline supplier has a specific
                expiration date, after which it cannot be used. We encourage you
                to discuss additional restrictions attached to your credit with
                a customer service agent. All such bookings where the
                cancellation may be permitted must be cancelled prior to the
                scheduled departure time of the first flight segment by calling
                our customer service center. We do not guarantee any
                cancellation. When you are ready to make your new booking and
                wish to use your airline credit, you will be required to the pay
                fare difference (if any), applicable airline penalties and any
                applicable NILHANS post-ticketing fees. All such changes are
                governed by each airlines fare rules, policies and procedures,
                which are not under our control.
              </p>
              <p className="mb-2 text-secondary">
                No additional representation is made for our exchange fees
                except that an agent will assist you in locating your desired
                new flights and attempt to re-book the new flights based on
                availability and other factors.
              </p>
            </div>
            <div id="baggage_policy" className="mb-4">
              <p className="mb-2 fw-bold text-uppercase">
                Baggage Policy and Fees
              </p>
              <p className="mb-2 text-secondary">
                If you have excess baggage, you will have to pay any excess
                baggage fee assessed by each airline. Most airlines now charge
                baggage fees even for the first bag checked-In, we recommend
                traveling light to reduce these costs. To locate the fees on
                baggage check-in by airlines, please visit our "Baggage Fees"
                page or look for a link baggage fees on our website. A baggage
                fee range from 15 USD up to 50 USD or more depending on the size
                and weight of the bag and is per checked bag. These fees are to
                be paid directly to airline upon using such service. We make an
                effort to keep the baggage fees table updated but we recommend
                you contact the airline directly for the latest fees on check
                baggage and policy as it relates to baggage.
              </p>
              <p className="mb-2 text-secondary">
                When there are two or more airlines involved for connecting
                flights then you may have to reclaim your bags at the connecting
                airport and check-in again to continue your journey. You are
                also advised that in these cases if you have excess baggage, you
                will have to pay any excess baggage fee assessed by each
                airline. Most airlines now charge baggage fees even for the
                first bag checked-In, we recommend traveling light to reduce
                these costs. To locate the fees on baggage check-in by airlines,
                please visit our "Baggage Fees" page or look for a link baggage
                fees on our website. Baggage fees range from 15 USD up to 100
                USD or more depending on the size and weight of the bag and is
                per checked bag. These fees are to be paid directly to airline
                upon using such service. We make an effort to keep the baggage
                fees table updated but we recommend you contact the airline
                directly for the latest fees on check baggage and policy as it
                relates to baggage.
              </p>
            </div>
            <div id="charges" className="mb-4">
              <p className="mb-2 fw-bold text-uppercase">
                Fare Changes And Post Payment Price Guarantee
              </p>
              <p className="mb-2 text-secondary">
                Prior to your form of payment being processed and accepted
                successfully, if there is a change in the price of air fare or
                any other change, you may be notified of this change and only
                upon such notification you have the right to either accept or
                decline this transaction. If you elect to decline this
                transaction, you will not be charged.
              </p>
              <p className="mb-2 text-secondary">
                Our Post Payment Price Guarantee: Upon successful acceptance and
                processing of your payment (credit/debit card), we guarantee
                that we will honor the total final quoted price of the airline
                tickets regardless of any changes or fluctuation in the price of
                air fare.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TermsAndConditions;
