import React, { useState, useEffect } from "react";
import FirstStep from "./FirstStep";
import SecondStep from "./SecondStep";
import { Link, useNavigate } from "react-router-dom";
import dayjs from "dayjs";
import {
  calculateTotalDuration,
  convertToISOFormat,
  startApiCall,
} from "../../Components/GlobalFunction/GlobalFunction";
import ApiCall from "../../api/ApiCall";
import { FlightRelatedEndpoints } from "../../api/Endpoints";
import { toast } from "react-toastify";
import ThirdStep from "./ThirdStep";
import PriceDetailsCard from "./PriceDetailsCard";

const Checkout = () => {
  const navigate = useNavigate();
  const login_data = JSON.parse(sessionStorage.getItem("login"));
  const [loader, setloader] = useState(false);
  const [errMsg, setErrMsg] = useState(false);
  const originLocationCode = sessionStorage.getItem("originLocationCode");
  const destinationLocationCode = sessionStorage.getItem(
    "destinationLocationCode"
  );
  const wayOfFlight = sessionStorage.getItem("wayOfFlight");
  const adults = sessionStorage.getItem("adults");
  const departureDate = sessionStorage.getItem("departureDate");
  const returnDate = sessionStorage.getItem("returnDate");
  const children = sessionStorage.getItem("children");
  const infants = sessionStorage.getItem("infants");
  const travelClass = sessionStorage.getItem("travelClass");
  const currencyCode = sessionStorage.getItem("currencyCode");

  const [bookingData, setBookingData] = useState([]);
  const [travellerPricing, setTravellerPricing] = useState([]);
  const [firstStep, setFirstStep] = useState(true);
  const [secondStep, setSecondStep] = useState(false);
  const [thirdStep, setThridStep] = useState(false);
  const bookingDetails = JSON.parse(sessionStorage.getItem("booking_details"));
  const [travellerDetails, settravellerDetails] = useState([]);
  const [isChecked, setIsChecked] = useState(false);
  const [isRefundable, setIsRefundable] = useState("false");
  const [isAddPack, setIsAddPack] = useState(false);
  const [totalPrice, setTotalPrice] = useState(0);

  const totalPassengers =
    parseInt(sessionStorage.getItem("adults")) +
    parseInt(sessionStorage.getItem("children")) +
    parseInt(sessionStorage.getItem("infants"));

  const initialFirstStep = () => {
    setFirstStep(true);
    setSecondStep(false);
    setThridStep(false);
  };

  const completeFirstStep = () => {
    setFirstStep(false);
    setSecondStep(true);
    setThridStep(false);
  };

  const completeSecondStep = () => {
    setFirstStep(false);
    setSecondStep(false);
    setThridStep(true);
  };

  useEffect(() => {
    if (bookingDetails && bookingDetails.price) {
      let newPrice = parseFloat(bookingDetails.price);
      if (isRefundable === "true") {
        const additionalCharge = bookingDetails?.price / totalPassengers / 6;
        newPrice += parseFloat((totalPassengers * additionalCharge).toFixed(2));
      } else {
        setTotalPrice(newPrice);
      }
      if (isAddPack) {
        newPrice += parseFloat(19.89);
      } else {
        setTotalPrice(newPrice?.toFixed(2));
      }
      setTotalPrice(newPrice?.toFixed(2));
    }
  }, [isRefundable, bookingDetails, totalPassengers, isAddPack]);

  // card and billing details
  const [cardNumberDigit, setcardNumberDigit] = useState(0);
  const [cardDetails, setcardDetails] = useState({
    name: "",
    holderName: "",
    number: "",
    expDate: "",
    cvv: "",
  });
  const [billingDetails, setbillingDetails] = useState({
    name: "",
    email: "",
    phone: "",
    country: "",
    countrycode: "",
    address: "",
    state: "",
    city: "",
    zip: "",
  });

  function isValidCardNumber(cardNumber) {
    if (!cardNumber) return false;
    const cleanedNumber = cardNumber.replace(/\s/g, "");
    const startsWithCorrectDigit = {
      "American express": cleanedNumber.startsWith("3"),
      Discover: cleanedNumber.startsWith("6"),
      "Master card": cleanedNumber.startsWith("5"),
      Visa: cleanedNumber.startsWith("4"),
    }[cardDetails?.name];

    const validLength =
      cardDetails?.name === "American express"
        ? cleanedNumber.length === 15
        : cleanedNumber.length === 16;

    return startsWithCorrectDigit && validLength && /^\d+$/.test(cleanedNumber);
  }

  const isBillingDisabled = Object.values(billingDetails).some(
    (value) => value === ""
  );
  const isCardDisabled = Object.values(cardDetails).some(
    (value) => value === ""
  );

  const isDisabled = travellerDetails?.some((details) =>
    Object.entries(details).some(
      ([key, value]) => key !== "middleName" && value === ""
    )
  );

  const isAllEmpty =
    travellerDetails?.length === 1 &&
    travellerDetails?.[0] &&
    Object.values(travellerDetails?.[0]).every((value) => value === "");

  const adultsCount = parseInt(sessionStorage.getItem("adults"), 10) || 0;
  const childrenCount = parseInt(sessionStorage.getItem("children"), 10) || 0;
  const infantsCount = parseInt(sessionStorage.getItem("infants"), 10) || 0;

  const passengerLabels = [
    ...Array(adultsCount).fill("Adult"),
    ...Array(childrenCount).fill("Child"),
    ...Array(infantsCount).fill("Infant"),
  ];

  useEffect(() => {
    let temp = [];
    for (let index = 0; index < totalPassengers; index++) {
      temp = passengerLabels.map((label) => ({
        firstName: "",
        middleName: "",
        lastName: "",
        date: "",
        gender: "",
        travellerType: label,
      }));
    }
    settravellerDetails(temp);
  }, [totalPassengers]);

  const handleChangeTravellerDetails = (
    e,
    selectedIndex,
    inputType = "input"
  ) => {
    const updatedTravellerDetails = [...travellerDetails];

    if (inputType === "input") {
      const { name, value } = e.target;
      updatedTravellerDetails[selectedIndex] = {
        ...updatedTravellerDetails[selectedIndex],
        [name]: value,
      };
    } else if (inputType === "datepicker") {
      const currentDate = dayjs();
      const selectedDate = dayjs(e);

      if (selectedDate.isAfter(currentDate)) {
        alert("Selected date cannot be greater than the current date.");
        return;
      }

      updatedTravellerDetails[selectedIndex] = {
        ...updatedTravellerDetails[selectedIndex],
        date: selectedDate,
      };
    } else if (inputType === "monthChange") {
      updatedTravellerDetails[selectedIndex] = {
        ...updatedTravellerDetails[selectedIndex],
        currentMonth: e,
      };
    } else if (inputType === "radio") {
      const { name, value } = e.target;
      updatedTravellerDetails[selectedIndex] = {
        ...updatedTravellerDetails[selectedIndex],
        [name.split("-")[0]]: value,
      };
    }

    settravellerDetails(updatedTravellerDetails);
  };

  useEffect(() => {
    if (bookingDetails) {
      const firstItinerary = {
        duration: calculateTotalDuration(
          bookingDetails?.flights?.[0]?.flightDetails
        ),
        segments: bookingDetails?.flights?.[0]?.flightDetails?.map((data) => ({
          departure: {
            iataCode: data?.flightInformation?.[0]?.location?.[0]?.locationId,
            at: convertToISOFormat(
              data?.flightInformation?.[0]?.productDateTime?.[0]
                ?.dateOfDeparture?.[0],
              data?.flightInformation?.[0]?.productDateTime?.[0]
                ?.timeOfDeparture?.[0]
            ),
          },
          arrival: {
            iataCode: data?.flightInformation?.[0]?.location?.[1]?.locationId,
            at: convertToISOFormat(
              data?.flightInformation?.[0]?.productDateTime?.[0]
                ?.dateOfArrival?.[0],
              data?.flightInformation?.[0]?.productDateTime?.[0]
                ?.timeOfArrival?.[0]
            ),
          },
          carrierCode:
            data?.flightInformation?.[0]?.companyId?.[0]?.customCarriers
              ?.airline_code,
          number: data?.flightInformation?.[0]?.flightOrtrainNumber?.[0],
          airline:
            data?.flightInformation?.[0]?.companyId?.[0]?.customCarriers
              ?.airline_name,
          airlineImage:
            data?.flightInformation?.[0]?.companyId?.[0]?.customCarriers?.image,
          blacklistedInEU: false,
        })),
      };

      const secondItinerary =
        wayOfFlight !== "one_way" && bookingDetails?.flights?.[1]
          ? {
              duration: calculateTotalDuration(
                bookingDetails?.flights?.[1]?.flightDetails
              ),
              segments: bookingDetails?.flights?.[1]?.flightDetails?.map(
                (data) => ({
                  departure: {
                    iataCode:
                      data?.flightInformation?.[0]?.location?.[0]?.locationId,
                    at: convertToISOFormat(
                      data?.flightInformation?.[0]?.productDateTime?.[0]
                        ?.dateOfDeparture?.[0],
                      data?.flightInformation?.[0]?.productDateTime?.[0]
                        ?.timeOfDeparture?.[0]
                    ),
                  },
                  arrival: {
                    iataCode:
                      data?.flightInformation?.[0]?.location?.[1]?.locationId,
                    at: convertToISOFormat(
                      data?.flightInformation?.[0]?.productDateTime?.[0]
                        ?.dateOfArrival?.[0],
                      data?.flightInformation?.[0]?.productDateTime?.[0]
                        ?.timeOfArrival?.[0]
                    ),
                  },
                  carrierCode:
                    data?.flightInformation?.[0]?.companyId?.[0]?.customCarriers
                      ?.airline_code,
                  number:
                    data?.flightInformation?.[0]?.flightOrtrainNumber?.[0],
                  airline:
                    data?.flightInformation?.[0]?.companyId?.[0]?.customCarriers
                      ?.airline_name,
                  airlineImage:
                    data?.flightInformation?.[0]?.companyId?.[0]?.customCarriers
                      ?.image,
                  blacklistedInEU: false,
                })
              ),
            }
          : null;

      const transformedData = {
        itineraries: [firstItinerary, secondItinerary].filter(Boolean),
        price: {
          currency: "USD",
          total: totalPrice?.toString(),
          base: totalPrice?.toString(),
          fees: [],
          grandTotal: totalPrice?.toString(),
          discount: bookingDetails?.flights?.[0]?.propFlightGrDetail?.[0]
            ?.recommendation?.recPriceInfo?.[0]?.customPrice?.markUp
            ?.markup_amount
            ? parseFloat(
                bookingDetails?.flights?.[0]?.propFlightGrDetail?.[0]
                  ?.recommendation?.recPriceInfo?.[0]?.customPrice?.markUp
                  ?.markup_amount
              )?.toString()
            : "0",
        },
      };

      setBookingData(transformedData);
    }
  }, [totalPrice]);

  useEffect(() => {
    if (bookingDetails) {
      const data = [
        {
          travelerType:
            bookingDetails?.flights?.[0]?.propFlightGrDetail?.[0]
              ?.recommendation?.paxFareProduct?.[0]?.paxReference?.[0]
              ?.ptc?.[0],
          price: {
            currency: "USD",
            total:
              wayOfFlight === "round_trip"
                ? (
                    parseFloat(
                      bookingDetails?.flights?.[0]?.propFlightGrDetail?.[0]
                        ?.recommendation?.paxFareProduct?.[0]
                        ?.paxFareDetail?.[0]?.totalFareAmount?.[0]
                    ) +
                    parseFloat(
                      bookingDetails?.flights?.[1]?.propFlightGrDetail?.[0]
                        ?.recommendation?.paxFareProduct?.[0]
                        ?.paxFareDetail?.[0]?.totalFareAmount?.[0]
                    )
                  ).toString()
                : bookingDetails?.flights?.[0]?.propFlightGrDetail?.[0]
                    ?.recommendation?.paxFareProduct?.[0]?.paxFareDetail?.[0]
                    ?.totalFareAmount?.[0],
            base:
              wayOfFlight === "round_trip"
                ? (
                    parseFloat(
                      bookingDetails?.flights?.[0]?.propFlightGrDetail?.[0]
                        ?.recommendation?.paxFareProduct?.[0]
                        ?.paxFareDetail?.[0]?.totalFareAmount?.[0]
                    ) +
                    parseFloat(
                      bookingDetails?.flights?.[1]?.propFlightGrDetail?.[0]
                        ?.recommendation?.paxFareProduct?.[0]
                        ?.paxFareDetail?.[0]?.totalFareAmount?.[0]
                    )
                  ).toString()
                : bookingDetails?.flights?.[0]?.propFlightGrDetail?.[0]
                    ?.recommendation?.paxFareProduct?.[0]?.paxFareDetail?.[0]
                    ?.totalFareAmount?.[0],
          },
        },
        {
          travelerType:
            bookingDetails?.flights?.[0]?.propFlightGrDetail?.[0]
              ?.recommendation?.paxFareProduct?.[1]?.paxReference?.[0]
              ?.ptc?.[0],
          price: {
            currency: "USD",
            total:
              wayOfFlight === "round_trip"
                ? (
                    parseFloat(
                      bookingDetails?.flights?.[0]?.propFlightGrDetail?.[0]
                        ?.recommendation?.paxFareProduct?.[1]
                        ?.paxFareDetail?.[0]?.totalFareAmount?.[0]
                    ) +
                    parseFloat(
                      bookingDetails?.flights?.[1]?.propFlightGrDetail?.[0]
                        ?.recommendation?.paxFareProduct?.[1]
                        ?.paxFareDetail?.[0]?.totalFareAmount?.[0]
                    )
                  ).toString()
                : bookingDetails?.flights?.[0]?.propFlightGrDetail?.[0]
                    ?.recommendation?.paxFareProduct?.[1]?.paxFareDetail?.[0]
                    ?.totalFareAmount?.[0],
            base:
              wayOfFlight === "round_trip"
                ? (
                    parseFloat(
                      bookingDetails?.flights?.[0]?.propFlightGrDetail?.[0]
                        ?.recommendation?.paxFareProduct?.[1]
                        ?.paxFareDetail?.[0]?.totalFareAmount?.[0]
                    ) +
                    parseFloat(
                      bookingDetails?.flights?.[1]?.propFlightGrDetail?.[0]
                        ?.recommendation?.paxFareProduct?.[1]
                        ?.paxFareDetail?.[0]?.totalFareAmount?.[0]
                    )
                  ).toString()
                : bookingDetails?.flights?.[0]?.propFlightGrDetail?.[0]
                    ?.recommendation?.paxFareProduct?.[1]?.paxFareDetail?.[0]
                    ?.totalFareAmount?.[0],
          },
        },
        {
          travelerType:
            bookingDetails?.flights?.[0]?.propFlightGrDetail?.[0]
              ?.recommendation?.paxFareProduct?.[2]?.paxReference?.[0]
              ?.ptc?.[0],
          price: {
            currency: "USD",
            total:
              wayOfFlight === "round_trip"
                ? (
                    parseFloat(
                      bookingDetails?.flights?.[0]?.propFlightGrDetail?.[0]
                        ?.recommendation?.paxFareProduct?.[2]
                        ?.paxFareDetail?.[0]?.totalFareAmount?.[0]
                    ) +
                    parseFloat(
                      bookingDetails?.flights?.[1]?.propFlightGrDetail?.[0]
                        ?.recommendation?.paxFareProduct?.[2]
                        ?.paxFareDetail?.[0]?.totalFareAmount?.[0]
                    )
                  ).toString()
                : bookingDetails?.flights?.[0]?.propFlightGrDetail?.[0]
                    ?.recommendation?.paxFareProduct?.[2]?.paxFareDetail?.[0]
                    ?.totalFareAmount?.[0],
            base:
              wayOfFlight === "round_trip"
                ? (
                    parseFloat(
                      bookingDetails?.flights?.[0]?.propFlightGrDetail?.[0]
                        ?.recommendation?.paxFareProduct?.[2]
                        ?.paxFareDetail?.[0]?.totalFareAmount?.[0]
                    ) +
                    parseFloat(
                      bookingDetails?.flights?.[1]?.propFlightGrDetail?.[0]
                        ?.recommendation?.paxFareProduct?.[2]
                        ?.paxFareDetail?.[0]?.totalFareAmount?.[0]
                    )
                  ).toString()
                : bookingDetails?.flights?.[0]?.propFlightGrDetail?.[0]
                    ?.recommendation?.paxFareProduct?.[2]?.paxFareDetail?.[0]
                    ?.totalFareAmount?.[0],
          },
        },
      ];

      if (sessionStorage.getItem("infants") === "0") {
        data.pop();
      }

      if (sessionStorage.getItem("children") === "0") {
        data.splice(1, 1);
      }

      setTravellerPricing(data);
    }
  }, []);

  const handleAddBillInfo = async () => {
    startApiCall(setErrMsg, setloader);
    let { name, email, phone, address, state, city, zip } = billingDetails;
    let { holderName, number, expDate, cvv } = cardDetails;
    const data = {
      billingDetails: {
        name,
        email,
        phone,
        address,
      },
      cardDetails: {
        name: cardDetails?.name,
        holderName,
        number,
        expDate,
        cvv,
      },
      travellerDetails: travellerDetails,
      flightDetails: {
        wayOfFlight,
        originLocationCode,
        destinationLocationCode,
        adults,
        departureDate,
        returnDate,
        children,
        infants,
        travelClass,
        currencyCode,
        other_details: bookingData,
        booking_steps: "step_one",
        checkout_title: "Billing Information",
        payment_method: "card",
        showSubscribeModal: false,
      },
      user_id: login_data ? login_data.user._id : "",
      wayOfFlight,
      originLocationCode,
      destinationLocationCode,
      adults,
      departureDate,
      returnDate,
      children,
      infants,
      travelClass,
      currencyCode,
      isOnline: true,
      travelerPricings: travellerPricing,
      state,
      city,
      zip,
    };
    const res = await ApiCall("post", FlightRelatedEndpoints.addBillInfo, data);
    if (res?.data) {
      setloader(false);
      toast.success("Booking created successfully!");
      sessionStorage.setItem("billInfos", JSON.stringify(res?.data));
      sessionStorage.removeItem("booking_login");
      navigate("/booksuccessfully");
    } else {
      setloader(false);
      setErrMsg(res?.error);
    }
  };

  return (
    <div className="bg-theme-secondary custom-container py-3">
      <div className="mt-0 shadow-none">
        <div className="d-flex align-items-center gap-3 mb-3">
          <Link
            to="/flights"
            className="btn btn-icon theme-btn-outline-primary border border-primary-theme"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              fill="currentColor"
              className="bi bi-chevron-left"
              viewBox="0 0 16 16"
            >
              <path
                fill-rule="evenodd"
                d="M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0"
              />
            </svg>
          </Link>
          <div className="breadcrumbs">
            <button className="breadcrumb-item" onClick={initialFirstStep}>
              <span className="label">Details</span>
              <span className="breadcrumb-after"></span>
            </button>
            <button
              className={`breadcrumb-item ${
                isDisabled ? "breadcrumb-disabled" : ""
              }`}
              disabled={firstStep ? isDisabled : ""}
              onClick={completeFirstStep}
            >
              <span className="label">Add ons</span>
              <span className="breadcrumb-after"></span>
            </button>
            <button
              className={`breadcrumb-item ${
                firstStep ? "breadcrumb-disabled" : ""
              }`}
              disabled={firstStep ? isDisabled : ""}
              onClick={completeSecondStep}
            >
              <span className="label">Billing</span>
              <span className="breadcrumb-after"></span>
            </button>
          </div>
        </div>
        <div className="row g-3">
          <div className="col-lg-8 col-md-7">
            <>
              {firstStep && (
                <FirstStep
                  bookingDetails={bookingDetails}
                  wayOfFlight={wayOfFlight}
                  travellerDetails={travellerDetails}
                  handleChangeTravellerDetails={handleChangeTravellerDetails}
                  passengerLabels={passengerLabels}
                />
              )}
              {secondStep && (
                <SecondStep
                  isRefundable={isRefundable}
                  setIsRefundable={setIsRefundable}
                  totalPassengers={totalPassengers}
                  bookingDetails={bookingDetails}
                  isAddPack={isAddPack}
                  setIsAddPack={setIsAddPack}
                />
              )}
              {thirdStep && (
                <ThirdStep
                  bookingDetails={bookingDetails}
                  wayOfFlight={wayOfFlight}
                  cardDetails={cardDetails}
                  setcardDetails={setcardDetails}
                  setcardNumberDigit={setcardNumberDigit}
                  cardNumberDigit={cardNumberDigit}
                  billingDetails={billingDetails}
                  setbillingDetails={setbillingDetails}
                  isChecked={isChecked}
                  setIsChecked={setIsChecked}
                  isValidCardNumber={isValidCardNumber}
                />
              )}
            </>
          </div>
          <div className="col-lg-4 col-md-5">
            <div className="d-flex flex-column gap-3">
              {thirdStep && (
                <div className="card rounded-10p bg-white">
                  <div className="card-body">
                    <div className="d-flex flex-column gap-2">
                      <p className="mb-0 h5 d-flex align-items-center">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="24"
                          height="24"
                          fill="currentColor"
                          className="bi bi-person text-primary-theme"
                          viewBox="0 0 16 16"
                        >
                          <path d="M8 8a3 3 0 1 0 0-6 3 3 0 0 0 0 6m2-3a2 2 0 1 1-4 0 2 2 0 0 1 4 0m4 8c0 1-1 1-1 1H3s-1 0-1-1 1-4 6-4 6 3 6 4m-1-.004c-.001-.246-.154-.986-.832-1.664C11.516 10.68 10.289 10 8 10s-3.516.68-4.168 1.332c-.678.678-.83 1.418-.832 1.664z" />
                        </svg>
                        &nbsp;Traveler Details
                      </p>
                      <div className="d-flex gap-1 text-primary-dark-theme">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="14"
                          height="14"
                          fill="currentColor"
                          className="bi bi-passport-fill"
                          style={{ marginTop: "1px" }}
                          viewBox="0 0 16 16"
                        >
                          <path d="M8 6a2 2 0 1 0 0 4 2 2 0 0 0 0-4" />
                          <path d="M2 3.252a1.5 1.5 0 0 1 1.232-1.476l8-1.454A1.5 1.5 0 0 1 13 1.797v.47A2 2 0 0 1 14 4v10a2 2 0 0 1-2 2H4a2 2 0 0 1-1.51-.688 1.5 1.5 0 0 1-.49-1.11V3.253ZM5 8a3 3 0 1 0 6 0 3 3 0 0 0-6 0m0 4.5a.5.5 0 0 0 .5.5h5a.5.5 0 0 0 0-1h-5a.5.5 0 0 0-.5.5" />
                        </svg>
                        <p className="mb-0 fs-sm">
                          Please ensure you have a valid visa and your passport
                          is valid for at least 6 months from the date of travel
                        </p>
                      </div>
                      <div
                        className="d-flex flex-column gap-3 overflow-auto"
                        style={{ maxHeight: "446px" }}
                      >
                        {travellerDetails?.map((data, index) => (
                          <div className="bg-light px-2 py-1 rounded-10p">
                            <p className="mb-0 d-flex align-items-center gap-1 text-primary-theme">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="16"
                                height="16"
                                fill="currentColor"
                                className="bi bi-person"
                                viewBox="0 0 16 16"
                              >
                                <path d="M8 8a3 3 0 1 0 0-6 3 3 0 0 0 0 6m2-3a2 2 0 1 1-4 0 2 2 0 0 1 4 0m4 8c0 1-1 1-1 1H3s-1 0-1-1 1-4 6-4 6 3 6 4m-1-.004c-.001-.246-.154-.986-.832-1.664C11.516 10.68 10.289 10 8 10s-3.516.68-4.168 1.332c-.678.678-.83 1.418-.832 1.664z" />
                              </svg>
                              Traveler {index + 1}
                            </p>
                            <p className="mb-0">
                              <span className="text-primary-dark-theme">
                                Name :&nbsp;
                              </span>
                              {data?.firstName}&nbsp;{data?.middleName}&nbsp;
                              {data?.lastName}
                            </p>
                            <p className="mb-0">
                              <span className="text-primary-dark-theme">
                                Date of Birth :&nbsp;
                              </span>
                              {dayjs(data?.date).format("MM/DD/YYYY")}
                            </p>
                            <p className="mb-0">
                              <span className="text-primary-dark-theme">
                                Gender :&nbsp;
                              </span>
                              {data?.gender}
                            </p>
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                </div>
              )}

              <PriceDetailsCard
                bookingDetails={bookingDetails}
                wayOfFlight={wayOfFlight}
                firstStep={firstStep}
                isRefundable={isRefundable}
                isAddPack={isAddPack}
                totalPassengers={totalPassengers}
                totalPrice={totalPrice}
              />
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-lg-8 col-md-7 d-flex justify-content-end mt-2">
            <button
              className="btn theme-btn-primary btn-rounded text-nowrap"
              disabled={
                firstStep
                  ? isDisabled
                  : thirdStep
                  ? isBillingDisabled ||
                    isCardDisabled ||
                    !isChecked ||
                    !isValidCardNumber(cardDetails?.number)
                  : ""
              }
              onClick={() => {
                if (firstStep) {
                  setFirstStep(false);
                  setSecondStep(true);
                  setThridStep(false);
                } else if (secondStep) {
                  setFirstStep(false);
                  setSecondStep(false);
                  setThridStep(true);
                } else if (thirdStep) {
                  handleAddBillInfo();
                }
              }}
            >
              {firstStep || secondStep
                ? "Continue"
                : loader
                ? "Loading...."
                : "Submit"}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Checkout;
