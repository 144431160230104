import React, { useState } from "react";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";

const FlightsRootModal = ({
  handleToggle,
  fromInputRef,
  toInputRef,
  state,
  handleChange,
  apiToken,
  setState,
  handleUpdate,
}) => {
  const handleTripChange = (tripType) => {
    // sessionStorage.setItem("wayOfFlight", tripType);
    handleChange("trip", tripType);
  };

  const swapValues = () => {
    setState((prevState) => ({
      ...prevState,
      city_from: prevState.city_to,
      iatacode_from: prevState.iatacode_to,
      airport_name_from: prevState.airport_name_to,
      country_from: prevState.country_to,
      city_to: prevState.city_from,
      iatacode_to: prevState.iatacode_from,
      airport_name_to: prevState.airport_name_from,
      country_to: prevState.country_from,
    }));
    setLocationFrom(state.iatacode_to);
    setLocationTo(state.iatacode_from);
  };

  const [locationFrom, setLocationFrom] = useState(state.iatacode_from);
  const [fromData, setFromData] = useState([]);
  const [locationTo, setLocationTo] = useState(state.iatacode_to);
  const [toData, setToData] = useState([]);

  const searchFrom = (val) => {
    if (val === locationTo) {
      alert('origin and destination locations cannot be the same.');
      setLocationFrom("");
      setState((prevState) => ({
        ...prevState,
        airport_name_from: '',
        country_from: '',
      }));
      return;
    } 
    setLocationFrom(val);

    fetch(
      "https://test.api.amadeus.com/v1/reference-data/locations?subType=CITY,AIRPORT&page[limit]=5&keyword=" +
        val,
      {
        method: "GET",
        headers: new Headers({
          Authorization: `Bearer ${apiToken}`,
        }),
      }
    )
      .then((response) => response.json())
      .then((res) => {
        if (res) {
          const data = res.data;
          for (let i = 0; i < data?.length; i++) {
            if (val === data[i].iataCode) {
              sessionStorage.setItem("iatacode_from", data[i].iataCode);
              sessionStorage.setItem("airport_name_from", data[i].name);
              sessionStorage.setItem("country_from", data[i].address.countryCode);
              sessionStorage.setItem("city_from", data[i].address.cityName);
              setState((prevState) => ({
                ...prevState,
                iatacode_from: data[i].iataCode,
                airport_name_from: data[i].name,
                country_from: data[i].address.countryCode,
                city_from: data[i].address.cityName,
              }));
            }
          }
          setFromData(data);
        } else {
          alert(JSON.stringify(res));
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const searchTo = (val) => {
    if (val === locationFrom) {
      alert('origin and destination locations cannot be the same.');
      setLocationTo("");
      setState((prevState) => ({
        ...prevState,
        airport_name_to: '',
        country_to: '',
      }));
      return;
    } 
    setLocationTo(val);
    fetch(
      "https://test.api.amadeus.com/v1/reference-data/locations?subType=CITY,AIRPORT&page[limit]=5&keyword=" +
        val,
      {
        method: "GET",
        headers: new Headers({
          Authorization: `Bearer ${apiToken}`,
        }),
      }
    )
      .then((response) => response.json())
      .then((res) => {
        if (res) {
          const data = res.data;
          for (let i = 0; i < data?.length; i++) {
            if (val === data[i].iataCode) {
              sessionStorage.setItem("iatacode_to", data[i].iataCode);
              sessionStorage.setItem("airport_name_to", data[i].name);
              sessionStorage.setItem("country_to", data[i].address.countryCode);
              sessionStorage.setItem("city_to", data[i].address.cityName);
              setState((prevState) => ({
                ...prevState,
                iatacode_to: data[i].iataCode,
                airport_name_to: data[i].name,
                country_to: data[i].address.countryCode,
                city_to: data[i].address.cityName,
              }));
            }
          }
          setToData(data);
        } else {
          alert(JSON.stringify(res));
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  return (
    <div
      className="modal fade"
      id="flight_root_modal"
      tabindex="-1"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div
        className="modal-dialog"
        style={{
          marginTop: 0,
          marginInline: 0,
          maxWidth: "100%",
        }}
      >
        <div className="modal-content">
          <div className="modal-body container">
            <div className="d-flex align-items-start justify-content-between gap-3">
              <ul className="d-flex align-items-center gap-3 list-unstyled">
                <li>
                  <span
                    className={`btn rounded-10p ${
                      state.trip === "one_way"
                        ? "theme-btn-outline-primary-dark"
                        : "btn-light"
                    }`}
                    onClick={() => handleTripChange("one_way")}
                  >
                    One way
                  </span>
                </li>
                <li>
                  <span
                    className={`btn rounded-10p ${
                      state.trip === "round_trip"
                        ? "theme-btn-outline-primary-dark"
                        : "btn-light"
                    }`}
                    onClick={() => handleTripChange("round_trip")}
                  >
                    Round trip
                  </span>
                </li>
              </ul>
              <span
                className="btn theme-btn-outline-primary-dark rounded-10p"
                data-bs-toggle="modal"
                data-bs-target="#add_passengers_modal"
              >
                {parseInt(state.adult) +
                  parseInt(state.child) +
                  parseInt(state.infants)}{" "}
                Traveller, {state.t_class_selected}
              </span>
            </div>
            <div className="row">
              <div className="col-lg-6">
                <div className="d-flex align-items-end gap-2">
                  <div className="d-flex flex-column gap-1 w-100">
                    <label htmlFor="" className="mb-0 text-secondary">
                      From
                    </label>
                    <input
                      type="text"
                      className="form-control rounded-10p"
                      required
                      ref={fromInputRef}
                      list="fromdata"
                      value={locationFrom}
                      onChange={(e) => searchFrom(e.target.value)}
                    />
                    {/* <p className="text-secondary">
                      {state.airport_name_from}&nbsp;
                      {state.country_from}
                    </p> */}
                    <datalist id="fromdata">
                      {fromData?.length > 0 && (
                        <>
                          {fromData.map((item) => (
                            <option key={item.iataCode} value={item.iataCode}>
                              {item.name}, {item.address.cityName}{" "}
                              {item.address.countryName}
                            </option>
                          ))}
                        </>
                      )}
                    </datalist>
                  </div>
                  <span
                    className="btn btn-icon theme-btn-primary rounded-10p"
                    onClick={swapValues}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="currentColor"
                      className="bi bi-arrow-down-up"
                      viewBox="0 0 16 16"
                      style={{ rotate: "90deg" }}
                    >
                      <path
                        fill-rule="evenodd"
                        d="M11.5 15a.5.5 0 0 0 .5-.5V2.707l3.146 3.147a.5.5 0 0 0 .708-.708l-4-4a.5.5 0 0 0-.708 0l-4 4a.5.5 0 1 0 .708.708L11 2.707V14.5a.5.5 0 0 0 .5.5m-7-14a.5.5 0 0 1 .5.5v11.793l3.146-3.147a.5.5 0 0 1 .708.708l-4 4a.5.5 0 0 1-.708 0l-4-4a.5.5 0 0 1 .708-.708L4 13.293V1.5a.5.5 0 0 1 .5-.5"
                      />
                    </svg>
                  </span>
                  <div className="d-flex flex-column gap-1 w-100">
                    <label htmlFor="" className="mb-0 text-secondary">
                      To
                    </label>
                    <input
                      type="text"
                      className="form-control rounded-10p"
                      required
                      ref={toInputRef}
                      list="todata"
                      value={locationTo}
                      onChange={(e) => searchTo(e.target.value)}
                    />
                    {/* <p className="text-secondary">
                          {state.airport_name_to}&nbsp;
                          {state.country_to}
                        </p> */}
                    <datalist id="todata">
                      {toData?.length > 0 && (
                        <>
                          {toData.map((item) => (
                            <option key={item.iataCode} value={item.iataCode}>
                              {item.name}, {item.address.cityName}{" "}
                              {item.address.countryName}
                            </option>
                          ))}
                        </>
                      )}
                    </datalist>
                  </div>
                </div>
                <div className="d-flex justify-content-between gap-3 ">
                  <div className="w-100 px-2 pt-1">
                    <p className="fs-sm mb-0 fw-bold">
                      {state.airport_name_from}&nbsp;
                      {state.country_from}
                    </p>
                  </div>
                  <div className="w-100 pt-1" style={{paddingLeft: "2.2rem"}}>
                    <p className="fs-sm mb-0 fw-bold">
                      {state.airport_name_to}&nbsp;
                      {state.country_to}
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="row g-3">
                  <div className="col-md-4 col-sm-6">
                    <div className="d-flex flex-column gap-1">
                      <label htmlFor="" className="mb-0 text-secondary">
                        Departure
                      </label>
                      <span
                        className="form-control rounded-10p"
                        style={{ paddingBlock: "1px" }}
                      >
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <DatePicker
                            value={state.departuredate}
                            onChange={(newValue) =>
                              handleChange("departuredate", newValue)
                            }
                            views={["year", "month", "day"]}
                            minDate={dayjs()}
                            renderInput={(params) => (
                              <input
                                {...params}
                                className="banner-form-input w-100"
                              />
                            )}
                          />
                        </LocalizationProvider>
                      </span>
                    </div>
                  </div>
                  <div className="col-md-4 col-sm-6">
                    {state.trip === "round_trip" && (
                      <div className="d-flex flex-column gap-1">
                        <label htmlFor="" className="mb-0 text-secondary">
                          Return
                        </label>
                        <span
                          className="form-control rounded-10p"
                          style={{ paddingBlock: "1px" }}
                        >
                          <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DatePicker
                              value={state.returndate}
                              onChange={(newValue) =>
                                handleChange("returndate", newValue)
                              }
                              views={["year", "month", "day"]}
                              minDate={dayjs(state.departuredate)}
                              renderInput={(params) => (
                                <input
                                  {...params}
                                  className="banner-form-input w-100"
                                />
                              )}
                            />
                          </LocalizationProvider>
                        </span>
                      </div>
                    )}
                  </div>
                  <div className="col-md-4 d-flex">
                    <div className="d-flex align-items-end w-100">
                      <button
                        data-bs-dismiss="modal"
                        className="btn rounded-10p theme-btn-primary w-100 d-flex align-items-center justify-content-center gap-2"
                        onClick={handleUpdate}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="16"
                          height="16"
                          fill="currentColor"
                          className="bi bi-search"
                          viewBox="0 0 16 16"
                        >
                          <path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001q.044.06.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1 1 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0" />
                        </svg>
                        Update
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FlightsRootModal;
