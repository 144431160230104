import React, { useState } from "react";
import Tooltip from "react-tooltip-lite";
import {
  calculateDuration,
  calculateTotalDuration,
  convertTimeFormat,
} from "../../Components/GlobalFunction/GlobalFunction";
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import utc from "dayjs/plugin/utc";
import allCountries from "../../Components/Countries";

dayjs.extend(customParseFormat);
dayjs.extend(utc);

const ThirdStep = ({
  bookingDetails,
  wayOfFlight,
  cardDetails,
  setcardDetails,
  setcardNumberDigit,
  cardNumberDigit,
  billingDetails,
  setbillingDetails,
  isChecked,
  setIsChecked,
  isValidCardNumber,
}) => {
  const [search, setSearch] = useState("");
  const [search1, setSearch2] = useState("");
  const filteredCountries = allCountries?.filter((con) =>
    con?.country?.toLowerCase()?.includes(search?.toLowerCase())
  );
  const filteredCountryCode = allCountries?.filter(
    (con) =>
      con?.code?.includes(search1) ||
      con?.country?.toLowerCase()?.includes(search1?.toLowerCase())
  );

  const handleChange = (event) => {
    const { value } = event.target;
    let formattedValue = value
      .replace(/\D/g, "")
      .replace(/(\d{2})(\d{0,2})/, "$1/$2")
      .slice(0, 5);

    if (formattedValue.length === 3 && value.length < formattedValue.length) {
      formattedValue = formattedValue.slice(0, 2);
    }
    const currentYear = new Date().getFullYear() % 100;
    const month = parseInt(formattedValue.slice(0, 2), 10);
    const year = parseInt(formattedValue.slice(3, 5), 10);
    if (month < 1 || month > 12) {
      formattedValue = formattedValue.slice(0, 2);
    }
    if (formattedValue.length === 5 && year < currentYear) {
      formattedValue = formattedValue.slice(0, 3);
    }
    setcardDetails({
      ...cardDetails,
      expDate: formattedValue,
    });
  };

  const handleChangeCvv = (event) => {
    const { value } = event.target;
    const formattedValue = value.replace(/\D/g, "").slice(0, 4);
    setcardDetails({
      ...cardDetails,
      cvv: formattedValue,
    });
  };

  const handleChangeNumber = (event) => {
    const inputValue = event.target.value.replace(/[^\d]/g, "");
    const formattedValue = inputValue.replace(/(\d{4})(?=\d)/g, "$1 ").trim();

    setcardDetails({
      ...cardDetails,
      number: formattedValue,
    });
  };

  const handleBillingDetails = (e) => {
    const { name, value } = e.target;
    // if (name === "phone" && value.length > 10) return;
    setbillingDetails({
      ...billingDetails,
      [name]: value,
    });
  };

  const handleCountrySelect = (code) => {
    setbillingDetails({ ...billingDetails, countrycode: code });
  };

  const handleClickCountry = (country) => {
    setbillingDetails({
      ...billingDetails,
      country: country,
    });
  };

  function isValidCardHolderName(holderName) {
    if (!holderName) return false;
    const nameRegex = /^[a-zA-Z\s]+$/;
    return (
      nameRegex.test(holderName) && holderName.trim().split(" ").length >= 2
    );
  }

  function isValidExpDate(expDate) {
    if (!expDate) return false;
    const [month, year] = expDate.split("/");
    const currentYear = new Date().getFullYear() % 100;
    return (
      month >= "01" &&
      month <= "12" &&
      year >= currentYear.toString() &&
      year <= (currentYear + 30).toString()
    );
  }

  function isValidCVV(cvv, cardName) {
    if (!cvv) return false;
    if (cardName === "American express") {
      return cvv.length === 4 && /^\d+$/.test(cvv);
    } else {
      return cvv.length === 3 && /^\d+$/.test(cvv);
    }
  }

  const isCardNoLength =
    cardDetails?.name === "American express"
      ? cardDetails?.number?.length >= 18
      : cardDetails?.number?.length >= 19;

  return (
    <>
      <div className="d-flex flex-column gap-3">
        <div className="card rounded-10p bg-white">
          <div className="card-body">
            <div className="d-flex flex-column gap-3">
              <div className="d-flex flex-column">
                <div className="d-flex justify-content-between gap-3">
                  <p className="mb-0 h5 d-flex align-items-center">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="20"
                      height="20"
                      fill="currentColor"
                      className="bi bi-geo-alt text-primary-theme"
                      viewBox="0 0 16 16"
                    >
                      <path d="M12.166 8.94c-.524 1.062-1.234 2.12-1.96 3.07A32 32 0 0 1 8 14.58a32 32 0 0 1-2.206-2.57c-.726-.95-1.436-2.008-1.96-3.07C3.304 7.867 3 6.862 3 6a5 5 0 0 1 10 0c0 .862-.305 1.867-.834 2.94M8 16s6-5.686 6-10A6 6 0 0 0 2 6c0 4.314 6 10 6 10" />
                      <path d="M8 8a2 2 0 1 1 0-4 2 2 0 0 1 0 4m0 1a3 3 0 1 0 0-6 3 3 0 0 0 0 6" />
                    </svg>
                    &nbsp;Departure
                  </p>
                  <p className="mb-0">
                    Airline Number :&nbsp;
                    <span className="text-primary-theme">
                      {
                        bookingDetails?.flights?.[0]?.flightDetails?.[0]
                          ?.flightInformation?.[0]?.flightOrtrainNumber?.[0]
                      }
                    </span>
                  </p>
                </div>
                <div className="d-flex align-items-center gap-3">
                  <img
                    loading="lazy"
                    className="checkout-page-flight-logo"
                    src={
                      bookingDetails?.flights?.[0]?.flightDetails?.[0]
                        ?.flightInformation?.[0]?.companyId?.[0]?.customCarriers
                        ?.image
                    }
                    alt=""
                  />
                  <p className="mb-0 text-uppercase">
                    {
                      bookingDetails?.flights?.[0]?.flightDetails?.[0]
                        ?.flightInformation?.[0]?.companyId?.[0]?.customCarriers
                        ?.airline_name
                    }
                  </p>
                </div>
                <div className="d-flex gap-3">
                  <div className="d-flex flex-column">
                    <div className="d-flex align-items-center gap-3">
                      <svg
                        width="44"
                        height="44"
                        viewBox="0 0 52 52"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <g clip-path="url(#clip0_2422_4485)">
                          <path
                            d="M30.3797 22.8141L38.8316 20.5494C39.7282 20.3092 40.6835 20.4349 41.4874 20.8991C42.2913 21.3632 42.8779 22.1277 43.1182 23.0243C43.3584 23.9209 43.2327 24.8763 42.7685 25.6802C42.3044 26.4841 41.5399 27.0707 40.6433 27.3109L15.2878 34.1049L7.49904 25.3215L12.5702 23.9627L16.8568 26.4376L21.9279 25.0788L15.3766 14.152L20.4477 12.7932L30.3797 22.8141Z"
                            stroke="#32CD32"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                          <path
                            d="M11.2031 39.2739H44.8031"
                            stroke="#32CD32"
                            stroke-linecap="round"
                          />
                        </g>
                        <defs>
                          <clipPath id="clip0_2422_4485">
                            <rect
                              width="42"
                              height="42"
                              fill="white"
                              transform="translate(0 10.8706) rotate(-15)"
                            />
                          </clipPath>
                        </defs>
                      </svg>
                      <div className="d-flex flex-column">
                        <p className="mb-0">
                          <span className="fw-bold">
                            {sessionStorage.getItem("city_from")}&nbsp;(
                            {
                              bookingDetails?.flights?.[0]?.flightDetails?.[0]
                                ?.flightInformation?.[0]?.location?.[0]
                                ?.locationId
                            }
                            )
                          </span>
                          &nbsp;
                          {dayjs(
                            bookingDetails?.flights?.[0]?.flightDetails?.[0]
                              ?.flightInformation?.[0]?.productDateTime?.[0]
                              ?.dateOfDeparture?.[0],
                            "DDMMYY"
                          ).format("DD/MM/YYYY")}
                        </p>
                        <p className="mb-0 fs-sm">
                          {convertTimeFormat(
                            bookingDetails?.flights?.[0]?.flightDetails?.[0]
                              ?.flightInformation?.[0]?.productDateTime?.[0]
                              ?.timeOfDeparture?.[0]
                          )}
                        </p>
                      </div>
                    </div>

                    <span
                      className="ms-4"
                      style={{
                        height: "20px",
                        borderLeft: "1px dashed #E1E1E1",
                      }}
                    ></span>
                    <div className="d-flex align-items-center gap-3">
                      <svg
                        style={{ marginLeft: "11px" }}
                        width="28"
                        height="28"
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M4 12.0005C4 14.1222 4.84285 16.1571 6.34315 17.6573C7.84344 19.1576 9.87827 20.0005 12 20.0005M4 12.0005C4 9.87876 4.84285 7.84393 6.34315 6.34363C7.84344 4.84334 9.87827 4.00049 12 4.00049M4 12.0005H2M12 20.0005C14.1217 20.0005 16.1566 19.1576 17.6569 17.6573C19.1571 16.1571 20 14.1222 20 12.0005M12 20.0005V22.0005M20 12.0005C20 9.87876 19.1571 7.84393 17.6569 6.34363C16.1566 4.84334 14.1217 4.00049 12 4.00049M20 12.0005H22M12 4.00049V2.00049M9 12.0005C9 12.7961 9.31607 13.5592 9.87868 14.1218C10.4413 14.6844 11.2044 15.0005 12 15.0005C12.7956 15.0005 13.5587 14.6844 14.1213 14.1218C14.6839 13.5592 15 12.7961 15 12.0005C15 11.2048 14.6839 10.4418 14.1213 9.87917C13.5587 9.31656 12.7956 9.00049 12 9.00049C11.2044 9.00049 10.4413 9.31656 9.87868 9.87917C9.31607 10.4418 9 11.2048 9 12.0005Z"
                          stroke="#32CD32"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                      </svg>
                      <div className="d-flex flex-column">
                        <p className="mb-0">
                          {bookingDetails?.flights?.[0]?.flightDetails
                            ?.length === 1 ? (
                            "Direct"
                          ) : (
                            <span className="pointer">
                              <Tooltip
                                content={
                                  bookingDetails?.flights?.[0]?.flightDetails &&
                                  bookingDetails?.flights?.[0]?.flightDetails
                                    ?.length > 0 &&
                                  bookingDetails?.flights?.[0]?.flightDetails
                                    .slice(
                                      0,
                                      bookingDetails?.flights?.[0]
                                        ?.flightDetails.length - 1
                                    )
                                    ?.map(
                                      (item, index) =>
                                        calculateDuration(
                                          item,
                                          item?.flightInformation?.[0]
                                            ?.productDateTime?.[0]
                                            ?.timeOfDeparture?.[0],
                                          item?.flightInformation?.[0]
                                            ?.productDateTime?.[0]
                                            ?.dateOfDeparture?.[0],
                                          item?.flightInformation?.[0]
                                            ?.productDateTime?.[0]
                                            ?.timeOfArrival?.[0],
                                          item?.flightInformation?.[0]
                                            ?.productDateTime?.[0]
                                            ?.dateOfArrival?.[0],
                                          index
                                        ) +
                                        (index < item?.flightDetails?.length - 2
                                          ? ", "
                                          : "")
                                    )
                                }
                              >
                                {bookingDetails?.flights?.[0]?.flightDetails
                                  ?.length - 1}
                                &nbsp;Stop
                              </Tooltip>
                            </span>
                          )}
                        </p>
                        <p className="mb-0 fs-sm">
                          {calculateTotalDuration(
                            bookingDetails?.flights?.[0]?.flightDetails
                          )}
                        </p>
                      </div>
                    </div>

                    <span
                      className="ms-4"
                      style={{
                        height: "20px",
                        borderLeft: "1px dashed #E1E1E1",
                      }}
                    ></span>
                    <div className="d-flex align-items-center gap-3">
                      <svg
                        width="44"
                        height="44"
                        viewBox="0 0 52 52"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <g clip-path="url(#clip0_2422_4482)">
                          <path
                            d="M29.023 25.5313L37.4749 27.7959C38.3715 28.0362 39.136 28.6228 39.6001 29.4267C40.0642 30.2306 40.19 31.1859 39.9498 32.0825C39.7095 32.9792 39.1229 33.7436 38.319 34.2078C37.5151 34.6719 36.5598 34.7977 35.6631 34.5574L10.3076 27.7634L7.95408 16.2624L13.0252 17.6212L15.5001 21.9078L20.5712 23.2666L20.361 10.5281L25.4321 11.8869L29.023 25.5313Z"
                            stroke="#32CD32"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                          <path
                            d="M11.2031 39.2739H44.8031"
                            stroke="#32CD32"
                            stroke-linecap="round"
                          />
                        </g>
                        <defs>
                          <clipPath id="clip0_2422_4482">
                            <rect
                              width="42"
                              height="42"
                              fill="white"
                              transform="translate(0 10.8706) rotate(-15)"
                            />
                          </clipPath>
                        </defs>
                      </svg>
                      <div className="d-flex flex-column">
                        <p className="mb-0">
                          <span className="fw-bold">
                            {sessionStorage.getItem("city_to")}&nbsp; (
                            {
                              bookingDetails?.flights?.[0]?.flightDetails[
                                bookingDetails?.flights?.[0]?.flightDetails
                                  ?.length - 1
                              ]?.flightInformation?.[0]?.location?.[1]
                                ?.locationId
                            }
                            )
                          </span>
                          &nbsp;
                          {dayjs(
                            bookingDetails?.flights?.[0]?.flightDetails[
                              bookingDetails?.flights?.[0]?.flightDetails
                                ?.length - 1
                            ]?.flightInformation?.[0]?.productDateTime?.[0]
                              ?.dateOfArrival?.[0],
                            "DDMMYY"
                          ).format("DD/MM/YYYY")}
                        </p>
                        <p className="mb-0 fs-sm">
                          {convertTimeFormat(
                            bookingDetails?.flights?.[0]?.flightDetails[
                              bookingDetails?.flights?.[0]?.flightDetails
                                ?.length - 1
                            ]?.flightInformation?.[0]?.productDateTime?.[0]
                              ?.timeOfArrival?.[0]
                          )}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              {wayOfFlight === "round_trip" && (
                <>
                  <div className="border-dashed w-75 mx-auto"></div>
                  <div className="d-flex flex-column">
                    <div className="d-flex justify-content-between gap-3">
                      <p className="mb-0 h5 d-flex align-items-center">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="20"
                          height="20"
                          fill="currentColor"
                          className="bi bi-geo-alt text-primary-theme"
                          viewBox="0 0 16 16"
                        >
                          <path d="M12.166 8.94c-.524 1.062-1.234 2.12-1.96 3.07A32 32 0 0 1 8 14.58a32 32 0 0 1-2.206-2.57c-.726-.95-1.436-2.008-1.96-3.07C3.304 7.867 3 6.862 3 6a5 5 0 0 1 10 0c0 .862-.305 1.867-.834 2.94M8 16s6-5.686 6-10A6 6 0 0 0 2 6c0 4.314 6 10 6 10" />
                          <path d="M8 8a2 2 0 1 1 0-4 2 2 0 0 1 0 4m0 1a3 3 0 1 0 0-6 3 3 0 0 0 0 6" />
                        </svg>
                        &nbsp;Return
                      </p>
                      <p className="mb-0">
                        Airline Number :&nbsp;
                        <span className="text-primary-theme">
                          {
                            bookingDetails?.flights?.[1]?.flightDetails?.[0]
                              ?.flightInformation?.[0]?.flightOrtrainNumber?.[0]
                          }
                        </span>
                      </p>
                    </div>
                    <div className="d-flex align-items-center gap-3">
                      <img
                        loading="lazy"
                        className="checkout-page-flight-logo"
                        src={
                          bookingDetails?.flights?.[1]?.flightDetails?.[0]
                            ?.flightInformation?.[0]?.companyId?.[0]
                            ?.customCarriers?.image
                        }
                        alt=""
                      />
                      <p className="mb-0 text-uppercase">
                        {" "}
                        {
                          bookingDetails?.flights?.[1]?.flightDetails?.[0]
                            ?.flightInformation?.[0]?.companyId?.[0]
                            ?.customCarriers?.airline_name
                        }
                      </p>
                    </div>
                    <div className="d-flex gap-3">
                      <div className="d-flex flex-column">
                        <div className="d-flex align-items-center gap-3">
                          <svg
                            width="44"
                            height="44"
                            viewBox="0 0 52 52"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <g clip-path="url(#clip0_2422_4485)">
                              <path
                                d="M30.3797 22.8141L38.8316 20.5494C39.7282 20.3092 40.6835 20.4349 41.4874 20.8991C42.2913 21.3632 42.8779 22.1277 43.1182 23.0243C43.3584 23.9209 43.2327 24.8763 42.7685 25.6802C42.3044 26.4841 41.5399 27.0707 40.6433 27.3109L15.2878 34.1049L7.49904 25.3215L12.5702 23.9627L16.8568 26.4376L21.9279 25.0788L15.3766 14.152L20.4477 12.7932L30.3797 22.8141Z"
                                stroke="#32CD32"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              />
                              <path
                                d="M11.2031 39.2739H44.8031"
                                stroke="#32CD32"
                                stroke-linecap="round"
                              />
                            </g>
                            <defs>
                              <clipPath id="clip0_2422_4485">
                                <rect
                                  width="42"
                                  height="42"
                                  fill="white"
                                  transform="translate(0 10.8706) rotate(-15)"
                                />
                              </clipPath>
                            </defs>
                          </svg>
                          <div className="d-flex flex-column">
                            <p className="mb-0">
                              <span className="fw-bold">
                                {sessionStorage.getItem("city_to")}&nbsp;(
                                {
                                  bookingDetails?.flights?.[1]
                                    ?.flightDetails?.[0]?.flightInformation?.[0]
                                    ?.location?.[0]?.locationId
                                }
                                )
                              </span>
                              &nbsp;
                              {dayjs(
                                bookingDetails?.flights?.[1]?.flightDetails?.[0]
                                  ?.flightInformation?.[0]?.productDateTime?.[0]
                                  ?.dateOfDeparture?.[0],
                                "DDMMYY"
                              ).format("DD/MM/YYYY")}
                            </p>
                            <p className="mb-0 fs-sm">
                              {convertTimeFormat(
                                bookingDetails?.flights?.[1]?.flightDetails?.[0]
                                  ?.flightInformation?.[0]?.productDateTime?.[0]
                                  ?.timeOfDeparture?.[0]
                              )}
                            </p>
                          </div>
                        </div>

                        <span
                          className="ms-4"
                          style={{
                            height: "20px",
                            borderLeft: "1px dashed #E1E1E1",
                          }}
                        ></span>
                        <div className="d-flex align-items-center gap-3">
                          <svg
                            style={{ marginLeft: "11px" }}
                            width="28"
                            height="28"
                            viewBox="0 0 24 24"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M4 12.0005C4 14.1222 4.84285 16.1571 6.34315 17.6573C7.84344 19.1576 9.87827 20.0005 12 20.0005M4 12.0005C4 9.87876 4.84285 7.84393 6.34315 6.34363C7.84344 4.84334 9.87827 4.00049 12 4.00049M4 12.0005H2M12 20.0005C14.1217 20.0005 16.1566 19.1576 17.6569 17.6573C19.1571 16.1571 20 14.1222 20 12.0005M12 20.0005V22.0005M20 12.0005C20 9.87876 19.1571 7.84393 17.6569 6.34363C16.1566 4.84334 14.1217 4.00049 12 4.00049M20 12.0005H22M12 4.00049V2.00049M9 12.0005C9 12.7961 9.31607 13.5592 9.87868 14.1218C10.4413 14.6844 11.2044 15.0005 12 15.0005C12.7956 15.0005 13.5587 14.6844 14.1213 14.1218C14.6839 13.5592 15 12.7961 15 12.0005C15 11.2048 14.6839 10.4418 14.1213 9.87917C13.5587 9.31656 12.7956 9.00049 12 9.00049C11.2044 9.00049 10.4413 9.31656 9.87868 9.87917C9.31607 10.4418 9 11.2048 9 12.0005Z"
                              stroke="#32CD32"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                          </svg>
                          <div className="d-flex flex-column">
                            <p className="mb-0">
                              {bookingDetails?.flights?.[1]?.flightDetails
                                ?.length === 1 ? (
                                "Direct"
                              ) : (
                                <span className="pointer">
                                  <Tooltip
                                    content={
                                      bookingDetails?.flights?.[1]
                                        ?.flightDetails &&
                                      bookingDetails?.flights?.[1]
                                        ?.flightDetails?.length > 0 &&
                                      bookingDetails?.flights?.[1]?.flightDetails
                                        .slice(
                                          0,
                                          bookingDetails?.flights?.[1]
                                            ?.flightDetails.length - 1
                                        )
                                        ?.map(
                                          (item, index) =>
                                            calculateDuration(
                                              item,
                                              item?.flightInformation?.[0]
                                                ?.productDateTime?.[0]
                                                ?.timeOfDeparture?.[0],
                                              item?.flightInformation?.[0]
                                                ?.productDateTime?.[0]
                                                ?.dateOfDeparture?.[0],
                                              item?.flightInformation?.[0]
                                                ?.productDateTime?.[0]
                                                ?.timeOfArrival?.[0],
                                              item?.flightInformation?.[0]
                                                ?.productDateTime?.[0]
                                                ?.dateOfArrival?.[0],
                                              index
                                            ) +
                                            (index <
                                            item?.flightDetails?.length - 2
                                              ? ", "
                                              : "")
                                        )
                                    }
                                  >
                                    {bookingDetails?.flights?.[1]?.flightDetails
                                      ?.length - 1}
                                    &nbsp;Stop
                                  </Tooltip>
                                </span>
                              )}
                            </p>
                            <p className="mb-0 fs-sm">
                              {calculateTotalDuration(
                                bookingDetails?.flights?.[1]?.flightDetails
                              )}
                            </p>
                          </div>
                        </div>

                        <span
                          className="ms-4"
                          style={{
                            height: "20px",
                            borderLeft: "1px dashed #E1E1E1",
                          }}
                        ></span>
                        <div className="d-flex align-items-center gap-3">
                          <svg
                            width="44"
                            height="44"
                            viewBox="0 0 52 52"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <g clip-path="url(#clip0_2422_4482)">
                              <path
                                d="M29.023 25.5313L37.4749 27.7959C38.3715 28.0362 39.136 28.6228 39.6001 29.4267C40.0642 30.2306 40.19 31.1859 39.9498 32.0825C39.7095 32.9792 39.1229 33.7436 38.319 34.2078C37.5151 34.6719 36.5598 34.7977 35.6631 34.5574L10.3076 27.7634L7.95408 16.2624L13.0252 17.6212L15.5001 21.9078L20.5712 23.2666L20.361 10.5281L25.4321 11.8869L29.023 25.5313Z"
                                stroke="#32CD32"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              />
                              <path
                                d="M11.2031 39.2739H44.8031"
                                stroke="#32CD32"
                                stroke-linecap="round"
                              />
                            </g>
                            <defs>
                              <clipPath id="clip0_2422_4482">
                                <rect
                                  width="42"
                                  height="42"
                                  fill="white"
                                  transform="translate(0 10.8706) rotate(-15)"
                                />
                              </clipPath>
                            </defs>
                          </svg>
                          <div className="d-flex flex-column">
                            <p className="mb-0">
                              <span className="fw-bold">
                                {sessionStorage.getItem("city_from")}&nbsp; (
                                {
                                  bookingDetails?.flights?.[1]?.flightDetails[
                                    bookingDetails?.flights?.[1]?.flightDetails
                                      ?.length - 1
                                  ]?.flightInformation?.[0]?.location?.[1]
                                    ?.locationId
                                }
                                )
                              </span>
                              &nbsp;
                              {dayjs(
                                bookingDetails?.flights?.[1]?.flightDetails[
                                  bookingDetails?.flights?.[1]?.flightDetails
                                    ?.length - 1
                                ]?.flightInformation?.[0]?.productDateTime?.[0]
                                  ?.dateOfArrival?.[0],
                                "DDMMYY"
                              ).format("DD/MM/YYYY")}
                            </p>
                            <p className="mb-0 fs-sm">
                              {convertTimeFormat(
                                bookingDetails?.flights?.[1]?.flightDetails[
                                  bookingDetails?.flights?.[1]?.flightDetails
                                    ?.length - 1
                                ]?.flightInformation?.[0]?.productDateTime?.[0]
                                  ?.timeOfArrival?.[0]
                              )}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
        <div className="card rounded-10p bg-white">
          <div className="card-body">
            <div className="d-flex flex-column gap-3">
              <p className="mb-0 h5 d-flex align-items-center">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  fill="currentColor"
                  className="bi bi-credit-card-2-back text-primary-theme"
                  viewBox="0 0 16 16"
                >
                  <path d="M11 5.5a.5.5 0 0 1 .5-.5h2a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-2a.5.5 0 0 1-.5-.5z" />
                  <path d="M2 2a2 2 0 0 0-2 2v8a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V4a2 2 0 0 0-2-2zm13 2v5H1V4a1 1 0 0 1 1-1h12a1 1 0 0 1 1 1m-1 9H2a1 1 0 0 1-1-1v-1h14v1a1 1 0 0 1-1 1" />
                </svg>
                &nbsp;Card Details
              </p>
              <div className="row g-3">
                <div className="col-lg-4 col-md-6">
                  <div className="form-group">
                    <label className="mb-1">
                      Card Name{" "}
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="6"
                        height="6"
                        fill="currentColor"
                        class="bi bi-asterisk mb-2 text-danger"
                        viewBox="0 0 16 16"
                      >
                        <path d="M8 0a1 1 0 0 1 1 1v5.268l4.562-2.634a1 1 0 1 1 1 1.732L10 8l4.562 2.634a1 1 0 1 1-1 1.732L9 9.732V15a1 1 0 1 1-2 0V9.732l-4.562 2.634a1 1 0 1 1-1-1.732L6 8 1.438 5.366a1 1 0 0 1 1-1.732L7 6.268V1a1 1 0 0 1 1-1" />
                      </svg>{" "}
                      :
                    </label>
                    <select
                      className="form-select"
                      value={cardDetails?.name}
                      onChange={(event) => {
                        setcardDetails({
                          ...cardDetails,
                          name: event.target.value,
                          number: "",
                          expDate: "",
                          cvv: "",
                        });
                        setcardNumberDigit(
                          event.target.value === "American express" ? 15 : 16
                        );
                      }}
                    >
                      <option value="">Select Card</option>
                      <option value="American express">American express</option>
                      <option value="Master card">Master card</option>
                      <option value="Visa">Visa</option>
                      <option value="Discover">Discover</option>
                    </select>
                  </div>
                </div>
                <div className="col-lg-4 col-md-6">
                  <div className="form-group">
                    <label className="mb-1">
                      Card Holder Name{" "}
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="6"
                        height="6"
                        fill="currentColor"
                        class="bi bi-asterisk mb-2 text-danger"
                        viewBox="0 0 16 16"
                      >
                        <path d="M8 0a1 1 0 0 1 1 1v5.268l4.562-2.634a1 1 0 1 1 1 1.732L10 8l4.562 2.634a1 1 0 1 1-1 1.732L9 9.732V15a1 1 0 1 1-2 0V9.732l-4.562 2.634a1 1 0 1 1-1-1.732L6 8 1.438 5.366a1 1 0 0 1 1-1.732L7 6.268V1a1 1 0 0 1 1-1" />
                      </svg>{" "}
                      :{" "}
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      required
                      value={cardDetails?.holderName}
                      onChange={(event) =>
                        setcardDetails({
                          ...cardDetails,
                          holderName: event.target.value,
                        })
                      }
                    />
                  </div>
                </div>
                <div className="col-lg-4 col-md-6">
                  <div className="form-group">
                    <label className="mb-1">
                      Card Number{" "}
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="6"
                        height="6"
                        fill="currentColor"
                        class="bi bi-asterisk mb-2 text-danger"
                        viewBox="0 0 16 16"
                      >
                        <path d="M8 0a1 1 0 0 1 1 1v5.268l4.562-2.634a1 1 0 1 1 1 1.732L10 8l4.562 2.634a1 1 0 1 1-1 1.732L9 9.732V15a1 1 0 1 1-2 0V9.732l-4.562 2.634a1 1 0 1 1-1-1.732L6 8 1.438 5.366a1 1 0 0 1 1-1.732L7 6.268V1a1 1 0 0 1 1-1" />
                      </svg>{" "}
                      :
                    </label>
                    <div className="input-with-icon">
                      <input
                        className="form-control"
                        disabled={!cardDetails.name}
                        id="ccn"
                        type="tel"
                        inputmode="numeric"
                        pattern="[0-9\s]{13,19}"
                        autocomplete="cc-number"
                        maxlength={
                          cardDetails?.name === "American express" ? "18" : "19"
                        }
                        placeholder={
                          cardDetails?.name === "American express"
                            ? "xxxx xxxx xxxx xxx"
                            : "xxxx xxxx xxxx xxxx"
                        }
                        required
                        value={cardDetails?.number}
                        onChange={handleChangeNumber}
                      />
                      {cardDetails?.number &&
                        isCardNoLength &&
                        (isValidCardNumber(cardDetails?.number) ? (
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="20"
                            height="20"
                            fill="currentColor"
                            className="bi bi-check text-success"
                            viewBox="0 0 16 16"
                          >
                            <path d="M10.97 4.97a.75.75 0 0 1 1.07 1.05l-3.99 4.99a.75.75 0 0 1-1.08.02L4.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093 3.473-4.425z" />
                          </svg>
                        ) : (
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="20"
                            height="20"
                            fill="currentColor"
                            className="bi bi-x text-danger"
                            viewBox="0 0 16 16"
                          >
                            <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
                          </svg>
                        ))}
                    </div>
                  </div>
                </div>
                <div className="col-md-4 col-sm-6">
                  <div className="form-group">
                    <label className="mb-1">
                      Card Expiry Date{" "}
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="6"
                        height="6"
                        fill="currentColor"
                        class="bi bi-asterisk mb-2 text-danger"
                        viewBox="0 0 16 16"
                      >
                        <path d="M8 0a1 1 0 0 1 1 1v5.268l4.562-2.634a1 1 0 1 1 1 1.732L10 8l4.562 2.634a1 1 0 1 1-1 1.732L9 9.732V15a1 1 0 1 1-2 0V9.732l-4.562 2.634a1 1 0 1 1-1-1.732L6 8 1.438 5.366a1 1 0 0 1 1-1.732L7 6.268V1a1 1 0 0 1 1-1" />
                      </svg>
                    </label>
                    <div className="input-with-icon">
                      <input
                        className="form-control"
                        type="text"
                        id="expiry-date"
                        name="expiry-date"
                        disabled={!cardDetails.name}
                        value={cardDetails?.expDate}
                        onChange={handleChange}
                        maxLength="5"
                        placeholder="MM/YY"
                      />
                      {isValidExpDate(cardDetails?.expDate) && (
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="20"
                          height="20"
                          fill="currentColor"
                          className="bi bi-check text-success"
                          viewBox="0 0 16 16"
                        >
                          <path d="M10.97 4.97a.75.75 0 0 1 1.07 1.05l-3.99 4.99a.75.75 0 0 1-1.08.02L4.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093 3.473-4.425z" />
                        </svg>
                      )}
                    </div>
                  </div>
                </div>
                <div className="col-lg-4 col-md-6">
                  <div className="form-group">
                    <label className="mb-1">
                      CVV{" "}
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="6"
                        height="6"
                        fill="currentColor"
                        class="bi bi-asterisk mb-2 text-danger"
                        viewBox="0 0 16 16"
                      >
                        <path d="M8 0a1 1 0 0 1 1 1v5.268l4.562-2.634a1 1 0 1 1 1 1.732L10 8l4.562 2.634a1 1 0 1 1-1 1.732L9 9.732V15a1 1 0 1 1-2 0V9.732l-4.562 2.634a1 1 0 1 1-1-1.732L6 8 1.438 5.366a1 1 0 0 1 1-1.732L7 6.268V1a1 1 0 0 1 1-1" />
                      </svg>{" "}
                      :
                    </label>
                    <div className="input-with-icon">
                      <input
                        className="form-control"
                        type="text"
                        id="cvv"
                        name="cvv"
                        maxlength={
                          cardDetails.name === "American express" ? "4" : "3"
                        }
                        pattern="\d{3,4}"
                        disabled={!cardDetails.name}
                        placeholder={
                          cardDetails.name === "American express"
                            ? "xxxx"
                            : "xxx"
                        }
                        required
                        value={cardDetails.cvv}
                        onChange={handleChangeCvv}
                      />
                      {isValidCVV(cardDetails.cvv, cardDetails.name) && (
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="20"
                          height="20"
                          fill="currentColor"
                          className="bi bi-check text-success"
                          viewBox="0 0 16 16"
                        >
                          <path d="M10.97 4.97a.75.75 0 0 1 1.07 1.05l-3.99 4.99a.75.75 0 0 1-1.08.02L4.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093 3.473-4.425z" />
                        </svg>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="card rounded-10p bg-white">
          <div className="card-body">
            <div className="d-flex flex-column gap-3">
              <p className="mb-0 h5 d-flex align-items-center">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  fill="currentColor"
                  className="bi bi-ticket-perforated text-primary-theme"
                  viewBox="0 0 16 16"
                >
                  <path d="M4 4.85v.9h1v-.9zm7 0v.9h1v-.9zm-7 1.8v.9h1v-.9zm7 0v.9h1v-.9zm-7 1.8v.9h1v-.9zm7 0v.9h1v-.9zm-7 1.8v.9h1v-.9zm7 0v.9h1v-.9z" />
                  <path d="M1.5 3A1.5 1.5 0 0 0 0 4.5V6a.5.5 0 0 0 .5.5 1.5 1.5 0 1 1 0 3 .5.5 0 0 0-.5.5v1.5A1.5 1.5 0 0 0 1.5 13h13a1.5 1.5 0 0 0 1.5-1.5V10a.5.5 0 0 0-.5-.5 1.5 1.5 0 0 1 0-3A.5.5 0 0 0 16 6V4.5A1.5 1.5 0 0 0 14.5 3zM1 4.5a.5.5 0 0 1 .5-.5h13a.5.5 0 0 1 .5.5v1.05a2.5 2.5 0 0 0 0 4.9v1.05a.5.5 0 0 1-.5.5h-13a.5.5 0 0 1-.5-.5v-1.05a2.5 2.5 0 0 0 0-4.9z" />
                </svg>
                &nbsp;Billing Details
              </p>
              <div className="row g-3">
                <div className="col-lg-4 col-md-6">
                  <div className="form-group">
                    <label className="mb-1">
                      Name{" "}
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="6"
                        height="6"
                        fill="currentColor"
                        class="bi bi-asterisk mb-2 text-danger"
                        viewBox="0 0 16 16"
                      >
                        <path d="M8 0a1 1 0 0 1 1 1v5.268l4.562-2.634a1 1 0 1 1 1 1.732L10 8l4.562 2.634a1 1 0 1 1-1 1.732L9 9.732V15a1 1 0 1 1-2 0V9.732l-4.562 2.634a1 1 0 1 1-1-1.732L6 8 1.438 5.366a1 1 0 0 1 1-1.732L7 6.268V1a1 1 0 0 1 1-1" />
                      </svg>{" "}
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      name="name"
                      required
                      value={billingDetails?.name}
                      onChange={handleBillingDetails}
                    />
                  </div>
                </div>
                <div className="col-lg-4 col-md-6">
                  <div className="form-group">
                    <label className="mb-1">
                      Email Address{" "}
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="6"
                        height="6"
                        fill="currentColor"
                        class="bi bi-asterisk mb-2 text-danger"
                        viewBox="0 0 16 16"
                      >
                        <path d="M8 0a1 1 0 0 1 1 1v5.268l4.562-2.634a1 1 0 1 1 1 1.732L10 8l4.562 2.634a1 1 0 1 1-1 1.732L9 9.732V15a1 1 0 1 1-2 0V9.732l-4.562 2.634a1 1 0 1 1-1-1.732L6 8 1.438 5.366a1 1 0 0 1 1-1.732L7 6.268V1a1 1 0 0 1 1-1" />
                      </svg>{" "}
                    </label>
                    <input
                      type="email"
                      className="form-control"
                      name="email"
                      required
                      value={billingDetails?.email}
                      onChange={handleBillingDetails}
                    />
                  </div>
                </div>
                <div className="col-lg-4 col-md-6">
                  <div className="form-group">
                    <label className="mb-1">
                      Country{" "}
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="6"
                        height="6"
                        fill="currentColor"
                        class="bi bi-asterisk mb-2 text-danger"
                        viewBox="0 0 16 16"
                      >
                        <path d="M8 0a1 1 0 0 1 1 1v5.268l4.562-2.634a1 1 0 1 1 1 1.732L10 8l4.562 2.634a1 1 0 1 1-1 1.732L9 9.732V15a1 1 0 1 1-2 0V9.732l-4.562 2.634a1 1 0 1 1-1-1.732L6 8 1.438 5.366a1 1 0 0 1 1-1.732L7 6.268V1a1 1 0 0 1 1-1" />
                      </svg>{" "}
                    </label>
                    <div className="input-group">
                      <div
                        id="contact_number"
                        className="input-group-text dropdown bg-white form-control d-flex justify-content-between"
                      >
                        <span
                          className="dropdown-toggle-span"
                          type="button"
                          id="dropdownMenuButton1"
                          data-bs-toggle="dropdown"
                          aria-expanded="false"
                        >
                          {billingDetails?.country || "Choose Country"}
                        </span>
                        <ul
                          className="dropdown-menu"
                          aria-labelledby="dropdownMenuButton1"
                        >
                          <form className="d-sm-block" role="search">
                            <input
                              type="text"
                              className="form-control mt-1"
                              value={search}
                              onChange={(e) => setSearch(e.target.value)}
                            />
                          </form>
                          <div style={{ height: "30vh", overflow: "scroll" }}>
                            {filteredCountries?.map((con) => (
                              <li>
                                <span
                                  className="dropdown-item pointer"
                                  onClick={() =>
                                    handleClickCountry(con?.country)
                                  }
                                >
                                  {con?.flag} &nbsp;
                                  {con?.country}
                                </span>
                              </li>
                            ))}
                          </div>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4 col-md-6">
                  <div className="form-group">
                    <label className="mb-1">
                      Contact No.{" "}
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="6"
                        height="6"
                        fill="currentColor"
                        class="bi bi-asterisk mb-2 text-danger"
                        viewBox="0 0 16 16"
                      >
                        <path d="M8 0a1 1 0 0 1 1 1v5.268l4.562-2.634a1 1 0 1 1 1 1.732L10 8l4.562 2.634a1 1 0 1 1-1 1.732L9 9.732V15a1 1 0 1 1-2 0V9.732l-4.562 2.634a1 1 0 1 1-1-1.732L6 8 1.438 5.366a1 1 0 0 1 1-1.732L7 6.268V1a1 1 0 0 1 1-1" />
                      </svg>{" "}
                    </label>
                    <div className="input-group">
                      <div
                        id="contact_number"
                        className="input-group-text dropdown bg-white"
                      >
                        <span
                          className="dropdown-toggle"
                          type="button"
                          id="dropdownMenuButton1"
                          data-bs-toggle="dropdown"
                          aria-expanded="false"
                        >
                          {billingDetails?.countrycode || "Code"}
                        </span>
                        <ul
                          className="dropdown-menu"
                          aria-labelledby="dropdownMenuButton1"
                        >
                          <form className="d-sm-block" role="search">
                            <input
                              type="text"
                              className="form-control mt-1 px-1"
                              value={search1}
                              onChange={(e) => setSearch2(e.target.value)}
                            />
                          </form>
                          <div style={{ height: "30vh", overflow: "scroll" }}>
                            {filteredCountryCode?.map((con) => (
                              <li>
                                <span
                                  className="dropdown-item pointer"
                                  onClick={() => handleCountrySelect(con?.code)}
                                >
                                  {con?.flag} &nbsp; {con?.code} &nbsp;{" "}
                                  {con?.country}
                                </span>
                              </li>
                            ))}
                          </div>
                        </ul>
                      </div>
                      <input
                        type="number"
                        className="form-control"
                        aria-describedby="contact_number"
                        name="phone"
                        required
                        value={billingDetails?.phone}
                        onChange={handleBillingDetails}
                      />
                    </div>
                  </div>
                </div>
                <div className="col-lg-4 col-md-6">
                  <div className="form-group">
                    <label className="mb-1">
                      Address{" "}
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="6"
                        height="6"
                        fill="currentColor"
                        class="bi bi-asterisk mb-2 text-danger"
                        viewBox="0 0 16 16"
                      >
                        <path d="M8 0a1 1 0 0 1 1 1v5.268l4.562-2.634a1 1 0 1 1 1 1.732L10 8l4.562 2.634a1 1 0 1 1-1 1.732L9 9.732V15a1 1 0 1 1-2 0V9.732l-4.562 2.634a1 1 0 1 1-1-1.732L6 8 1.438 5.366a1 1 0 0 1 1-1.732L7 6.268V1a1 1 0 0 1 1-1" />
                      </svg>{" "}
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      name="address"
                      required
                      value={billingDetails?.address}
                      onChange={handleBillingDetails}
                    />
                  </div>
                </div>
                <div className="col-lg-4 col-md-6">
                  <div className="form-group">
                    <label className="mb-1">
                      State{" "}
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="6"
                        height="6"
                        fill="currentColor"
                        class="bi bi-asterisk mb-2 text-danger"
                        viewBox="0 0 16 16"
                      >
                        <path d="M8 0a1 1 0 0 1 1 1v5.268l4.562-2.634a1 1 0 1 1 1 1.732L10 8l4.562 2.634a1 1 0 1 1-1 1.732L9 9.732V15a1 1 0 1 1-2 0V9.732l-4.562 2.634a1 1 0 1 1-1-1.732L6 8 1.438 5.366a1 1 0 0 1 1-1.732L7 6.268V1a1 1 0 0 1 1-1" />
                      </svg>{" "}
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      name="state"
                      required
                      value={billingDetails?.state}
                      onChange={handleBillingDetails}
                    />
                  </div>
                </div>
                <div className="col-lg-4 col-md-6">
                  <div className="form-group">
                    <label className="mb-1">
                      City{" "}
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="6"
                        height="6"
                        fill="currentColor"
                        class="bi bi-asterisk mb-2 text-danger"
                        viewBox="0 0 16 16"
                      >
                        <path d="M8 0a1 1 0 0 1 1 1v5.268l4.562-2.634a1 1 0 1 1 1 1.732L10 8l4.562 2.634a1 1 0 1 1-1 1.732L9 9.732V15a1 1 0 1 1-2 0V9.732l-4.562 2.634a1 1 0 1 1-1-1.732L6 8 1.438 5.366a1 1 0 0 1 1-1.732L7 6.268V1a1 1 0 0 1 1-1" />
                      </svg>{" "}
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      name="city"
                      required
                      value={billingDetails?.city}
                      onChange={handleBillingDetails}
                    />
                  </div>
                </div>
                <div className="col-lg-4 col-md-6">
                  <div className="form-group">
                    <label className="mb-1">
                      Zip{" "}
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="6"
                        height="6"
                        fill="currentColor"
                        class="bi bi-asterisk mb-2 text-danger"
                        viewBox="0 0 16 16"
                      >
                        <path d="M8 0a1 1 0 0 1 1 1v5.268l4.562-2.634a1 1 0 1 1 1 1.732L10 8l4.562 2.634a1 1 0 1 1-1 1.732L9 9.732V15a1 1 0 1 1-2 0V9.732l-4.562 2.634a1 1 0 1 1-1-1.732L6 8 1.438 5.366a1 1 0 0 1 1-1.732L7 6.268V1a1 1 0 0 1 1-1" />
                      </svg>{" "}
                    </label>
                    <input
                      type="number"
                      className="form-control"
                      name="zip"
                      required
                      value={billingDetails?.zip}
                      onChange={handleBillingDetails}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="form-check">
          <input
            className="form-check-input"
            type="checkbox"
            id="privacy-policy-check"
            checked={isChecked}
            onChange={() => setIsChecked(!isChecked)}
          />
          <label className="form-check-label" for="privacy-policy-check">
            By checking this box you agree that you have read all details of
            your trip along with the instructions below and accepted our
            <span className="text-primary">&nbsp;Terms and Conditions</span>,
            Cancellation and
            <span className="text-primary">&nbsp;Privacy policies</span>. You
            also acknowledge that this will serve as your electronic signature
            and one-time authorization for the charges on your credit/debit card
          </label>
        </div>
      </div>
    </>
  );
};

export default ThirdStep;
