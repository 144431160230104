import dayjs from "dayjs";
import React, { useEffect } from "react";

const BookSuccessfully = () => {
  const bookingDetails = JSON.parse(sessionStorage.getItem("booking_details"));
  const billInfo = JSON.parse(sessionStorage.getItem("billInfos"));

  function convertTimeFormat(timeString) {
    if (timeString) {
      const hours = timeString?.substring(0, 2);
      const minutes = timeString?.substring(2);
      return `${hours}:${minutes}`;
    }
    return 0;
  }

  function calculateTotalDuration(flightDetails) {
    if (!Array.isArray(flightDetails) || flightDetails.length < 1) {
      return null;
    }
    const firstFlight = flightDetails?.[0].flightInformation?.[0];
    const lastFlight =
      flightDetails[flightDetails?.length - 1].flightInformation?.[0];

    // Format dates and times
    const departureDateTime = dayjs(
      `${firstFlight?.productDateTime?.[0]?.dateOfDeparture?.[0]} ${firstFlight?.productDateTime?.[0]?.timeOfDeparture?.[0]}`,
      "DDMMYY HHmm"
    );

    const arrivalDateTime = dayjs(
      `${lastFlight?.productDateTime?.[0]?.dateOfArrival?.[0]} ${lastFlight?.productDateTime?.[0]?.timeOfArrival?.[0]}`,
      "DDMMYY HHmm"
    );
    const durationMinutes = arrivalDateTime.diff(departureDateTime, "minutes");

    const durationHours = Math.floor(durationMinutes / 60);
    const durationRemainingMinutes = durationMinutes % 60;

    const formattedDuration = `${durationHours}h ${durationRemainingMinutes}m`;

    return formattedDuration;
  }

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < 768 || window.innerWidth >= 768) {
        window.location.reload();
      }
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <div className="bg-theme-secondary custom-container py-3">
      <div className="container d-flex flex-column gap-4">
        <header className="ribbon-container mb-2">
          <h2 className="ribbon">
            <span className="ribbon-content">Congratulations!</span>
          </h2>
        </header>
        <div className="text-center">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="80"
            height="80"
            fill="currentColor"
            className="bi bi-check-circle-fill text-primary-theme"
            viewBox="0 0 16 16"
          >
            <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0m-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z" />
          </svg>
        </div>
        <div className="text-center">
          <p className="mb-0 h4 text-primary-dark-theme">
            Your booking is in process will send you E-ticket shortly
          </p>
          <p className="mb-0 fw-bold">Our team will reach you soon.</p>
        </div>
        <div className="row g-3">
          <div className="col-md-4">
            <div className="d-flex align-items-center justify-content-center gap-1">
              <span
                className="bg-white text-primary-dark-theme rounded-circle shadow-sm"
                style={{
                  height: "50px",
                  width: "50px",
                  display: "grid",
                  placeItems: "center",
                }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  fill="currentColor"
                  className="bi bi-send-fill"
                  viewBox="0 0 16 16"
                >
                  <path d="M15.964.686a.5.5 0 0 0-.65-.65L.767 5.855H.766l-.452.18a.5.5 0 0 0-.082.887l.41.26.001.002 4.995 3.178 3.178 4.995.002.002.26.41a.5.5 0 0 0 .886-.083zm-1.833 1.89L6.637 10.07l-.215-.338a.5.5 0 0 0-.154-.154l-.338-.215 7.494-7.494 1.178-.471z" />
                </svg>
              </span>
              <div>
                <p className="mb-0">Booking reference</p>
                <p className="mb-0 h5 text-success">{billInfo?.pnr_no}</p>
              </div>
            </div>
          </div>
          <div className="col-md-4">
            <div className="d-flex align-items-center justify-content-center gap-1">
              <span
                className="bg-white text-primary-dark-theme rounded-circle shadow-sm"
                style={{
                  height: "50px",
                  width: "50px",
                  display: "grid",
                  placeItems: "center",
                }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  fill="currentColor"
                  className="bi bi-envelope-fill"
                  viewBox="0 0 16 16"
                >
                  <path d="M.05 3.555A2 2 0 0 1 2 2h12a2 2 0 0 1 1.95 1.555L8 8.414zM0 4.697v7.104l5.803-3.558zM6.761 8.83l-6.57 4.027A2 2 0 0 0 2 14h12a2 2 0 0 0 1.808-1.144l-6.57-4.027L8 9.586zm3.436-.586L16 11.801V4.697z" />
                </svg>
              </span>
              <div>
                <p className="mb-0">Confirmation sent to</p>
                <p className="mb-0 h5 text-primary">
                  {billInfo?.billing_email}
                </p>
              </div>
            </div>
          </div>
          <div className="col-md-4">
            <div className="d-flex align-items-center justify-content-center gap-1">
              <span
                className="bg-white text-primary-dark-theme rounded-circle shadow-sm"
                style={{
                  height: "50px",
                  width: "50px",
                  display: "grid",
                  placeItems: "center",
                }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  fill="currentColor"
                  className="bi bi-bank2"
                  viewBox="0 0 16 16"
                >
                  <path d="M8.277.084a.5.5 0 0 0-.554 0l-7.5 5A.5.5 0 0 0 .5 6h1.875v7H1.5a.5.5 0 0 0 0 1h13a.5.5 0 1 0 0-1h-.875V6H15.5a.5.5 0 0 0 .277-.916zM12.375 6v7h-1.25V6zm-2.5 0v7h-1.25V6zm-2.5 0v7h-1.25V6zm-2.5 0v7h-1.25V6zM8 4a1 1 0 1 1 0-2 1 1 0 0 1 0 2M.5 15a.5.5 0 0 0 0 1h15a.5.5 0 1 0 0-1z" />
                </svg>
              </span>
              <div>
                <p className="mb-0">Awating payment</p>
                <p className="mb-0 h5">$ {bookingDetails?.price}</p>
              </div>
            </div>
          </div>
        </div>
        <div className="card rounded-10p custom-card-shadow border-primary-theme mx-auto">
          <div className="card-body">
            <div className="d-flex flex-column gap-3">
              <div className="d-flex align-items-center justify-content-center gap-3 flex-wrap">
                <img
                  className="flight-ticket-img-lg"
                  src={
                    bookingDetails?.flights?.[0]?.flightDetails?.[0]
                      ?.flightInformation?.[0]?.companyId?.[0]?.customCarriers
                      ?.image
                  }
                  alt=""
                  loading="lazy"
                />

                <div className="d-flex align-items-center gap-3 py-1 px-2">
                  <div className="text-end">
                    <p className="mb-0 fw-bold">
                      {
                        bookingDetails?.flights?.[0]?.flightDetails?.[0]
                          ?.flightInformation?.[0]?.location?.[0]?.locationId
                      }
                    </p>
                    <p className="mb-0">
                      {dayjs(
                        bookingDetails?.flights?.[0]?.flightDetails?.[0]
                          ?.flightInformation?.[0]?.productDateTime?.[0]
                          ?.dateOfDeparture?.[0],
                        "DDMMYY"
                      ).format("DD/MM/YYYY")}
                    </p>
                    <p className="mb-0">
                      {convertTimeFormat(
                        bookingDetails?.flights?.[0]?.flightDetails?.[0]
                          ?.flightInformation?.[0]?.productDateTime?.[0]
                          ?.timeOfDeparture?.[0]
                      )}
                    </p>
                  </div>
                  <div className="d-flex align-items-center">
                    <svg
                      width="20"
                      height="18"
                      viewBox="0 0 20 18"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M14 7H18C18.5304 7 19.0391 7.21071 19.4142 7.58579C19.7893 7.96086 20 8.46957 20 9C20 9.53043 19.7893 10.0391 19.4142 10.4142C19.0391 10.7893 18.5304 11 18 11H14L10 18H7L9 11H5L3 13H0L2 9L0 5H3L5 7H9L7 0H10L14 7Z"
                        fill="#D9D9D9"
                      />
                    </svg>
                    <div className="d-flex flex-column align-items-center gap-1">
                      <p className="mb-0">
                        {calculateTotalDuration(
                          bookingDetails?.flights?.[0]?.flightDetails
                        )}
                      </p>
                      <svg
                        width="100%"
                        height="1"
                        viewBox="0 0 188 1"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <line y1="0.5" x2="188" y2="0.5" stroke="#D9D9D9" />
                      </svg>
                      <p className="mb-0">
                        {bookingDetails?.flights?.[0]?.flightDetails?.length ===
                        1 ? (
                          "Direct"
                        ) : (
                          <span className="pointer">
                            {bookingDetails?.flights?.[0]?.flightDetails
                              ?.length - 1}
                            &nbsp;Stop
                          </span>
                        )}
                      </p>
                    </div>
                    <svg
                      width="13"
                      height="15"
                      viewBox="0 0 13 15"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M11.0079 2.2842C12.1783 3.45446 12.8477 5.03403 12.8745 6.68891C12.9013 8.34379 12.2835 9.94423 11.1517 11.1518L11.0079 11.3006L8.00241 14.3053C7.62107 14.6864 7.10912 14.9083 6.57027 14.9261C6.03143 14.9438 5.50598 14.7561 5.10037 14.4009L4.99837 14.3053L1.99221 11.2999C0.796654 10.1043 0.125 8.4828 0.125 6.79203C0.125 5.10127 0.796654 3.47975 1.99221 2.2842C3.18776 1.08865 4.80927 0.416992 6.50004 0.416992C8.1908 0.416992 9.81232 1.08865 11.0079 2.2842ZM6.50004 4.66703C6.22098 4.66703 5.94465 4.722 5.68684 4.82879C5.42902 4.93558 5.19476 5.0921 4.99744 5.28943C4.80011 5.48675 4.64359 5.72101 4.53679 5.97883C4.43 6.23665 4.37504 6.51297 4.37504 6.79203C4.37504 7.07109 4.43 7.34742 4.53679 7.60523C4.64359 7.86305 4.80011 8.09731 4.99744 8.29463C5.19476 8.49196 5.42902 8.64848 5.68684 8.75528C5.94465 8.86207 6.22098 8.91703 6.50004 8.91703C7.06362 8.91703 7.60413 8.69315 8.00264 8.29463C8.40116 7.89612 8.62504 7.35562 8.62504 6.79203C8.62504 6.22845 8.40116 5.68794 8.00264 5.28943C7.60413 4.89091 7.06362 4.66703 6.50004 4.66703Z"
                        fill="#D9D9D9"
                      />
                    </svg>
                  </div>
                  <div className="text-start">
                    <p className="mb-0 fw-bold">
                      {
                        bookingDetails?.flights?.[0]?.flightDetails[
                          bookingDetails?.flights?.[0]?.flightDetails?.length -
                            1
                        ]?.flightInformation?.[0]?.location?.[1]?.locationId
                      }
                    </p>
                    <p className="mb-0">
                      {dayjs(
                        bookingDetails?.flights?.[0]?.flightDetails?.[0]
                          ?.flightInformation?.[0]?.productDateTime?.[0]
                          ?.dateOfArrival?.[0],
                        "DDMMYY"
                      ).format("DD/MM/YYYY")}
                    </p>
                    <p className="mb-0">
                      {convertTimeFormat(
                        bookingDetails?.flights?.[0]?.flightDetails[
                          bookingDetails?.flights?.[0]?.flightDetails?.length -
                            1
                        ]?.flightInformation?.[0]?.productDateTime?.[0]
                          ?.timeOfArrival?.[0]
                      )}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {sessionStorage.getItem("wayOfFlight") === "round_trip" && (
          <div className="card rounded-10p custom-card-shadow border-primary-theme mx-auto">
            <div className="card-body">
              <div className="d-flex flex-column gap-3">
                <div className="d-flex align-items-center justify-content-center gap-3 flex-wrap">
                  <img
                    className="flight-ticket-img-lg"
                    src={
                      bookingDetails?.flights?.[1]?.flightDetails?.[0]
                        ?.flightInformation?.[0]?.companyId?.[0]?.customCarriers
                        ?.image
                    }
                    alt=""
                    loading="lazy"
                  />

                  <div className="d-flex align-items-center gap-3 py-1 px-2">
                    <div className="text-end">
                      <p className="mb-0 fw-bold">
                        {
                          bookingDetails?.flights?.[1]?.flightDetails?.[0]
                            ?.flightInformation?.[0]?.location?.[0]?.locationId
                        }
                      </p>
                      <p className="mb-0">
                        {dayjs(
                          bookingDetails?.flights?.[1]?.flightDetails?.[0]
                            ?.flightInformation?.[0]?.productDateTime?.[0]
                            ?.dateOfDeparture?.[0],
                          "DDMMYY"
                        ).format("DD/MM/YYYY")}
                      </p>
                      <p className="mb-0">
                        {convertTimeFormat(
                          bookingDetails?.flights?.[1]?.flightDetails?.[0]
                            ?.flightInformation?.[0]?.productDateTime?.[0]
                            ?.timeOfDeparture?.[0]
                        )}
                      </p>
                    </div>
                    <div className="d-flex align-items-center">
                      <svg
                        width="20"
                        height="18"
                        viewBox="0 0 20 18"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M14 7H18C18.5304 7 19.0391 7.21071 19.4142 7.58579C19.7893 7.96086 20 8.46957 20 9C20 9.53043 19.7893 10.0391 19.4142 10.4142C19.0391 10.7893 18.5304 11 18 11H14L10 18H7L9 11H5L3 13H0L2 9L0 5H3L5 7H9L7 0H10L14 7Z"
                          fill="#D9D9D9"
                        />
                      </svg>
                      <div className="d-flex flex-column align-items-center gap-1">
                        <p className="mb-0">
                          {calculateTotalDuration(
                            bookingDetails?.flights?.[1]?.flightDetails
                          )}
                        </p>
                        <svg
                          width="100%"
                          height="1"
                          viewBox="0 0 188 1"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <line y1="0.5" x2="188" y2="0.5" stroke="#D9D9D9" />
                        </svg>
                        <p className="mb-0">
                          {bookingDetails?.flights?.[1]?.flightDetails
                            ?.length === 1 ? (
                            "Direct"
                          ) : (
                            <span className="pointer">
                              {bookingDetails?.flights?.[1]?.flightDetails
                                ?.length - 1}
                              &nbsp;Stop
                            </span>
                          )}
                        </p>
                      </div>
                      <svg
                        width="13"
                        height="15"
                        viewBox="0 0 13 15"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M11.0079 2.2842C12.1783 3.45446 12.8477 5.03403 12.8745 6.68891C12.9013 8.34379 12.2835 9.94423 11.1517 11.1518L11.0079 11.3006L8.00241 14.3053C7.62107 14.6864 7.10912 14.9083 6.57027 14.9261C6.03143 14.9438 5.50598 14.7561 5.10037 14.4009L4.99837 14.3053L1.99221 11.2999C0.796654 10.1043 0.125 8.4828 0.125 6.79203C0.125 5.10127 0.796654 3.47975 1.99221 2.2842C3.18776 1.08865 4.80927 0.416992 6.50004 0.416992C8.1908 0.416992 9.81232 1.08865 11.0079 2.2842ZM6.50004 4.66703C6.22098 4.66703 5.94465 4.722 5.68684 4.82879C5.42902 4.93558 5.19476 5.0921 4.99744 5.28943C4.80011 5.48675 4.64359 5.72101 4.53679 5.97883C4.43 6.23665 4.37504 6.51297 4.37504 6.79203C4.37504 7.07109 4.43 7.34742 4.53679 7.60523C4.64359 7.86305 4.80011 8.09731 4.99744 8.29463C5.19476 8.49196 5.42902 8.64848 5.68684 8.75528C5.94465 8.86207 6.22098 8.91703 6.50004 8.91703C7.06362 8.91703 7.60413 8.69315 8.00264 8.29463C8.40116 7.89612 8.62504 7.35562 8.62504 6.79203C8.62504 6.22845 8.40116 5.68794 8.00264 5.28943C7.60413 4.89091 7.06362 4.66703 6.50004 4.66703Z"
                          fill="#D9D9D9"
                        />
                      </svg>
                    </div>
                    <div className="text-start">
                      <p className="mb-0 fw-bold">
                        {
                          bookingDetails?.flights?.[1]?.flightDetails[
                            bookingDetails?.flights?.[1]?.flightDetails
                              ?.length - 1
                          ]?.flightInformation?.[0]?.location?.[1]?.locationId
                        }
                      </p>
                      <p className="mb-0">
                        {dayjs(
                          bookingDetails?.flights?.[1]?.flightDetails?.[0]
                            ?.flightInformation?.[0]?.productDateTime?.[0]
                            ?.dateOfArrival?.[0],
                          "DDMMYY"
                        ).format("DD/MM/YYYY")}
                      </p>
                      <p className="mb-0">
                        {convertTimeFormat(
                          bookingDetails?.flights?.[1]?.flightDetails[
                            bookingDetails?.flights?.[1]?.flightDetails
                              ?.length - 1
                          ]?.flightInformation?.[0]?.productDateTime?.[0]
                            ?.timeOfArrival?.[0]
                        )}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
        <div className="mx-auto">
          <div className="d-flex align-items-center gap-3 flex-wrap">
            <span className="d-flex align-items-center gap-1 mb-3">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                fill="currentColor"
                className="bi bi-check-square-fill text-primary-theme"
                viewBox="0 0 16 16"
              >
                <path d="M2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2zm10.03 4.97a.75.75 0 0 1 .011 1.05l-3.992 4.99a.75.75 0 0 1-1.08.02L4.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093 3.473-4.425a.75.75 0 0 1 1.08-.022z" />
              </svg>
              <span className="fw-bold text-primary-dark-theme">Class :</span>
              <span>{sessionStorage.getItem("travelClass")}</span>
            </span>

            <span className="d-flex align-items-center gap-1 mb-3">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                fill="currentColor"
                className="bi bi-airplane-engines-fill text-primary-theme"
                viewBox="0 0 16 16"
              >
                <path d="M8 0c-.787 0-1.292.592-1.572 1.151A4.35 4.35 0 0 0 6 3v3.691l-2 1V7.5a.5.5 0 0 0-.5-.5h-1a.5.5 0 0 0-.5.5v1.191l-1.17.585A1.5 1.5 0 0 0 0 10.618V12a.5.5 0 0 0 .582.493l1.631-.272.313.937a.5.5 0 0 0 .948 0l.405-1.214 2.21-.369.375 2.253-1.318 1.318A.5.5 0 0 0 5.5 16h5a.5.5 0 0 0 .354-.854l-1.318-1.318.375-2.253 2.21.369.405 1.214a.5.5 0 0 0 .948 0l.313-.937 1.63.272A.5.5 0 0 0 16 12v-1.382a1.5 1.5 0 0 0-.83-1.342L14 8.691V7.5a.5.5 0 0 0-.5-.5h-1a.5.5 0 0 0-.5.5v.191l-2-1V3c0-.568-.14-1.271-.428-1.849C9.292.591 8.787 0 8 0" />
              </svg>
              <span className="fw-bold text-primary-dark-theme">
                Trip type :
              </span>
              <span>
                {sessionStorage.getItem("wayOfFlight") === "one_way"
                  ? "One Way"
                  : "Round Trip"}
              </span>
            </span>

            <span className="d-flex gap-1 mb-3">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                fill="currentColor"
                className="bi bi-check-square-fill mt-1 text-primary-theme"
                viewBox="0 0 16 16"
              >
                <path d="M2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2zm10.03 4.97a.75.75 0 0 1 .011 1.05l-3.992 4.99a.75.75 0 0 1-1.08.02L4.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093 3.473-4.425a.75.75 0 0 1 1.08-.022z" />
              </svg>
              <span className="fw-bold text-nowrap text-primary-dark-theme">
                Check-in :
              </span>
              <span>
                Adult:&nbsp;{sessionStorage.getItem("adults")},&nbsp;Child:&nbsp;
                {sessionStorage.getItem("children")},&nbsp;Infant:&nbsp;
                {sessionStorage.getItem("infants")}
              </span>
            </span>
          </div>
        </div>
        <p className="text-center text-secondary">
          Some information about baggage or some other important things.
        </p>
      </div>
    </div>
  );
};

export default BookSuccessfully;
