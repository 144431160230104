import React, { useEffect, useState } from "react";
import NilhansCard from "./NilhansCard";
import defaultUser from "../../Assets/Images/user-default.jpg";
import WalletTab from "./WalletTab";
import AddCardModal from "./AddCardModal";
import { startApiCall } from "../../Components/GlobalFunction/GlobalFunction";
import OtherApiCall from "../../api/OtherApiCall";
import { CardRelatedEndpoints } from "../../api/Endpoints";
import Bookings from "./BookingTab/Bookings";
import UserInfo from "./BookingTab/UserInfo";
import ConfirmModal from "../../Components/ConfirmModal/ConfirmModal";
import Skeleton from "@mui/material/Skeleton";

const Dashboard = () => {
  const login_data = JSON.parse(sessionStorage.getItem("login"));
  const [isDashboardTab, setIsDashboardTab] = useState("wallet");
  const [loader, setloader] = useState(false);
  const [allCards, setAllCards] = useState([]);
  const [editCard, setEditCard] = useState(null);

  const handleGetAllCards = async () => {
    startApiCall(null, setloader);
    const data = {
      filters: {
        user_id: login_data?.user?._id,
      },
    };
    const res = await OtherApiCall(
      "post",
      CardRelatedEndpoints.getAllCards,
      data
    );
    if (res?.success) {
      setAllCards(res.result);
      setloader(false);
    } else {
      setloader(false);
    }
  };

  useEffect(() => {
    if (isDashboardTab === "card") {
      handleGetAllCards();
    }
  }, [isDashboardTab]);

  return (
    <>
      <div className="bg-theme-secondary custom-container py-3">
        <div className="container">
          <div className="row g-3">
            <div className="col-lg-3 col-md-4">
              <div className="d-flex flex-column gap-3">
                <div className="card rounded-10p custom-card-shadow">
                  <div className="card-body d-flex flex-column align-items-center justify-content-center gap-3">
                    <img
                      className={`rounded-circle pointer custom-card-shadow ${
                        isDashboardTab === "userInfo"
                          ? "border border-primary-theme"
                          : ""
                      }`}
                      style={{ maxWidth: "200px" }}
                      src={defaultUser}
                      alt=""
                      onClick={() => setIsDashboardTab("userInfo")}
                    />
                  </div>
                </div>
                <div
                  className={`card rounded-10p custom-card-shadow pointer${
                    isDashboardTab === "wallet"
                      ? "border-primary-theme bg-theme-primary-light-2"
                      : ""
                  }`}
                  onClick={() => setIsDashboardTab("wallet")}
                >
                  <div className="card-body d-flex align-items-center justify-content-between gap-3">
                    <p className="mb-0 text-primary-dark-theme text-capitalize fw-bold">
                      Wallet
                    </p>
                    <span
                      className="bg-theme-primary-light rounded-circle d-flex align-items-center justify-content-center"
                      style={{ height: "28px", width: "28px" }}
                    >
                      <svg
                        aria-hidden="true"
                        focusable="false"
                        data-prefix="fas"
                        data-icon="wallet"
                        class="svg-inline--fa fa-wallet fa-w-16 text-primary-dark-theme"
                        height="16"
                        width="16"
                        role="img"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 512 512"
                      >
                        <path
                          fill="currentColor"
                          d="M461.2 128H80c-8.84 0-16-7.16-16-16s7.16-16 16-16h384c8.84 0 16-7.16 16-16 0-26.51-21.49-48-48-48H64C28.65 32 0 60.65 0 96v320c0 35.35 28.65 64 64 64h397.2c28.02 0 50.8-21.53 50.8-48V176c0-26.47-22.78-48-50.8-48zM416 336c-17.67 0-32-14.33-32-32s14.33-32 32-32 32 14.33 32 32-14.33 32-32 32z"
                        ></path>
                      </svg>
                    </span>
                  </div>
                </div>
                <div
                  className={`card rounded-10p custom-card-shadow pointer ${
                    isDashboardTab === "card"
                      ? "border-primary-theme bg-theme-primary-light-2"
                      : ""
                  }`}
                  onClick={() => setIsDashboardTab("card")}
                >
                  <div className="card-body d-flex align-items-center justify-content-between gap-3">
                    <p className="mb-0 text-primary-dark-theme text-capitalize fw-bold">
                      Your card
                    </p>
                    <span
                      className="bg-theme-primary-light rounded-circle d-flex align-items-center justify-content-center"
                      style={{ height: "28px", width: "28px" }}
                    >
                      <svg
                        aria-hidden="true"
                        focusable="false"
                        data-prefix="fas"
                        data-icon="credit-card"
                        class="svg-inline--fa fa-credit-card fa-w-18 text-primary-dark-theme"
                        height="16"
                        width="16"
                        role="img"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 576 512"
                      >
                        <path
                          fill="currentColor"
                          d="M0 432c0 26.5 21.5 48 48 48h480c26.5 0 48-21.5 48-48V256H0v176zm192-68c0-6.6 5.4-12 12-12h136c6.6 0 12 5.4 12 12v40c0 6.6-5.4 12-12 12H204c-6.6 0-12-5.4-12-12v-40zm-128 0c0-6.6 5.4-12 12-12h72c6.6 0 12 5.4 12 12v40c0 6.6-5.4 12-12 12H76c-6.6 0-12-5.4-12-12v-40zM576 80v48H0V80c0-26.5 21.5-48 48-48h480c26.5 0 48 21.5 48 48z"
                        ></path>
                      </svg>
                    </span>
                  </div>
                </div>
                <div
                  className={`card rounded-10p custom-card-shadow pointer ${
                    isDashboardTab === "bookings"
                      ? "border-primary-theme bg-theme-primary-light-2"
                      : ""
                  }`}
                  onClick={() => setIsDashboardTab("bookings")}
                >
                  <div className="card-body d-flex align-items-center justify-content-between gap-3">
                    <p className="mb-0 text-primary-dark-theme text-capitalize fw-bold">
                      Your bookings
                    </p>
                    <span
                      className="bg-theme-primary-light rounded-circle d-flex align-items-center justify-content-center"
                      style={{ height: "28px", width: "28px" }}
                    >
                      <svg
                        aria-hidden="true"
                        focusable="false"
                        data-prefix="fas"
                        data-icon="clipboard"
                        class="svg-inline--fa fa-clipboard fa-w-12 text-primary-dark-theme"
                        height="16"
                        width="16"
                        role="img"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 384 512"
                      >
                        <path
                          fill="currentColor"
                          d="M384 112v352c0 26.51-21.49 48-48 48H48c-26.51 0-48-21.49-48-48V112c0-26.51 21.49-48 48-48h80c0-35.29 28.71-64 64-64s64 28.71 64 64h80c26.51 0 48 21.49 48 48zM192 40c-13.255 0-24 10.745-24 24s10.745 24 24 24 24-10.745 24-24-10.745-24-24-24m96 114v-20a6 6 0 0 0-6-6H102a6 6 0 0 0-6 6v20a6 6 0 0 0 6 6h180a6 6 0 0 0 6-6z"
                        ></path>
                      </svg>
                    </span>
                  </div>
                </div>
                <div
                  className="card rounded-10p custom-card-shadow pointer"
                  data-bs-toggle="modal"
                  data-bs-target="#confirm_modal"
                >
                  <div className="card-body d-flex align-items-center justify-content-between gap-3">
                    <p className="mb-0 text-primary-dark-theme text-capitalize fw-bold">
                      Logout
                    </p>
                    <span
                      className="bg-theme-primary-light rounded-circle d-flex align-items-center justify-content-center"
                      style={{ height: "28px", width: "28px" }}
                    >
                      <svg
                        aria-hidden="true"
                        focusable="false"
                        data-prefix="fas"
                        data-icon="sign-out-alt"
                        class="svg-inline--fa fa-sign-out-alt fa-w-16 text-primary-dark-theme"
                        height="16"
                        width="16"
                        role="img"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 512 512"
                      >
                        <path
                          fill="currentColor"
                          d="M497 273L329 441c-15 15-41 4.5-41-17v-96H152c-13.3 0-24-10.7-24-24v-96c0-13.3 10.7-24 24-24h136V88c0-21.4 25.9-32 41-17l168 168c9.3 9.4 9.3 24.6 0 34zM192 436v-40c0-6.6-5.4-12-12-12H96c-17.7 0-32-14.3-32-32V160c0-17.7 14.3-32 32-32h84c6.6 0 12-5.4 12-12V76c0-6.6-5.4-12-12-12H96c-53 0-96 43-96 96v192c0 53 43 96 96 96h84c6.6 0 12-5.4 12-12z"
                        ></path>
                      </svg>
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-9 col-md-8">
              {isDashboardTab === "userInfo" && (
                <UserInfo login_data={login_data} />
              )}
              {isDashboardTab === "wallet" && (
                <WalletTab login_data={login_data} />
              )}
              {isDashboardTab === "card" && (
                <div className="card rounded-10p custom-card-shadow h-100">
                  <div className="card-body">
                    <div className="d-flex justify-content-end mb-3">
                      <button
                        className="btn theme-btn-primary text-capitalize"
                        data-bs-toggle="modal"
                        data-bs-target="#add_new_card"
                      >
                        + Add new card
                      </button>
                    </div>
                    {loader && (
                      <>
                        <div className="row g-3">
                          <div className="col-lg-6">
                            <div className="custom-debit-card-card mx-auto">
                              <div className="custom-card-shadow p-3">
                                <div className="d-flex justify-content-between">
                                  <Skeleton
                                    variant="circular"
                                    width={50}
                                    height={50}
                                  />
                                  <Skeleton
                                    variant="text"
                                    sx={{ fontSize: "1.5rem" }}
                                    height={50}
                                    width={250}
                                  />
                                </div>
                                <div>
                                  <Skeleton
                                    variant="text"
                                    sx={{ fontSize: "1.5rem" }}
                                    height={50}
                                  />
                                  <Skeleton
                                    variant="text"
                                    sx={{ fontSize: "1.5rem" }}
                                    height={50}
                                  />
                                  <Skeleton
                                    variant="text"
                                    sx={{ fontSize: "1.5rem" }}
                                    height={50}
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="col-lg-6">
                            <div className="custom-debit-card-card mx-auto">
                              <div className="custom-card-shadow p-3">
                                <div className="d-flex justify-content-between">
                                  <Skeleton
                                    variant="circular"
                                    width={50}
                                    height={50}
                                  />
                                  <Skeleton
                                    variant="text"
                                    sx={{ fontSize: "1.5rem" }}
                                    height={50}
                                    width={250}
                                  />
                                </div>
                                <div>
                                  <Skeleton
                                    variant="text"
                                    sx={{ fontSize: "1.5rem" }}
                                    height={50}
                                  />
                                  <Skeleton
                                    variant="text"
                                    sx={{ fontSize: "1.5rem" }}
                                    height={50}
                                  />
                                  <Skeleton
                                    variant="text"
                                    sx={{ fontSize: "1.5rem" }}
                                    height={50}
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="col-lg-6">
                            <div className="custom-debit-card-card mx-auto">
                              <div className="custom-card-shadow p-3">
                                <div className="d-flex justify-content-between">
                                  <Skeleton
                                    variant="circular"
                                    width={50}
                                    height={50}
                                  />
                                  <Skeleton
                                    variant="text"
                                    sx={{ fontSize: "1.5rem" }}
                                    height={50}
                                    width={250}
                                  />
                                </div>
                                <div>
                                  <Skeleton
                                    variant="text"
                                    sx={{ fontSize: "1.5rem" }}
                                    height={50}
                                  />
                                  <Skeleton
                                    variant="text"
                                    sx={{ fontSize: "1.5rem" }}
                                    height={50}
                                  />
                                  <Skeleton
                                    variant="text"
                                    sx={{ fontSize: "1.5rem" }}
                                    height={50}
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="col-lg-6">
                            <div className="custom-debit-card-card mx-auto">
                              <div className="custom-card-shadow p-3">
                                <div className="d-flex justify-content-between">
                                  <Skeleton
                                    variant="circular"
                                    width={50}
                                    height={50}
                                  />
                                  <Skeleton
                                    variant="text"
                                    sx={{ fontSize: "1.5rem" }}
                                    height={50}
                                    width={250}
                                  />
                                </div>
                                <div>
                                  <Skeleton
                                    variant="text"
                                    sx={{ fontSize: "1.5rem" }}
                                    height={50}
                                  />
                                  <Skeleton
                                    variant="text"
                                    sx={{ fontSize: "1.5rem" }}
                                    height={50}
                                  />
                                  <Skeleton
                                    variant="text"
                                    sx={{ fontSize: "1.5rem" }}
                                    height={50}
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </>
                    )}
                    {!loader && allCards?.length > 0 && (
                      <div className="row g-3">
                        {allCards?.map((data) => {
                          return (
                            <div className="col-lg-6">
                              <NilhansCard
                                data={data}
                                setEditCard={setEditCard}
                              />
                            </div>
                          );
                        })}
                      </div>
                    )}
                    {!loader && allCards?.length === 0 && (
                      <span className="d-flex justify-content-center mt-3">No Card added</span>
                    )}
                  </div>
                </div>
              )}
              {isDashboardTab === "bookings" && (
                <Bookings login_data={login_data} />
              )}
            </div>
          </div>
        </div>
      </div>

      {/* Add Card Popup */}
      <AddCardModal
        login_data={login_data}
        handleGetAllCards={handleGetAllCards}
        editCard={editCard}
        setEditCard={setEditCard}
      />

      <ConfirmModal />
    </>
  );
};

export default Dashboard;
