import React from "react";

const PrivacyPolicy = () => {
  return (
    <div className="bg-theme-secondary custom-container py-3">
      <div className="container d-flex flex-column gap-3">
        <div className="card rounded-10p custom-card-shadow">
          <div className="card-body">
            <p className="card-title text-primary-dark-theme h2 border-doubled-theme mb-3">
              Privacy & Cookies Policy
            </p>
            <p className="fs-sm-2 text-secondary">
              Your privacy and security is primary to us and we aim to abide by
              every GDPRA security and confidentiality. We appreciate you for
              believing in the business ideas we have entrusting us with your
              valuable personal data. We want you to understand how we use your
              data and your rights regarding that data. This website is owned
              and operated by NILHANS LLC. This Privacy & Cookies Policy
              explains how we collect, use, share, and process personal data
              through the NILHANS website, the NILHANS mobile site, our iPhone,
              Android and other mobile applications and any other online
              communications and interfaces. We request to go through the
              Privacy & Cookies policy given below so that you thoroughly
              recognize and understand about our practices. By visiting this
              site, you honor to acknowledge that you have fully read and
              understood the practices elucidated and detailed in this policy.
            </p>
            <p className="mb-2 fw-bold text-uppercase theme-text-primary">
              Summary of key issues
            </p>
            <div id="our_collection_of_your_personal_data" className="mb-4">
              <p className="mb-2 fw-bold text-uppercase">
                1. Our Collection of Your Personal Data
              </p>
              <p className="mb-2 fs-sm-2 text-secondary">
                We must personally collect recognizable information in order to
                provide you with our online travel service. We may need to
                accumulate the following categories of personal data that you
                provide to us while you are using the site -
              </p>
              <p className="mb-2 fw-bold text-uppercase">Identifiers</p>
              <p className="mb-2">- Name</p>
              <p className="mb-2">- Address</p>
              <p className="mb-2">- Phone number</p>
              <p className="mb-2">- Email address</p>
              <p className="mb-2">- Age</p>
              <p className="mb-2">- Date of birth</p>
              <p className="mb-2">- Gender</p>
              <p className="mb-2">- Username and password for our site</p>
              <p className="mb-2">
                - You might choose any other information to provide either
                through your use of social media handle sign-in features such as
                Facebook or directly as required to fulfill the requested
                service.
              </p>
              <p className="mb-2">- IP address</p>
              <p className="mb-2 fw-bold text-uppercase">
                Commercial information
              </p>
              <p className="mb-2">- Credit card number</p>
              <p className="mb-2">- Payment information</p>
              <p className="mb-2 fw-bold text-uppercase">
                Internet or other electronic network activity information
              </p>
              <p className="mb-2">- IP address</p>
              <p className="mb-2">- Device information</p>
              <p className="mb-2">- Web log information</p>
              <p className="mb-2">
                - Preferred searchrelated to specific searches
              </p>
              <p className="mb-2">
                - Bids through your interactions with our site
              </p>
              <p className="mb-2 fw-bold text-uppercase">
                Inferences drawn from other Personal Data
              </p>
              <p className="mb-2">
                - Profile or purchasing habits inferred from Personal Data
              </p>
              <p className="mb-2">- Device information</p>
              <p className="mb-2">- Web log information</p>
              <p className="mb-2">
                - Search preferences related to specific searches and
              </p>
              <p className="mb-2">
                - Bids through your interactions with our site
              </p>
              <p className="mb-2 fw-bold text-uppercase">Geolocation data</p>
              <p className="mb-2">- General device location and</p>
              <p className="mb-2">- With consent, specific device location</p>
              <p className="mb-2 fs-sm-2 text-secondary">
                Additional to that, we may acquire certain categories of
                personal datalisted above from sources as well as you, basically
                more precisely from the following categories of sources
              </p>
              <p className="mb-2">
                - Third party applications (such as Facebook and Google) that
                you use for single sign-in
              </p>
              <p className="mb-2">
                - Oursubsidiaries, affiliates, or business partners
              </p>
              <p className="mb-2">- Third party data providers and</p>
              <p className="mb-2">
                - Individuals purchasing services on your behalf
              </p>
              <p className="mb-2 fs-sm-2 text-secondary">
                In case you purchase services for your travel companions or any
                other traveler, you must ensure that you behold the right to
                provide their personal data to us and that even those travelers
                have accepted the terms and policies based on which NILHANS uses
                their information. Personal data of other travelers is acquired
                only on the grounds of providing such personal data to the apt
                travel supplier to fulfill the requested service, or may be
                required or permitted by applicable law.
              </p>
            </div>
            <div id="protecting_your_personal_data" className="mb-4">
              <p className="mb-2 fw-bold text-uppercase">
                2. Protecting Your Personal Data
              </p>
              <p className="mb-2 fs-sm-2 text-secondary">
                Prioritizing the security of your personal data at NILHANS, we
                choose to employ administrative, technical, and physical
                safeguards that are designated as well as designed to prevent
                any kind of unauthorized access, maintenance of accurate data,
                and to ensure that the usage of personal data is being done
                correctly. If you ever feel that the established interaction
                with us is no more secure, immediately contact us from the
                Contact Us section of this Privacy & Cookies Policy.
              </p>
            </div>
            <div id="protecting_your_personal_data" className="mb-4">
              <p className="mb-2 fw-bold text-uppercase">
                3. Use of Your Personal Data
              </p>
              <p className="mb-2 fs-sm-2 text-secondary">
                We use Personal Data to serve you in a way of building features
                that will make the services easier to use, and to contact you.
                This includesbetter customer support, timely notice of new
                services, a faster website experience, special offers, and more
                relevant content.
              </p>
              <p className="mb-2 fw-bold text-uppercase">
                Our legal basis to use your data, where one is required to be
                provided by applicable law, is as follows
              </p>
              <p className="mb-2">- When necessary to perform a transaction</p>
              <p className="mb-2">
                - When necessary to act in accordance with a legal obligation
              </p>
              <p className="mb-2">
                - Where you have provided consent as appropriate under
                applicable law
              </p>
              <p className="mb-2">
                - When necessary for legitimate interests such as marketing that
                you have not previously objected to receiving.
              </p>
              <p className="mb-2 fs-sm-2 text-secondary">
                Basically, you need to provide the personal data in order to
                acquire a reservation or otherwise enter into a transaction with
                us, so that we are able to provide you with travel reservation
                services and that might be required for our compliance purposes
                in association with such transactions, except in some varying
                instances when we indicate that the information is optional and
                up to your choice. Not receiving your personal data might hold
                us aback from providing you with some requested information and
                carrying out transactions.
              </p>
            </div>
            <div id="sharing_your_personal_data" className="mb-4">
              <p className="mb-2 fw-bold text-uppercase">
                4. Sharing Your Personal Data
              </p>
              <p className="mb-2 fs-sm-2 text-secondary">
                NILHANS only shares your personal data on the grounds mentioned
                in this Privacy & Cookies Policy or as otherwise authorized by
                you. We also might share your personal data that we accumulate
                from the categories of recipients mentioned below for our
                business purposes –
              </p>
              <p className="mb-2 fs-sm-2 text-secondary">
                We may share your personal data withhotels, car rental agencies,
                airlines, and other involved third parties. Please refer to the
                websites of third party suppliers for their Privacy Policies. We
                share your information with the companies associated with us -
              </p>
              <p className="mb-2">- To provide you with integrated services.</p>
              <p className="mb-2">
                - To provide you with personalized offers and send to you
                marketing communications with your consent or as otherwise
                permitted by applicable law.
              </p>
              <p className="mb-2">
                - To enable "single sign-on" that is allowing you to log into a
                shared account for multiple bookings and the account holding a
                common log of information.
              </p>
              <p className="mb-2">- To provide customer support services.</p>
              <p className="mb-2">
                - To detect, prevent, and investigate fraud and misuse of our
                services, and other data breaches and illegal activities.
              </p>
              <p className="mb-2">
                - To analyze how users use our independent and affiliated
                platforms, so that we could improvise it for better service.
              </p>
              <p className="mb-2">
                - To ensure compliance with applicable law.
              </p>
              <p className="mb-2 fs-sm-2 text-secondary">
                In order to provide you with certain services, we may share your
                Personal Data with our business partners, or require that you
                transact directly with a business partner. Our contracts with
                our business partners that offer these services require them to
                maintain such Personal Data on grounds of Privacy & Cookies
                Policy. You might refer to the terms and policies of the
                business partner on their website.
              </p>
              <p className="mb-2 fs-sm-2 text-secondary">
                Multiple operations that we perform require us to hire other
                companies to help us. Such operations include sending and
                delivering postal mail and email, analyzing data we collect,
                marketing our services, handling credit card transactions and
                providing customer service. The companies we are associated with
                have access to personal data to perform their operations and may
                not use it for other purposes. NILHANS requires these vendors to
                enter into confidentiality agreements and to agree to act in a
                manner showing no reluctance to the relevant principles
                articulated in this Privacy & Cookies Policy.
              </p>
              <p className="mb-2 fs-sm-2 text-secondary">
                As change is the only constant and that goes for business as
                well, sobeing a part of this process, NILHANS may buy or sell
                other companies, and Personal Data may be transferred as a part
                of these transactions. Possibilities also lie in case when
                NILHANS, or substantially all of its assets, may be taken over
                by another company, either by sale of assets, merger, or
                otherwise, and personal data might be transferred as a part of
                such transaction. In cases like such, the company that takes
                over will be required to abide by the privacy promises in this
                Privacy and Cookies Policy or it will responsibly obtain your
                consent to any material changes as to how your personal data
                needs to be handled.
              </p>
              <p className="mb-2 fs-sm-2 text-secondary">
                NILHANS reserves the right to release personal data in order to
                comply law or a judicial proceeding, legal processes or court
                orders served on us. We reserve rights also to release the
                personal data to apply or enforce Terms and Conditions
                applicable to the services, in order to protect us or others
                against inappropriate activities or fraudulent, or to otherwise
                protect the property or safety of NILHANS, our customers, our
                affiliated companies, or others.
              </p>
              <p className="mb-2 fs-sm-2 text-secondary">
                We may share internet or some other electronic network activity
                information about you, and also the general geological data
                based on IP address, with third party cookie providers we use on
                our sites which may use this data for purposes other than
                strictly operational purposes. The aforesaid statements are in
                accordance with the rights preserved and described well in the
                section on Our Policy on Cookies and Other Tracking
                Technologies. In certain jurisdictions, such kind of sharing may
                be considered a sale of your personal data and you may have the
                right under the applicable law to opt-out of or object to such
                sharing.
              </p>
            </div>
            <div id="your_choices_regarding_our_collection" className="mb-4">
              <p className="mb-2 fw-bold text-uppercase">
                5. Your Choices Regarding Our Collection and Use of Your Data
                for Marketing and Other Purposes
              </p>
              <p className="mb-2 fs-sm-2 text-secondary">
                As already foresaid and explained that you may choose not to
                provide us with your personal data; but also doing so may hold
                us aback from providing you with the varying requested
                information and carrying out transactions.
              </p>
              <p className="mb-2 fs-sm-2 text-secondary">
                Your personal data that is acquired to be used for direct
                marketing is all under your control. If receiving notices of
                special promotions or savings or any other marketing offers or
                materials is not preferred by you, you can simply opt out from
                receiving them by using the unsubscribe hyperlink provided in
                any communication, updating your choices in your account profile
                or you might also opt for contacting us where required by law,
                we take your consent for such direct marketing. We request you
                to note that we might send you service-related communications
                (like confirmations of any future travel reservation you make),
                even if you opt-out of receiving marketing communications from
                us.
              </p>
              <p className="mb-2 fs-sm-2 text-secondary">
                You may also have the opportunity of providing your mobile phone
                number on our site in order to receive certain updates or
                alerts, which is not reliable to consistency and continuity and
                may be discontinued at any time. You may also use the settings
                to enable or unable the mobile push notifications from us.
              </p>
            </div>
            <div id="our_policy_on_cookies" className="mb-4">
              <p className="mb-2 fw-bold text-uppercase">
                6. Our Policy on Cookies and Other Tracking Technologies
              </p>
              <p className="mb-2 fs-sm-2 text-secondary">
                Cookies and analogous tracking technologies, such as scripts,
                beacons, and tags, are small bits of code that are usually
                stored on a user’s computer hard-drive or device that enables a
                website to customize itself for each user by recognizing
                information about the user’s visit to the website.
              </p>
              <p className="mb-2 fs-sm-2 text-secondary">
                The site uses cookies to accumulate your preferences and display
                content based on what you view to customize your visit,
                administer the site, analyze trends, track users’ movement
                around the site, gather demographic information about our user
                base as a whole, and serve targeted advertising. On mobile apps
                and websites, we may use anonymous and random device IDs or
                advertiser identifiers in a manner akin to our use of cookies on
                our websites. Despite these activities include the involvement
                of understood development of you as a user as we use this
                information for the purpose described. Provided with the nature
                of our sites and services as a travel provider, and the limited
                amount of personal data we obtain about you, this activity in no
                ways produce legal effects for you and also doesn’t
                significantly affect you.
              </p>
              <p className="mb-2 fs-sm-2 text-secondary">
                NILHANS cookies: We use our cookies to improvise your
                web-browsing exploration. For example, we use a cookie to reduce
                the time it takes for you to submit purchase requests by storing
                a registered user's email address so that you do not need to
                enter your email address each time that you log-in. We will also
                use a cookie to keep track of your search criteria while you are
                engaging NILHANS’s services. NILHANS cookies are associated with
                your personal data. However, no third party may use the
                information we collect through NILHANS cookies for their own
                purposes.
              </p>
              <p className="mb-2 fs-sm-2 text-secondary">
                Third Party Cookies: We work with third parties that place
                cookies on our site to provide their services, including
              </p>
              <p className="mb-2">
                - Ad Targeting: We work with third-party advertising companies
                to serve ads while you are visiting our site. We permit these
                companies to place and access their own cookies on your computer
                in the course of serving advertisements on this site. In order
                to provide advertisements about goods and services of interest
                to you, these companies may use information obtained through
                their cookies (which do not include your name, address, email
                address or telephone number) about your visits to the site and
                other websites, in combination with non-personally identifiable
                information about your purchases and interests from other online
                sites. Other companies' use of their cookies is subject to their
                own privacy policies and not ours.
              </p>
              <p className="mb-2">
                - Data Providers: We also allow data providers to collect web
                log data from you (including IP address and information about
                your browser or operating system), when you visit our site, or
                place or recognize a unique cookie on your browser to enable you
                to receive customized ads or content. These cookies contain no
                personally identifiable information. The cookies may reflect
                demographic or other data, with identifying elements removed,
                linked to data you voluntarily have submitted to us, e.g., your
                email address that we may share with data providers solely in
                hashed, non-human readable form.
              </p>
              <p className="mb-2">
                - Analytics/Measurement: We also use third-party analytics
                cookies to gain insight into how our visitors use the site and
                optimize and improve our site. The data we gather includes which
                web pages you have viewed, which referring/exit pages you have
                entered and arrived from, which platform type you have used,
                date and time stamp information, and details such as the number
                of clicks you make on a given page, your mouse movements and
                scrolling activity, the search words you use and the text you
                type while using our site. We also make use of analytics cookies
                as part of our online advertising campaigns to learn how users
                interact with our site after they have been shown an online
                advertisement, which may include advertisements on third-party
                websites.
              </p>
              <p className="mb-2">
                - Remarketing Pixel Tags and Other Technologies: We may share
                web site usage information with third-party advertising
                companies for the purpose of managing and targeting
                advertisements and for market research. For these purposes, we
                and our third-party advertising companies may place or recognize
                a cookie on your computer or device or directly in our emails or
                communications or may place pixel tags (also called clear gifs)
                on certain web pages. We will then use the information that is
                collected to serve you relevant advertisements when you are
                visiting other sites on the Internet. These advertisements may
                be targeted to specific searches you conducted on NILHANS during
                earlier browsing sessions.
              </p>
              <p className="mb-2">
                - Flash Cookies: Our partners, who provide certain features on
                our site or display advertising based on your browsing activity,
                also use Local Shared Objects such as Flash Cookies.
              </p>
              <p className="mb-2">
                - Controlling Cookies: You have a choice over the use of cookies
                as described in this Privacy & Cookies Policy. Our site is not
                currently configured to respond to Do Not Track signals. If you
                would rather we do not use NILHANS cookies when you visit us,
                please configure your specific browser settings to reject
                cookies.
              </p>
              <p className="mb-2 fs-sm-2 text-secondary">
                We request you to keep in mind that without cookies you may be
                unable to have access to specific features on the site that also
                includes access to your account or profile and certain
                customized content. Removal of all cookies from your computer
                also affects your ensuing visits to some websites, including
                this site, by requiring that, such as you will have to enter
                your login name when you return to that website.
              </p>
              <p className="mb-2 fw-bold text-uppercase">Mobile Devices</p>
              <p className="mb-2 fs-sm-2 text-secondary">
                We and/or our data providers may collect and store a unique
                identifier matched to your mobile device in order to deliver
                customized ads or content while you use applications or surf the
                internet, or to identify you in a unique manner across other
                devices or browsers. In order to customize these ads or content,
                we or a data partner may collect demographic or other data, with
                identifying elements removed, about you received from third
                parties, as well as data you voluntarily have submitted to us
                (e.g., your email address) and/or data passively collected from
                you, such as your device identifier or IP address. For
                advertising purposes, however, we will share your email address
                with our data partners solely in hashed, non-human readable
                form.
              </p>
            </div>
            <div id="how_you_can_access_your_data" className="mb-4">
              <p className="mb-2 fw-bold text-uppercase">
                7. How You Can Access or Change Your Personal Data and Your Data
                Subject Rights
              </p>
              <p className="mb-2 fs-sm-2 text-secondary">
                NILHANS recognizes that you may will to change, access or delete
                your personal data and you will be able to do that by either
                contacting us at NILHANS or by accessing your profile. We will
                need to verify your identity before acting on a requestto
                protect your privacy and security. In most of the cases, we will
                then reply to your request within 30 days of verification. Your
                email address and password are required in order to access your
                profile information if you have created a profile on any of the
                services available on the site.
              </p>
              <p className="mb-2 fs-sm-2 text-secondary">
                Depending on the jurisdiction where you reside, you may have
                specific rights under local legislation to
              </p>
              <p className="mb-2">- request access to your Personal Data</p>
              <p className="mb-2">
                - request rectification of your Personal Data
              </p>
              <p className="mb-2">- request erasure of your Personal Data</p>
              <p className="mb-2">
                - request restriction of processing of your Personal Data
              </p>
              <p className="mb-2">- request data portability</p>
              <p className="mb-2">
                - object to the processing of your Personal Data
              </p>
              <p className="mb-2 fs-sm-2 text-secondary">
                These also include opting out of certain sharing of your
                personal data or processing of your personal data for marketing
                purposes. You may also have the right to request from us the
                personal data from or about you that we have sold or disclosed
                for a business purpose within the past 12 months. We request you
                to visit the "Contact Us" section of this Privacy & Cookies
                Policy if you have any questions or would like to exercise such
                rights. Please note that, in order to provide appropriate
                security to your information, we may require additional data
                points or information from you in order to verify your identity
                prior to completing certain requests related to your Personal
                Data (e.g., requiring the matching of two or three data points
                provided by you with information maintained by NILHANS).
              </p>
              <p className="mb-2 fs-sm-2 text-secondary">
                We will hold on to your information for as long as your account
                is active or as needed to provide you services. We will also
                perpetuate and use your information as necessary to act in
                accordance with our legal obligations, enforce our agreements,
                andresolve disputes.
              </p>
            </div>
            <div id="third_party_services" className="mb-4">
              <p className="mb-2 fw-bold text-uppercase">
                8. Links to Other Websites and Third Party Services
              </p>
              <p className="mb-2 fs-sm-2 text-secondary">
                Our site contains links to other services and websites that are
                neither owned nor controlled by, including links to websites of
                our advertisers and travel suppliers, partners, and sponsors.
                The information that are collected by our site are only bound to
                this Privacy and Cookies Policy. We have no control over the
                third party websites, and your use of third party websites and
                features are based on grounds of privacy policies posted on
                those websites. We aren’t responsible or liable in any ways for
                the business practices or privacy of any third party website
                linked to our site or third party social media functionally
                offered or featured on our site, such as Google Plus, Twitter,
                Facebook, and YouTube. Your use of third parties’ websites
                linked to our site is at your own risk and we are not associated
                in any way, so we highly recommend to go through the privacy
                policies of any linked third party websites when you leave one
                of our sites, link into our website or through a sign-in
                feature, or get benefit from a third party social media
                functionality or feature available on our site.
              </p>
            </div>
            <div id="changes_to_the_privacy_policy" className="mb-4">
              <p className="mb-2 fw-bold text-uppercase">
                9. Changes to the Privacy & Cookies Policy
              </p>
              <p className="mb-2 fs-sm-2 text-secondary">
                NILHANS may revise this Privacy and Cookie Policy to reflect
                changes in the law, our personal data collection and use
                practices, the features of our site, or advancements in
                technology. If we further indulge into making any such material
                changes, we will ensure to notify you by email (mentioned email
                address in your account) or through a prominent notice on the
                site before the change becomes effective.
              </p>
            </div>
            <div id="how_to_contact_us" className="mb-4">
              <p className="mb-2 fw-bold text-uppercase">
                10. How to Contact Us
              </p>
              <p className="mb-3 fs-sm-2 text-secondary">
                For any other queries, or to exercise your rights as detailed
                above, please contact us at –
              </p>
              <p className="mb-3 fs-sm-2 text-secondary">
                By postal mail at NILHANS LLC, Three Sugar Creek Center Blvd
                Sugar Land TX 77479, USA.
              </p>
              <p className="mb-2 fs-sm-2 text-secondary">
                <span className="fw-bold">Email&nbsp;</span>– Info@nilhans.com
              </p>
              <p className="mb-2 fs-sm-2 text-secondary">
                <span className="fw-bold">Phone&nbsp;</span>- +1 888-315-1615
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PrivacyPolicy;
