import React from "react";
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import {
  calculateDuration,
  calculateTotalDuration,
  convertTimeFormat,
} from "../../Components/GlobalFunction/GlobalFunction";
import Tooltip from "react-tooltip-lite";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import TextField from "@mui/material/TextField";
import PriceDetailsCard from "./PriceDetailsCard";

dayjs.extend(customParseFormat);

const FirstStep = ({
  bookingDetails,
  wayOfFlight,
  travellerDetails,
  handleChangeTravellerDetails,
  passengerLabels,
}) => {
  return (
    <>
      <div className="d-flex flex-column gap-3">
        <div className="card rounded-10p bg-white">
          <div className="card-body">
            <div className="d-flex flex-column gap-3">
              <div className="d-flex flex-column">
                <div className="d-flex justify-content-between gap-3">
                  <p className="mb-0 h5 d-flex align-items-center">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="20"
                      height="20"
                      fill="currentColor"
                      className="bi bi-geo-alt text-primary-theme"
                      viewBox="0 0 16 16"
                    >
                      <path d="M12.166 8.94c-.524 1.062-1.234 2.12-1.96 3.07A32 32 0 0 1 8 14.58a32 32 0 0 1-2.206-2.57c-.726-.95-1.436-2.008-1.96-3.07C3.304 7.867 3 6.862 3 6a5 5 0 0 1 10 0c0 .862-.305 1.867-.834 2.94M8 16s6-5.686 6-10A6 6 0 0 0 2 6c0 4.314 6 10 6 10" />
                      <path d="M8 8a2 2 0 1 1 0-4 2 2 0 0 1 0 4m0 1a3 3 0 1 0 0-6 3 3 0 0 0 0 6" />
                    </svg>
                    &nbsp;Departure
                  </p>
                  <p className="mb-0">
                    Airline Number :&nbsp;
                    <span className="text-primary-theme">
                      {
                        bookingDetails?.flights?.[0]?.flightDetails?.[0]
                          ?.flightInformation?.[0]?.flightOrtrainNumber?.[0]
                      }
                    </span>
                  </p>
                </div>
                <div className="d-flex align-items-center gap-3">
                  <img
                    loading="lazy"
                    className="checkout-page-flight-logo"
                    src={
                      bookingDetails?.flights?.[0]?.flightDetails?.[0]
                        ?.flightInformation?.[0]?.companyId?.[0]?.customCarriers
                        ?.image
                    }
                    alt=""
                  />
                  <p className="mb-0 text-uppercase">
                    {
                      bookingDetails?.flights?.[0]?.flightDetails?.[0]
                        ?.flightInformation?.[0]?.companyId?.[0]?.customCarriers
                        ?.airline_name
                    }
                  </p>
                </div>
                <div className="d-flex gap-3">
                  <div className="d-flex flex-column">
                    <div className="d-flex align-items-center gap-3">
                      <svg
                        width="44"
                        height="44"
                        viewBox="0 0 52 52"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <g clip-path="url(#clip0_2422_4485)">
                          <path
                            d="M30.3797 22.8141L38.8316 20.5494C39.7282 20.3092 40.6835 20.4349 41.4874 20.8991C42.2913 21.3632 42.8779 22.1277 43.1182 23.0243C43.3584 23.9209 43.2327 24.8763 42.7685 25.6802C42.3044 26.4841 41.5399 27.0707 40.6433 27.3109L15.2878 34.1049L7.49904 25.3215L12.5702 23.9627L16.8568 26.4376L21.9279 25.0788L15.3766 14.152L20.4477 12.7932L30.3797 22.8141Z"
                            stroke="#32CD32"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                          <path
                            d="M11.2031 39.2739H44.8031"
                            stroke="#32CD32"
                            stroke-linecap="round"
                          />
                        </g>
                        <defs>
                          <clipPath id="clip0_2422_4485">
                            <rect
                              width="42"
                              height="42"
                              fill="white"
                              transform="translate(0 10.8706) rotate(-15)"
                            />
                          </clipPath>
                        </defs>
                      </svg>
                      <div className="d-flex flex-column">
                        <p className="mb-0">
                          <span className="fw-bold">
                            {sessionStorage.getItem("city_from")}&nbsp;(
                            {
                              bookingDetails?.flights?.[0]?.flightDetails?.[0]
                                ?.flightInformation?.[0]?.location?.[0]
                                ?.locationId
                            }
                            )
                          </span>
                          &nbsp;
                          {dayjs(
                            bookingDetails?.flights?.[0]?.flightDetails?.[0]
                              ?.flightInformation?.[0]?.productDateTime?.[0]
                              ?.dateOfDeparture?.[0],
                            "DDMMYY"
                          ).format("DD/MM/YYYY")}
                        </p>
                        <p className="mb-0 fs-sm">
                          {convertTimeFormat(
                            bookingDetails?.flights?.[0]?.flightDetails?.[0]
                              ?.flightInformation?.[0]?.productDateTime?.[0]
                              ?.timeOfDeparture?.[0]
                          )}
                        </p>
                      </div>
                    </div>

                    <span
                      className="ms-4"
                      style={{
                        height: "20px",
                        borderLeft: "1px dashed #E1E1E1",
                      }}
                    ></span>
                    <div className="d-flex align-items-center gap-3">
                      <svg
                        style={{ marginLeft: "11px" }}
                        width="28"
                        height="28"
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M4 12.0005C4 14.1222 4.84285 16.1571 6.34315 17.6573C7.84344 19.1576 9.87827 20.0005 12 20.0005M4 12.0005C4 9.87876 4.84285 7.84393 6.34315 6.34363C7.84344 4.84334 9.87827 4.00049 12 4.00049M4 12.0005H2M12 20.0005C14.1217 20.0005 16.1566 19.1576 17.6569 17.6573C19.1571 16.1571 20 14.1222 20 12.0005M12 20.0005V22.0005M20 12.0005C20 9.87876 19.1571 7.84393 17.6569 6.34363C16.1566 4.84334 14.1217 4.00049 12 4.00049M20 12.0005H22M12 4.00049V2.00049M9 12.0005C9 12.7961 9.31607 13.5592 9.87868 14.1218C10.4413 14.6844 11.2044 15.0005 12 15.0005C12.7956 15.0005 13.5587 14.6844 14.1213 14.1218C14.6839 13.5592 15 12.7961 15 12.0005C15 11.2048 14.6839 10.4418 14.1213 9.87917C13.5587 9.31656 12.7956 9.00049 12 9.00049C11.2044 9.00049 10.4413 9.31656 9.87868 9.87917C9.31607 10.4418 9 11.2048 9 12.0005Z"
                          stroke="#32CD32"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                      </svg>
                      <div className="d-flex flex-column">
                        <p className="mb-0">
                          {bookingDetails?.flights?.[0]?.flightDetails
                            ?.length === 1 ? (
                            "Direct"
                          ) : (
                            <span className="pointer">
                              <Tooltip
                                content={
                                  bookingDetails?.flights?.[0]?.flightDetails &&
                                  bookingDetails?.flights?.[0]?.flightDetails
                                    ?.length > 0 &&
                                  bookingDetails?.flights?.[0]?.flightDetails
                                    .slice(
                                      0,
                                      bookingDetails?.flights?.[0]
                                        ?.flightDetails.length - 1
                                    )
                                    ?.map(
                                      (item, index) =>
                                        calculateDuration(
                                          item,
                                          item?.flightInformation?.[0]
                                            ?.productDateTime?.[0]
                                            ?.timeOfDeparture?.[0],
                                          item?.flightInformation?.[0]
                                            ?.productDateTime?.[0]
                                            ?.dateOfDeparture?.[0],
                                          item?.flightInformation?.[0]
                                            ?.productDateTime?.[0]
                                            ?.timeOfArrival?.[0],
                                          item?.flightInformation?.[0]
                                            ?.productDateTime?.[0]
                                            ?.dateOfArrival?.[0],
                                          index
                                        ) +
                                        (index < item?.flightDetails?.length - 2
                                          ? ", "
                                          : "")
                                    )
                                }
                              >
                                {bookingDetails?.flights?.[0]?.flightDetails
                                  ?.length - 1}
                                &nbsp;Stop
                              </Tooltip>
                            </span>
                          )}
                        </p>
                        <p className="mb-0 fs-sm">
                          {calculateTotalDuration(
                            bookingDetails?.flights?.[0]?.flightDetails
                          )}
                        </p>
                      </div>
                    </div>

                    <span
                      className="ms-4"
                      style={{
                        height: "20px",
                        borderLeft: "1px dashed #E1E1E1",
                      }}
                    ></span>
                    <div className="d-flex align-items-center gap-3">
                      <svg
                        width="44"
                        height="44"
                        viewBox="0 0 52 52"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <g clip-path="url(#clip0_2422_4482)">
                          <path
                            d="M29.023 25.5313L37.4749 27.7959C38.3715 28.0362 39.136 28.6228 39.6001 29.4267C40.0642 30.2306 40.19 31.1859 39.9498 32.0825C39.7095 32.9792 39.1229 33.7436 38.319 34.2078C37.5151 34.6719 36.5598 34.7977 35.6631 34.5574L10.3076 27.7634L7.95408 16.2624L13.0252 17.6212L15.5001 21.9078L20.5712 23.2666L20.361 10.5281L25.4321 11.8869L29.023 25.5313Z"
                            stroke="#32CD32"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                          <path
                            d="M11.2031 39.2739H44.8031"
                            stroke="#32CD32"
                            stroke-linecap="round"
                          />
                        </g>
                        <defs>
                          <clipPath id="clip0_2422_4482">
                            <rect
                              width="42"
                              height="42"
                              fill="white"
                              transform="translate(0 10.8706) rotate(-15)"
                            />
                          </clipPath>
                        </defs>
                      </svg>
                      <div className="d-flex flex-column">
                        <p className="mb-0">
                          <span className="fw-bold">
                            {sessionStorage.getItem("city_to")}&nbsp; (
                            {
                              bookingDetails?.flights?.[0]?.flightDetails[
                                bookingDetails?.flights?.[0]?.flightDetails
                                  ?.length - 1
                              ]?.flightInformation?.[0]?.location?.[1]
                                ?.locationId
                            }
                            )
                          </span>
                          &nbsp;
                          {dayjs(
                            bookingDetails?.flights?.[0]?.flightDetails[
                              bookingDetails?.flights?.[0]?.flightDetails
                                ?.length - 1
                            ]?.flightInformation?.[0]?.productDateTime?.[0]
                              ?.dateOfArrival?.[0],
                            "DDMMYY"
                          ).format("DD/MM/YYYY")}
                        </p>
                        <p className="mb-0 fs-sm">
                          {convertTimeFormat(
                            bookingDetails?.flights?.[0]?.flightDetails[
                              bookingDetails?.flights?.[0]?.flightDetails
                                ?.length - 1
                            ]?.flightInformation?.[0]?.productDateTime?.[0]
                              ?.timeOfArrival?.[0]
                          )}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              {wayOfFlight === "round_trip" && (
                <>
                  <div className="border-dashed w-75 mx-auto"></div>
                  <div className="d-flex flex-column">
                    <div className="d-flex justify-content-between gap-3">
                      <p className="mb-0 h5 d-flex align-items-center">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="20"
                          height="20"
                          fill="currentColor"
                          className="bi bi-geo-alt text-primary-theme"
                          viewBox="0 0 16 16"
                        >
                          <path d="M12.166 8.94c-.524 1.062-1.234 2.12-1.96 3.07A32 32 0 0 1 8 14.58a32 32 0 0 1-2.206-2.57c-.726-.95-1.436-2.008-1.96-3.07C3.304 7.867 3 6.862 3 6a5 5 0 0 1 10 0c0 .862-.305 1.867-.834 2.94M8 16s6-5.686 6-10A6 6 0 0 0 2 6c0 4.314 6 10 6 10" />
                          <path d="M8 8a2 2 0 1 1 0-4 2 2 0 0 1 0 4m0 1a3 3 0 1 0 0-6 3 3 0 0 0 0 6" />
                        </svg>
                        &nbsp;Return
                      </p>
                      <p className="mb-0">
                        Airline Number :&nbsp;
                        <span className="text-primary-theme">
                          {
                            bookingDetails?.flights?.[1]?.flightDetails?.[0]
                              ?.flightInformation?.[0]?.flightOrtrainNumber?.[0]
                          }
                        </span>
                      </p>
                    </div>
                    <div className="d-flex align-items-center gap-3">
                      <img
                        loading="lazy"
                        className="checkout-page-flight-logo"
                        src={
                          bookingDetails?.flights?.[1]?.flightDetails?.[0]
                            ?.flightInformation?.[0]?.companyId?.[0]
                            ?.customCarriers?.image
                        }
                        alt=""
                      />
                      <p className="mb-0 text-uppercase">
                        {" "}
                        {
                          bookingDetails?.flights?.[1]?.flightDetails?.[0]
                            ?.flightInformation?.[0]?.companyId?.[0]
                            ?.customCarriers?.airline_name
                        }
                      </p>
                    </div>
                    <div className="d-flex gap-3">
                      <div className="d-flex flex-column">
                        <div className="d-flex align-items-center gap-3">
                          <svg
                            width="44"
                            height="44"
                            viewBox="0 0 52 52"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <g clip-path="url(#clip0_2422_4485)">
                              <path
                                d="M30.3797 22.8141L38.8316 20.5494C39.7282 20.3092 40.6835 20.4349 41.4874 20.8991C42.2913 21.3632 42.8779 22.1277 43.1182 23.0243C43.3584 23.9209 43.2327 24.8763 42.7685 25.6802C42.3044 26.4841 41.5399 27.0707 40.6433 27.3109L15.2878 34.1049L7.49904 25.3215L12.5702 23.9627L16.8568 26.4376L21.9279 25.0788L15.3766 14.152L20.4477 12.7932L30.3797 22.8141Z"
                                stroke="#32CD32"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              />
                              <path
                                d="M11.2031 39.2739H44.8031"
                                stroke="#32CD32"
                                stroke-linecap="round"
                              />
                            </g>
                            <defs>
                              <clipPath id="clip0_2422_4485">
                                <rect
                                  width="42"
                                  height="42"
                                  fill="white"
                                  transform="translate(0 10.8706) rotate(-15)"
                                />
                              </clipPath>
                            </defs>
                          </svg>
                          <div className="d-flex flex-column">
                            <p className="mb-0">
                              <span className="fw-bold">
                                {sessionStorage.getItem("city_to")}&nbsp;(
                                {
                                  bookingDetails?.flights?.[1]
                                    ?.flightDetails?.[0]?.flightInformation?.[0]
                                    ?.location?.[0]?.locationId
                                }
                                )
                              </span>
                              &nbsp;
                              {dayjs(
                                bookingDetails?.flights?.[1]?.flightDetails?.[0]
                                  ?.flightInformation?.[0]?.productDateTime?.[0]
                                  ?.dateOfDeparture?.[0],
                                "DDMMYY"
                              ).format("DD/MM/YYYY")}
                            </p>
                            <p className="mb-0 fs-sm">
                              {convertTimeFormat(
                                bookingDetails?.flights?.[1]?.flightDetails?.[0]
                                  ?.flightInformation?.[0]?.productDateTime?.[0]
                                  ?.timeOfDeparture?.[0]
                              )}
                            </p>
                          </div>
                        </div>

                        <span
                          className="ms-4"
                          style={{
                            height: "20px",
                            borderLeft: "1px dashed #E1E1E1",
                          }}
                        ></span>
                        <div className="d-flex align-items-center gap-3">
                          <svg
                            style={{ marginLeft: "11px" }}
                            width="28"
                            height="28"
                            viewBox="0 0 24 24"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M4 12.0005C4 14.1222 4.84285 16.1571 6.34315 17.6573C7.84344 19.1576 9.87827 20.0005 12 20.0005M4 12.0005C4 9.87876 4.84285 7.84393 6.34315 6.34363C7.84344 4.84334 9.87827 4.00049 12 4.00049M4 12.0005H2M12 20.0005C14.1217 20.0005 16.1566 19.1576 17.6569 17.6573C19.1571 16.1571 20 14.1222 20 12.0005M12 20.0005V22.0005M20 12.0005C20 9.87876 19.1571 7.84393 17.6569 6.34363C16.1566 4.84334 14.1217 4.00049 12 4.00049M20 12.0005H22M12 4.00049V2.00049M9 12.0005C9 12.7961 9.31607 13.5592 9.87868 14.1218C10.4413 14.6844 11.2044 15.0005 12 15.0005C12.7956 15.0005 13.5587 14.6844 14.1213 14.1218C14.6839 13.5592 15 12.7961 15 12.0005C15 11.2048 14.6839 10.4418 14.1213 9.87917C13.5587 9.31656 12.7956 9.00049 12 9.00049C11.2044 9.00049 10.4413 9.31656 9.87868 9.87917C9.31607 10.4418 9 11.2048 9 12.0005Z"
                              stroke="#32CD32"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                          </svg>
                          <div className="d-flex flex-column">
                            <p className="mb-0">
                              {bookingDetails?.flights?.[1]?.flightDetails
                                ?.length === 1 ? (
                                "Direct"
                              ) : (
                                <span className="pointer">
                                  <Tooltip
                                    content={
                                      bookingDetails?.flights?.[1]
                                        ?.flightDetails &&
                                      bookingDetails?.flights?.[1]
                                        ?.flightDetails?.length > 0 &&
                                      bookingDetails?.flights?.[1]?.flightDetails
                                        .slice(
                                          0,
                                          bookingDetails?.flights?.[1]
                                            ?.flightDetails.length - 1
                                        )
                                        ?.map(
                                          (item, index) =>
                                            calculateDuration(
                                              item,
                                              item?.flightInformation?.[0]
                                                ?.productDateTime?.[0]
                                                ?.timeOfDeparture?.[0],
                                              item?.flightInformation?.[0]
                                                ?.productDateTime?.[0]
                                                ?.dateOfDeparture?.[0],
                                              item?.flightInformation?.[0]
                                                ?.productDateTime?.[0]
                                                ?.timeOfArrival?.[0],
                                              item?.flightInformation?.[0]
                                                ?.productDateTime?.[0]
                                                ?.dateOfArrival?.[0],
                                              index
                                            ) +
                                            (index <
                                            item?.flightDetails?.length - 2
                                              ? ", "
                                              : "")
                                        )
                                    }
                                  >
                                    {bookingDetails?.flights?.[1]?.flightDetails
                                      ?.length - 1}
                                    &nbsp;Stop
                                  </Tooltip>
                                </span>
                              )}
                            </p>
                            <p className="mb-0 fs-sm">
                              {calculateTotalDuration(
                                bookingDetails?.flights?.[1]?.flightDetails
                              )}
                            </p>
                          </div>
                        </div>

                        <span
                          className="ms-4"
                          style={{
                            height: "20px",
                            borderLeft: "1px dashed #E1E1E1",
                          }}
                        ></span>
                        <div className="d-flex align-items-center gap-3">
                          <svg
                            width="44"
                            height="44"
                            viewBox="0 0 52 52"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <g clip-path="url(#clip0_2422_4482)">
                              <path
                                d="M29.023 25.5313L37.4749 27.7959C38.3715 28.0362 39.136 28.6228 39.6001 29.4267C40.0642 30.2306 40.19 31.1859 39.9498 32.0825C39.7095 32.9792 39.1229 33.7436 38.319 34.2078C37.5151 34.6719 36.5598 34.7977 35.6631 34.5574L10.3076 27.7634L7.95408 16.2624L13.0252 17.6212L15.5001 21.9078L20.5712 23.2666L20.361 10.5281L25.4321 11.8869L29.023 25.5313Z"
                                stroke="#32CD32"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              />
                              <path
                                d="M11.2031 39.2739H44.8031"
                                stroke="#32CD32"
                                stroke-linecap="round"
                              />
                            </g>
                            <defs>
                              <clipPath id="clip0_2422_4482">
                                <rect
                                  width="42"
                                  height="42"
                                  fill="white"
                                  transform="translate(0 10.8706) rotate(-15)"
                                />
                              </clipPath>
                            </defs>
                          </svg>
                          <div className="d-flex flex-column">
                            <p className="mb-0">
                              <span className="fw-bold">
                                {sessionStorage.getItem("city_from")}&nbsp; (
                                {
                                  bookingDetails?.flights?.[1]?.flightDetails[
                                    bookingDetails?.flights?.[1]?.flightDetails
                                      ?.length - 1
                                  ]?.flightInformation?.[0]?.location?.[1]
                                    ?.locationId
                                }
                                )
                              </span>
                              &nbsp;
                              {dayjs(
                                bookingDetails?.flights?.[1]?.flightDetails[
                                  bookingDetails?.flights?.[1]?.flightDetails
                                    ?.length - 1
                                ]?.flightInformation?.[0]?.productDateTime?.[0]
                                  ?.dateOfArrival?.[0],
                                "DDMMYY"
                              ).format("DD/MM/YYYY")}
                            </p>
                            <p className="mb-0 fs-sm">
                              {convertTimeFormat(
                                bookingDetails?.flights?.[1]?.flightDetails[
                                  bookingDetails?.flights?.[1]?.flightDetails
                                    ?.length - 1
                                ]?.flightInformation?.[0]?.productDateTime?.[0]
                                  ?.timeOfArrival?.[0]
                              )}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
        <div className="card rounded-10p bg-white">
          <div className="card-body">
            <div className="d-flex flex-column gap-3">
              <p className="mb-0 h5 d-flex align-items-center">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  fill="currentColor"
                  className="bi bi-person text-primary-theme"
                  viewBox="0 0 16 16"
                >
                  <path d="M8 8a3 3 0 1 0 0-6 3 3 0 0 0 0 6m2-3a2 2 0 1 1-4 0 2 2 0 0 1 4 0m4 8c0 1-1 1-1 1H3s-1 0-1-1 1-4 6-4 6 3 6 4m-1-.004c-.001-.246-.154-.986-.832-1.664C11.516 10.68 10.289 10 8 10s-3.516.68-4.168 1.332c-.678.678-.83 1.418-.832 1.664z" />
                </svg>
                &nbsp;Traveler Details
              </p>

              {travellerDetails?.map((data, key) => {
                return (
                  <div className="accordion" id="accordionExample">
                    <div className="accordion-item">
                      <h2 className="accordion-header" id="headingOne">
                        <span
                          className="accordion-button d-flex align-items-center gap-2"
                          data-bs-toggle="collapse"
                          data-bs-target="#collapseOne"
                          aria-expanded="true"
                          aria-controls="collapseOne"
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="16"
                            height="16"
                            fill="currentColor"
                            className="bi bi-person"
                            viewBox="0 0 16 16"
                          >
                            <path d="M8 8a3 3 0 1 0 0-6 3 3 0 0 0 0 6m2-3a2 2 0 1 1-4 0 2 2 0 0 1 4 0m4 8c0 1-1 1-1 1H3s-1 0-1-1 1-4 6-4 6 3 6 4m-1-.004c-.001-.246-.154-.986-.832-1.664C11.516 10.68 10.289 10 8 10s-3.516.68-4.168 1.332c-.678.678-.83 1.418-.832 1.664z" />
                          </svg>
                          Traveler&nbsp;{key + 1}&nbsp;
                          <span className="text-primary-theme">
                            {passengerLabels[key]}
                          </span>
                        </span>
                      </h2>
                      <div
                        id="collapseOne"
                        className="accordion-collapse collapse show"
                        aria-labelledby="headingOne"
                        data-bs-parent="#accordionExample"
                      >
                        <div className="accordion-body">
                          <div className="row g-3">
                            <div className="col-lg-4 col-md-6">
                              <div className="form-group">
                                <label>First Name</label>
                                <input
                                  type="text"
                                  className="form-control"
                                  required
                                  name="firstName"
                                  style={{
                                    borderRadius: "10px",
                                  }}
                                  value={data?.firstName}
                                  onChange={(e) => {
                                    handleChangeTravellerDetails(e, key);
                                  }}
                                  placeholder="Enter first name"
                                />
                              </div>
                            </div>
                            <div className="col-lg-4 col-md-6">
                              <div className="form-group">
                                <label>Middle Name</label>
                                <input
                                  type="text"
                                  className="form-control"
                                  required
                                  name="middleName"
                                  style={{
                                    borderRadius: "10px",
                                  }}
                                  value={data?.middleName}
                                  onChange={(e) => {
                                    handleChangeTravellerDetails(e, key);
                                  }}
                                  placeholder="Enter middle name"
                                />
                              </div>
                            </div>
                            <div className="col-lg-4 col-md-6">
                              <div className="form-group">
                                <label>Last Name</label>
                                <input
                                  type="text"
                                  className="form-control"
                                  required
                                  name="lastName"
                                  style={{
                                    borderRadius: "10px",
                                  }}
                                  value={data?.lastName}
                                  onChange={(e) => {
                                    handleChangeTravellerDetails(e, key);
                                  }}
                                  placeholder="Enter last name"
                                />
                              </div>
                            </div>
                            <div className="col-lg-4 col-md-6">
                              <div className="form-group">
                                <label>Date of Birth</label>
                                <span
                                  className="form-control rounded-10p"
                                  style={{ paddingBlock: "1px" }}
                                >
                                  <LocalizationProvider
                                    dateAdapter={AdapterDayjs}
                                  >
                                    <DatePicker
                                      value={
                                        dayjs(data?.date).isValid()
                                          ? data?.date
                                          : null
                                      }
                                      onChange={(newValue) =>
                                        handleChangeTravellerDetails(
                                          newValue,
                                          key,
                                          "datepicker"
                                        )
                                      }
                                      views={["year", "month", "day"]}
                                      maxDate={dayjs()}
                                      renderInput={(params) => (
                                        <TextField
                                          {...params}
                                          className="banner-form-input w-100"
                                        />
                                      )}
                                    />
                                  </LocalizationProvider>
                                </span>
                              </div>
                            </div>
                            <div className="col-lg-4 col-md-6">
                              <div className="form-group">
                                <label>Gender</label>
                                <div
                                  className="d-flex align-items-center gap-3"
                                  style={{ height: "37.5px" }}
                                >
                                  <div className="form-check mb-0">
                                    <input
                                      className="form-check-input"
                                      type="radio"
                                      name={`gender-${key}`}
                                      id={`male-${key}`}
                                      value="Male"
                                      checked={data.gender === "Male"}
                                      onChange={(e) =>
                                        handleChangeTravellerDetails(
                                          e,
                                          key,
                                          "radio"
                                        )
                                      }
                                    />
                                    <label
                                      className="form-check-label"
                                      htmlFor="male"
                                    >
                                      Male
                                    </label>
                                  </div>
                                  <div className="form-check mb-0">
                                    <input
                                      className="form-check-input"
                                      type="radio"
                                      name={`gender-${key}`}
                                      id={`female-${key}`}
                                      value="Female"
                                      checked={data.gender === "Female"}
                                      onChange={(e) =>
                                        handleChangeTravellerDetails(
                                          e,
                                          key,
                                          "radio"
                                        )
                                      }
                                    />
                                    <label
                                      className="form-check-label"
                                      htmlFor="female"
                                    >
                                      Female
                                    </label>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default FirstStep;
