import React, { useEffect, useState } from "react";
import offerImg from "../../Assets/Images/offer.jpg";
import { startApiCall } from "../../Components/GlobalFunction/GlobalFunction";
import ApiCall from "../../api/ApiCall";
import { FlightRelatedEndpoints } from "../../api/Endpoints";
import DefaultLoader from "../../Components/Loaders/DefaultLoader";
import { toast } from "react-toastify";

const Deals = () => {
  const [loader, setLoader] = useState(false);
  const [loader2, setLoader2] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [offerData, setOfferData] = useState("");
  const [dealslist, setDealslist] = useState([]);
  const [name, setName] = useState("");
  const [phone, setPhone] = useState("");
  const [email, setemail] = useState("");
  const [date, setDate] = useState("");
  const [noofPassenger, setNoOfPassenger] = useState(0);
  const [visibleBlogs, setVisibleBlogs] = useState(6);

  const handleLoadMore = () => {
    setVisibleBlogs((prevVisibleBlogs) => prevVisibleBlogs + 6);
  };

  const handleGetAllDeals = async () => {
    startApiCall(null, setLoader);
    const data = {
      filters: {},
    };
    const res = await ApiCall("post", FlightRelatedEndpoints.getOffers, data);
    if (res) {
      setDealslist(res);
      setLoader(false);
    } else {
      setLoader(false);
    }
  };

  useEffect(() => {
    handleGetAllDeals();
  }, []);

  const handleReset = () => {
    setName("");
    setPhone("");
    setDate("");
    setemail("");
    setNoOfPassenger(0);
    setLoader2(false);
    setErrorMessage("");
    const closeModalDom = document.getElementById("get_offer_modal");
    if (closeModalDom) closeModalDom.click();
  }

  const handleCreateOffer = async (e) => {
    e.preventDefault();
    startApiCall(setErrorMessage, setLoader2);
    const payload = {
      offerpage_id: offerData?._id,
      name: name,
      no_of_passengers: noofPassenger.toString(),
      phone_no: phone,
      email: email,
      date_of_travel: date,
    };
    const res = await ApiCall(
      "post",
      FlightRelatedEndpoints.sumitOfferInquiry,
      payload
    );
    if (res) {
      handleReset();
      toast.success("Thank you for your interest in our deal. We will contact you soon !");
    } else {
      setErrorMessage(res.error);
      setLoader2(false);
    }
  };

  const handleChange = (event) => {
    const formattedValue = event.target.value.replace(/[^\d]/g, "");
    setPhone(formattedValue);
  };

  return (
    <>
      {!loader && (
        <div className="bg-theme-secondary custom-container py-3">
          <div className="container d-flex flex-column gap-3">
            <p
              className="mb-0 h2 text-primary-dark-theme"
              data-bs-toggle="modal"
              data-bs-target="#get_offer_modal"
            >
              Super Offers
            </p>
            {dealslist.slice(0, visibleBlogs)?.map((item, index) => {
              return (
                <div
                  className="card rounded-10p custom-card-shadow"
                  key={index}
                >
                  <div className="card-body">
                    <div className="d-flex justify-content-between gap-3 col-reverse-xs">
                      <div className="d-flex flex-column gap-3 justify-content-between w-100">
                        <div className="d-flex flex-column gap-3">
                          <p className="text-primary-theme h4">{item?.name}</p>
                          <p className="mb-0">
                            CODE :&nbsp;
                            <span className="fw-bold text-primary-dark-theme">
                              {item?.short_description}
                            </span>
                          </p>
                        </div>
                        <div className="d-flex align-items-center gap-3 justify-content-between">
                          {/* <a href="">
                            <span className="btn btn-rounded theme-btn-primary btn-lg">
                              Contact
                            </span>
                          </a> */}

                          <span
                            className="btn btn-rounded theme-btn-primary btn-lg text-nowrap"
                            data-bs-toggle="modal"
                            data-bs-target="#get_offer_modal"
                            onClick={() => setOfferData(item)}
                          >
                            Get Offer
                          </span>
                        </div>
                      </div>
                      <img
                        className="offer_img mx-auto-sm"
                        src={item?.image}
                        alt=""
                        loading="lazy"
                      />
                    </div>
                  </div>
                </div>
              );
            })}
            {visibleBlogs < dealslist?.length && (
              <button
                className="btn theme-btn-outline-primary border"
                onClick={handleLoadMore}
              >
                Load More
              </button>
            )}
            {dealslist?.length === 0 && (
              <p className="text-center text-secondary h2 my-5">
                No Deals Available
              </p>
            )}
          </div>
        </div>
      )}
      {loader && <DefaultLoader />}

      <div
        className="modal fade"
        id="get_offer_modal"
        tabindex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <form onSubmit={handleCreateOffer}>
          <div
            className="modal-dialog modal-dialog-centered"
            style={{ maxWidth: "740px" }}
          >
            <div className="modal-content">
              <div className="modal-header pb-0 border-0">
                <div className="d-flex justify-content-between gap-3 w-100 pe-2">
                  <h5
                    className="modal-title text-primary-theme"
                    id="exampleModalLabel"
                  >
                    Get Offer
                  </h5>
                  {/* <span className="btn theme-btn-primary btn-rounded">
                    Talk to our experts
                  </span> */}
                </div>
                <span
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                  onClick={handleReset}
                ></span>
              </div>
              <div className="modal-body">
                <div className="row g-3">
                  <div className="col-md-6">
                    <div className="form-group">
                      <label for="user_name" className="form-label">
                        Name :
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="user_name"
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <label for="Passengers_count" className="form-label">
                        Number of Passengers :
                      </label>
                      <input
                        type="number"
                        className="form-control"
                        value={noofPassenger}
                        onChange={(e) => setNoOfPassenger(e.target.value)}
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <label for="user_contact" className="form-label">
                        Contact No. :
                      </label>
                      <input
                        type="tel"
                        className="form-control"
                        value={phone}
                        onChange={handleChange}
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <label for="journey_date" className="form-label">
                        Journey Date:
                      </label>
                      <input
                        type="date"
                        className="form-control"
                        value={date}
                        onChange={(e) => setDate(e.target.value)}
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <label for="user_email" className="form-label">
                        Email address :
                      </label>
                      <input
                        type="email"
                        className="form-control"
                        value={email}
                        onChange={(e) => setemail(e.target.value)}
                      />
                      {/* <div className="form-text">
                        We'll never share your credentials with anyone else.
                      </div> */}
                    </div>
                  </div>
                </div>
                <div className="col-md-6 mt-3">
                  <div className="form-group">
                    <label className="form-label">Code No. :</label>
                    <p className="fw-bold h5 mt-1 mb-0 text-primary-dark-theme">
                      {offerData?.short_description}
                    </p>
                  </div>
                </div>
              </div>
              <div className="modal-footer pt-0 border-0">
                <button
                  type="submit"
                  className="btn theme-btn-primary btn-rounded"
                >
                  {loader2 ? "Loading.....":"Submit"}
                </button>
              </div>
            </div>
          </div>
        </form>
      </div>
    </>
  );
};

export default Deals;
