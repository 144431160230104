import React, { useState } from "react";
import { startApiCall } from "../GlobalFunction/GlobalFunction";
import { AuthenticationEndpoints } from "../../api/Endpoints";
import OtherApiCall from "../../api/OtherApiCall";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import ForgotPassword from "./ForgotPassword/ForgotPassword";

const LoginSignUpModal = () => {
  const navigate = useNavigate();
  const [isLogin, setIsLogin] = useState(true);
  const [errorMessage, setErrorMessage] = useState("");
  const [loader, setloader] = useState(false);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [password, setPassword] = useState("");
  const [forgotPassword, setForgotPassword] = useState(false);
  const [firstStep, setfirstStep] = useState(true);
  const pattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

  const handleChange = (event) => {
    const formattedValue = event.target.value.replace(/[^\d]/g, "");
    setPhone(formattedValue);
  };

  const handleReset = () => {
    setloader(false);
    setErrorMessage("");
    setName("");
    setEmail("");
    setPassword("");
    setPhone("");
    const closeModalDom = document.getElementById("login_modal");
    if (closeModalDom) closeModalDom.click();
  };

  const handleSignup = async () => {
    startApiCall(setErrorMessage, setloader);
    const data = {
      name: name,
      password: password,
      email: email,
      mobile_no: phone,
      registered_by: "email",
      role: "customer",
      is_active: true,
    };
    const res = await OtherApiCall(
      "post",
      AuthenticationEndpoints.userSignup,
      data
    );
    if (res?.success) {
      handleLogin();
      handleReset();
      setloader(false);
    } else {
      setloader(false);
      setErrorMessage(res.error);
    }
  };

  const handleLogin = async () => {
    startApiCall(setErrorMessage, setloader);
    const data = {
      email: email,
      password: password,
      isCustomer: true,
    };
    const res = await OtherApiCall(
      "post",
      AuthenticationEndpoints.userLogin,
      data
    );
    if (res?.success) {
      setloader(false);
      toast.success("Login successfully!");
      handleReset();
      sessionStorage.setItem("login", JSON.stringify(res.result));
      if (sessionStorage.getItem("booking_login") === "yes") {
        navigate("/checkout");
      } else {
        navigate("/");
      }
    } else {
      setloader(false);
      setErrorMessage(res.error);
    }
  };

  const handleResetModal = () => {
    const closeModalDom = document.getElementById("login_modal");
    if (closeModalDom) closeModalDom.click();
  };

  return (
    <>
      <div
        className="modal fade"
        id="login_modal"
        tabindex="-1"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content rounded-40p">
            <div className="modal-body">
              <div>
                {/* <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button> */}
                <p className="text-center h4 mb-0 text-primary-dark-theme">
                  Welcome Back to&nbsp;
                  <span className="text-uppercase text-primary-theme">
                    Nilhans
                  </span>
                </p>
                <p className="text-center fw-400">
                  Sign in to your account to continue using Ogule
                </p>
                <div className="border-doubled-theme"></div>
                {isLogin === true ? (
                  <div className="p-3">
                    <div className="mb-3">
                      <label
                        className="text-primary-dark-theme mb-2"
                        htmlFor="user_email"
                      >
                        Email Address
                      </label>
                      <input
                        id="user_email"
                        type="email"
                        className="form-control border-0 border-bottom"
                        placeholder="Enter Email"
                        required
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                      />
                      <div className="form-text">
                        We'll never share your credentials with anyone else.
                      </div>
                    </div>
                    <div className="mb-3">
                      <label
                        className="text-primary-dark-theme mb-2"
                        htmlFor="user_password"
                      >
                        Password
                      </label>
                      <input
                        id="user_password"
                        type="password"
                        className="form-control border-0 border-bottom"
                        placeholder="Enter Password"
                        required
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                      />
                    </div>
                    {errorMessage && (
                      <div className="d-flex justify-content-center text-danger mb-2">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="16"
                          height="16"
                          fill="currentColor"
                          className="bi bi-exclamation-circle mt-1"
                          viewBox="0 0 16 16"
                        >
                          <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14m0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16" />
                          <path d="M7.002 11a1 1 0 1 1 2 0 1 1 0 0 1-2 0M7.1 4.995a.905.905 0 1 1 1.8 0l-.35 3.507a.552.552 0 0 1-1.1 0z" />
                        </svg>
                        &nbsp;
                        {errorMessage}
                      </div>
                    )}
                    <div className="d-flex justify-content-end mb-3">
                      <span
                        className="text-secondary pointer"
                        onClick={() => {
                          setForgotPassword(true);
                          setfirstStep(true);
                          handleResetModal();
                        }}
                      >
                        Forgot Password ?
                      </span>
                    </div>
                    <button
                      className="btn btn-rounded custom-btn w-100 fw-bold"
                      onClick={() => {
                        if (email.match(pattern)) {
                          handleLogin();
                        } else {
                          alert("Invalid email format");
                        }
                      }}
                    >
                      {loader ? "Loading.........." : "Login"}
                    </button>
                    <p className="mb-0 text-center text-secondary fs-sm-2 mt-4">
                      Don't have an account?
                    </p>
                    <p
                      className="mb-0 text-center text-primary-theme fs-sm-2 text-uppercase fw-bold pointer"
                      onClick={() => {
                        setIsLogin(false);
                        setEmail("");
                        setPassword("");
                      }}
                    >
                      Sign up now
                    </p>
                  </div>
                ) : (
                  <div className="p-3">
                    <div className="mb-3">
                      <label
                        className="text-primary-dark-theme mb-2"
                        htmlFor="user_name"
                      >
                        Name
                      </label>
                      <input
                        id="user_name"
                        type="text"
                        className="form-control border-0 border-bottom"
                        placeholder="Enter Name"
                        required
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                      />
                    </div>
                    <div className="mb-3">
                      <label
                        className="text-primary-dark-theme mb-2"
                        htmlFor="user_email"
                      >
                        Email Address
                      </label>
                      <input
                        id="user_email"
                        type="email"
                        className="form-control border-0 border-bottom"
                        placeholder="Enter Email"
                        required
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                      />
                      <div className="form-text">
                        We'll never share your credentials with anyone else.
                      </div>
                    </div>
                    <div className="mb-4">
                      <label
                        className="text-primary-dark-theme mb-2"
                        htmlFor="phone_no"
                      >
                        Phone no.
                      </label>
                      <input
                        id="phone_no"
                        type="tel"
                        className="form-control border-0 border-bottom"
                        placeholder="Enter Phone"
                        required
                        value={phone}
                        onChange={handleChange}
                      />
                    </div>
                    <div className="mb-3">
                      <label
                        className="text-primary-dark-theme mb-2"
                        htmlFor="user_password"
                      >
                        Password
                      </label>
                      <input
                        id="user_password"
                        type="password"
                        className="form-control border-0 border-bottom"
                        placeholder="Enter Password"
                        required
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                      />
                    </div>
                    {errorMessage && (
                      <div className="d-flex justify-content-center text-danger mb-2">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="16"
                          height="16"
                          fill="currentColor"
                          className="bi bi-exclamation-circle mt-1"
                          viewBox="0 0 16 16"
                        >
                          <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14m0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16" />
                          <path d="M7.002 11a1 1 0 1 1 2 0 1 1 0 0 1-2 0M7.1 4.995a.905.905 0 1 1 1.8 0l-.35 3.507a.552.552 0 0 1-1.1 0z" />
                        </svg>
                        &nbsp;
                        {errorMessage}
                      </div>
                    )}

                    <button
                      className="btn btn-rounded custom-btn w-100 fw-bold"
                      onClick={() => {
                        if (email.match(pattern)) {
                          handleSignup();
                        } else {
                          alert("Invalid email format");
                        }
                      }}
                    >
                      {loader ? "Loading.........." : "Sign up"}
                    </button>
                    <p className="mb-0 text-center text-secondary fs-sm-2 mt-4">
                      Already have an account?
                    </p>
                    <p
                      className="mb-0 text-center text-primary-theme fs-sm-2 text-uppercase fw-bold pointer"
                      onClick={() => {
                        setIsLogin(true);
                        setEmail("");
                        setPassword("");
                      }}
                    >
                      Login
                    </p>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      {forgotPassword && (
        <ForgotPassword
          setForgotPassword={setForgotPassword}
          firstStep={firstStep}
          setfirstStep={setfirstStep}
        />
      )}
    </>
  );
};

export default LoginSignUpModal;
