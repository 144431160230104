import React from "react";
import Slider from "@mui/material/Slider";
import {
  convertTimeFormat,
  formatTimeValue,
  parseTimeValue,
} from "../../Components/GlobalFunction/GlobalFunction";

const FilterSideBar = ({
  minPrice,
  maxPrice,
  selectedMaxPrice,
  handleChangePrice,
  deptValue,
  arrivValue,
  departureData,
  arrivalData,
  stops,
  updateStopCount,
  totalStopCount,
  handleCheckBoxChange,
  priceWithAirlines,
  selectedAirlines,
  handleSelectAll,
  handleClearAll,
  handleAirlineCheckboxChange,
  handleChangeDeptValue,
  handleChangeArrivValue,
}) => {
  return (
    <div className="d-flex flex-column p-3 flight-sidebar gap-3 hide-scrollbar flight-page-sidebar-lg">
      <div className="card rounded-10p custom-card-shadow">
        <div className="card-body pb-1">
          <p className="fw-bold text-uppercase mb-1">Stop</p>
          {stops?.map((data) => {
            return (
              <div className="form-check pointer">
                <input
                  className="form-check-input"
                  type="checkbox"
                  value={data}
                  id="direct"
                  onChange={() => {
                    handleCheckBoxChange(data);
                  }}
                  checked={updateStopCount?.includes(data)}
                  disabled={!totalStopCount?.includes(data)}
                />
                <label className="form-check-label" htmlFor="direct">
                  {data === "0"
                    ? "Direct"
                    : data === "1"
                    ? "1 Stop"
                    : "2+ Stop"}
                </label>
              </div>
            );
          })}
        </div>
        <div className="card-body pb-1">
          <p className="fw-bold text-uppercase mb-1">Price</p>
          <div className="d-flex justify-content-between">
            <p className="fs-sm-2 mb-0 text-secondary">
              Min:&nbsp;
              <span className="text-primary-theme fw-bold">$ {minPrice}</span>
            </p>
            <p className="fs-sm-2 mb-0 text-secondary">
              Max:&nbsp;
              <span className="text-primary-theme fw-bold">$ {maxPrice}</span>
            </p>
          </div>
          <Slider
            defaultValue={selectedMaxPrice}
            aria-label="Default"
            valueLabelDisplay="auto"
            min={minPrice}
            max={maxPrice}
            // onChange={(event, newValue) => handleChangePrice(newValue)}
            onChange={(event, newValue) =>
              handleChangePrice(event, newValue, "slider")
            }
          />
          <p className="fs-sm-2 mb-0 text-secondary">
            Current price value:&nbsp;
            <span className="text-primary-theme fw-bold">
              $ {selectedMaxPrice?.toFixed(2)}
            </span>
          </p>
        </div>
        <div className="card-body pb-1">
          <p className="fw-bold text-uppercase mb-1">Times</p>
          <p className="fs-sm-2 mb-0 text-secondary">
            Take-off from&nbsp;
            <span className="text-primary-theme fw-bold">
              {sessionStorage.getItem("originLocationCode")}
            </span>
          </p>
          <div className="d-flex justify-content-between">
            <p className="fs-sm-2 mb-0 text-secondary">
              Start:&nbsp;
              <span className="text-primary-theme fw-bold">
                {convertTimeFormat(departureData?.[0])}
              </span>
            </p>
            <p className="fs-sm-2 mb-0 text-secondary">
              End:&nbsp;
              <span className="text-primary-theme fw-bold">
                {convertTimeFormat(departureData?.[departureData.length - 1])}
              </span>
            </p>
          </div>
          <Slider
            value={deptValue}
            onChange={handleChangeDeptValue}
            valueLabelDisplay="auto"
            valueLabelFormat={formatTimeValue}
            min={parseTimeValue(departureData[0])}
            max={parseTimeValue(departureData[departureData.length - 1])}
          />
          {sessionStorage.getItem("wayOfFlight") === "round_trip" && (
            <>
            <div className="border-dashed mx-auto mb-2"></div>
              <p className="fs-sm-2 mb-0 text-secondary">
                Take-off from&nbsp;
                <span className="text-primary-theme fw-bold">
                  {sessionStorage.getItem("destinationLocationCode")}
                </span>
              </p>
              <div className="d-flex justify-content-between">
                <p className="fs-sm-2 mb-0 text-secondary">
                  Start:&nbsp;
                  <span className="text-primary-theme fw-bold">
                    {convertTimeFormat(arrivalData?.[0])}
                  </span>
                </p>
                <p className="fs-sm-2 mb-0 text-secondary">
                  End:&nbsp;
                  <span className="text-primary-theme fw-bold">
                    {convertTimeFormat(arrivalData?.[departureData.length - 1])}
                  </span>
                </p>
              </div>
              <Slider
                value={arrivValue}
                onChange={handleChangeArrivValue}
                valueLabelDisplay="auto"
                valueLabelFormat={formatTimeValue}
                min={parseTimeValue(arrivalData[0])}
                max={parseTimeValue(arrivalData[arrivalData.length - 1])}
              />
            </>
          )}
        </div>
        <div className="card-body pb-1">
          <p className="fw-bold text-uppercase mb-1">Airlines</p>
          <div className="d-flex justify-content-between gap-3 mb-2">
            <span
              className="btn theme-btn-outline-primary btn-sm btn-rounded"
              onClick={handleSelectAll}
            >
              Select All
            </span>
            <span
              className="btn theme-btn-outline-primary btn-sm btn-rounded"
              onClick={handleClearAll}
            >
              Clear All
            </span>
          </div>
          {priceWithAirlines?.map((flight, index) => {
            return (
              <div className="d-flex justify-content-between gap-3 text-nowrap" key={index}>
                <div className="form-check">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    value={flight.airline_code}
                    // id={flight.id}
                    checked={selectedAirlines?.includes(
                      flight.airline_code
                    )}
                    onChange={() =>
                      handleAirlineCheckboxChange(
                        flight.airline_code
                      )
                    }
                  />
                  <label
                    className="form-check-label text-capitalize mw-140p text-truncate"
                    htmlFor={`checkbox-${index}`}
                  >
                    {flight?.airline_name}
                  </label>
                </div>
                <p className="mb-0">$ {parseFloat(flight.totalPrice)?.toFixed(2)}</p>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default FilterSideBar;
