import React from "react";

const NilhansCard = ({ data, setEditCard }) => {
  return (
    <div className="custom-debit-card-card mx-auto">
      <div className="custom-debit-card-front p-3 custom-card-shadow">
        <div className="d-flex align-items-center justify-content-between">
          <p className="mb-0 text-uppercase fw-bold h4 fst-italic">
            {data?.card_name}
          </p>
          <span
            className="btn btn-icon text-white rounded-circle"
            data-bs-toggle="modal"
            data-bs-target="#add_new_card"
            onClick={() => setEditCard(data)}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              fill="currentColor"
              class="bi bi-pencil-square"
              viewBox="0 0 16 16"
            >
              <path d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z" />
              <path
                fill-rule="evenodd"
                d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5z"
              />
            </svg>
          </span>
        </div>
        <div className="custom-debit-card-card-no">
          <p className="mb-0 w-100 text-truncate">{data?.card_no}</p>
        </div>
        <div className="d-flex align-items-center justify-content-between custom-debit-card-card-holder">
          <p className="text-start mb-0">
            CARD HOLDER
            <br />
            <span className="fw-bold">{data?.name_on_card}</span>
          </p>
          <p className="text-end mb-0 text-uppercase">
            Expires
            <br />
            <span className="fw-bold">{data?.card_expiry_date}</span>
          </p>
        </div>
      </div>
    </div>
  );
};

export default NilhansCard;
