const flightPrefix = "frontend";
const staffPrefix = "staff";
const userPrefix = "user";
const bookingPrefix = "booking";
const managementPrefix = "management";
const staffprefix = "staff";
const blogPrefix = "blog";
const authPrefix = "auth";

export const FlightRelatedEndpoints = {
  getFlights: flightPrefix + "/MasterPricer",
  getBlogs: blogPrefix + "/get-blogs",
  getBlogDetails: blogPrefix + "/get-blog-by-id",
  getOffers: flightPrefix + "/get-all-offers",
  sumitOfferInquiry: flightPrefix + "/sumit_offer_inquiry",
  addBillInfo: flightPrefix + "/add-bill-info"
};

export const AuthenticationEndpoints = {
  userSignup: staffPrefix + "/add-staff",
  userLogin: staffPrefix + "/login-staff"
}

export const CardRelatedEndpoints = {
  addCard: userPrefix + "/add-card",
  getAllCards: userPrefix + "/get-all-cards",
  editCard: userPrefix + "/edit-card"
}

export const BookingRelatedEndpoints = {
  getAllBookings: bookingPrefix + "/get-all-bookings",
}

export const UserRelatedEndpoints = {
  getUserData: managementPrefix + "/get-customers",
  editStaff: staffprefix + "/edit-staff",
  getCardDetails: staffPrefix + "/get-credit-card"
}

export const forgotPasswordEndpoints = {
  sendOtpForResetPassword: authPrefix + "/send-otp-forgot-password",
  validateOtpForResetPassword: authPrefix + "/validate-otp-forgot-password",
  resetPassword: authPrefix + "/reset-password"
}
