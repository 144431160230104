import React, { useEffect, useRef, useState } from "react";
import PassengersModal from "../../Components/PassengersModal/PassengersModal";
import CardCarousel from "../../Components/CardCarousel/CardCarousel";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import { useNavigate } from "react-router-dom";
import BeautifulPlaces from "./BeautifulPlaces";
import ProfessionalPlanners from "./ProfessionalPlanners";
import WhyChooseUs from "./WhyChooseUs";
import { startApiCall } from "../../Components/GlobalFunction/GlobalFunction";

const Home = () => {
  const navigate = useNavigate();
  const [apiToken, setApiToken] = useState("");
  const departureDatePickerRef = useRef();
  const datePickerRef = useRef();
  const travellerRef = useRef();
  const toInputRef = useRef();
  const [state, setState] = useState({
    setShow: false,
    iatacode_from: sessionStorage.getItem("iatacode_from") || "BHM",
    airport_name_from:
      sessionStorage.getItem("airport_name_from") || "SHUTTLESWORTH INTL",
    country_from: sessionStorage.getItem("country_from") || "US",
    city_from: sessionStorage.getItem("city_from") || "BIRMINGHAM",
    iatacode_to: sessionStorage.getItem("iatacode_to") || "DEN",
    airport_name_to:
      sessionStorage.getItem("airport_name_to") || "DENVER INTERNATIONAL",
    country_to: sessionStorage.getItem("country_to") || "US",
    city_to: sessionStorage.getItem("city_to") || "DENVER",
    departuredate: dayjs(sessionStorage.getItem("departureDate") || new Date()),
    returndate: dayjs(
      sessionStorage.getItem("returnDate") || dayjs().add(1, "day")
    ),
    // returndate: format(
    //   new Date().setDate(new Date().getDate() + 1),
    //   "yyyy-MM-dd"
    // ),
    child: 0,
    adult: 1,
    infants: 0,
    totalCounts: 1,
    t_class: [
      { name: "Economy", value: "ECONOMY" },
      { name: "Premium Economy", value: "PREMIUM_ECONOMY" },
      { name: "Business", value: "BUSINESS" },
      { name: "First", value: "FIRST" },
    ],
    t_class_checked: 0,
    t_class_selected: "ECONOMY",
    trip: sessionStorage.getItem("wayOfFlight") || "round_trip",
    currencyCode: "USD",
    selectedCurrency: "USD",
    selectedAirline: "",
    isDirect: false,
    showFormModal: false,
    showTimeOutModal: false,
    timer: null,
  });
  const fromInputRef = useRef(null);
  const [locationFrom, setLocationFrom] = useState(state.iatacode_from);
  const [fromData, setFromData] = useState([]);
  const [locationTo, setLocationTo] = useState(state.iatacode_to);
  const [toData, setToData] = useState([]);

  const handleChange = (field, value) => {
    if (field === "departuredate") {
      const newDepartureDate = dayjs(value);
      const newReturnDate = newDepartureDate.add(1, "day");
      setState((prevState) => ({
        ...prevState,
        departuredate: newDepartureDate,
        returndate: newReturnDate,
      }));
    } else {
      setState((prevState) => ({
        ...prevState,
        [field]: value,
      }));
    }
  };

  const handleTripChange = (tripType) => {
    sessionStorage.setItem("wayOfFlight", tripType);
    handleChange("trip", tripType);
  };

  const cardData = [
    {
      text: "Booking my flight was seamless and efficient. The user-friendly website offered various options, and the quick confirmation email provided peace of mind. A hassle-free experience from start to finish.",
      author: "Thomas K.",
      rating: 4,
    },
    {
      text: "Booking my flight was seamless and efficient. The user-friendly website offered various options, and the quick confirmation email provided peace of mind. A hassle-free experience from start to finish.",
      author: "Kelly J.",
      rating: 5,
    },
    {
      text: "Booking my flight was seamless and efficient. The user-friendly website offered various options, and the quick confirmation email provided peace of mind. A hassle-free experience from start to finish.",
      author: "Sofia K.",
      rating: 4,
    },
    {
      text: "Booking my flight was seamless and efficient. The user-friendly website offered various options, and the quick confirmation email provided peace of mind. A hassle-free experience from start to finish.",
      author: "Thomas K.",
      rating: 4,
    },
    {
      text: "Booking my flight was seamless and efficient. The user-friendly website offered various options, and the quick confirmation email provided peace of mind. A hassle-free experience from start to finish.",
      author: "Kelly J.",
      rating: 5,
    },
    {
      text: "Booking my flight was seamless and efficient. The user-friendly website offered various options, and the quick confirmation email provided peace of mind. A hassle-free experience from start to finish.",
      author: "Sofia K.",
      rating: 4,
    },
  ];

  useEffect(() => {
    const currentDate = dayjs();
    const returnDate = currentDate.add(1, "day");

    sessionStorage.setItem("departureDate", currentDate.format("YYYY-MM-DD"));
    sessionStorage.setItem("returnDate", returnDate.format("YYYY-MM-DD"));

    setState((prevState) => ({
      ...prevState,
      departuredate: currentDate,
      returndate: returnDate,
    }));
  }, []);

  useEffect(() => {
    sessionStorage.setItem(
      "departureDate",
      state.departuredate.format("YYYY-MM-DD")
    );
    sessionStorage.setItem("returnDate", state.returndate.format("YYYY-MM-DD"));
  }, [state.departuredate, state.returndate]);

  const fetchToken = async () => {
    try {
      const response = await fetch(
        "https://test.api.amadeus.com/v1/security/oauth2/token",
        {
          method: "POST",
          headers: new Headers({
            "Content-Type": "application/x-www-form-urlencoded", // <-- Specifying the Content-Type
          }),
          body: "grant_type=client_credentials&client_id=lFD9B6KFg1j3kMKGLzFgadbAKFEzOwVw&client_secret=XmvZgKrjBJAiAMfE", // <-- Post parameters
        }
      );

      const res = await response.json();
      if (res.access_token) {
        setApiToken(res.access_token);
      } else {
        alert(JSON.stringify(res));
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    fetchToken();
  }, []);

  const searchFrom = (val) => {
    if (val === locationTo) {
      alert("origin and destination locations cannot be the same.");
      setLocationFrom("");
      setState((prevState) => ({
        ...prevState,
        airport_name_from: "",
        country_from: "",
      }));
      return;
    }
    setLocationFrom(val);

    fetch(
      "https://test.api.amadeus.com/v1/reference-data/locations?subType=CITY,AIRPORT&page[limit]=5&keyword=" +
        val,
      {
        method: "GET",
        headers: new Headers({
          Authorization: `Bearer ${apiToken}`,
        }),
      }
    )
      .then((response) => response.json())
      .then((res) => {
        if (res) {
          const data = res.data;
          for (let i = 0; i < data?.length; i++) {
            if (val === data[i].iataCode) {
              sessionStorage.setItem("iatacode_from", data[i].iataCode);
              sessionStorage.setItem("airport_name_from", data[i].name);
              sessionStorage.setItem(
                "country_from",
                data[i].address.countryCode
              );
              sessionStorage.setItem("city_from", data[i].address.cityName);
              setState((prevState) => ({
                ...prevState,
                iatacode_from: data[i].iataCode,
                airport_name_from: data[i].name,
                country_from: data[i].address.countryCode,
                city_from: data[i].address.cityName,
              }));
            }
          }
          setFromData(data);
        } else {
          alert(JSON.stringify(res));
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const swapValues = () => {
    setState((prevState) => ({
      ...prevState,
      city_from: prevState.city_to,
      iatacode_from: prevState.iatacode_to,
      airport_name_from: prevState.airport_name_to,
      country_from: prevState.country_to,
      city_to: prevState.city_from,
      iatacode_to: prevState.iatacode_from,
      airport_name_to: prevState.airport_name_from,
      country_to: prevState.country_from,
    }));
    setLocationFrom(state.iatacode_to);
    setLocationTo(state.iatacode_from);
  };

  const searchTo = (val) => {
    if (val === locationFrom) {
      alert("origin and destination locations cannot be the same.");
      setLocationTo("");
      setState((prevState) => ({
        ...prevState,
        airport_name_to: "",
        country_to: "",
      }));
      return;
    }
    setLocationTo(val);
    fetch(
      "https://test.api.amadeus.com/v1/reference-data/locations?subType=CITY,AIRPORT&page[limit]=5&keyword=" +
        val,
      {
        method: "GET",
        headers: new Headers({
          Authorization: `Bearer ${apiToken}`,
        }),
      }
    )
      .then((response) => response.json())
      .then((res) => {
        if (res) {
          const data = res.data;
          for (let i = 0; i < data?.length; i++) {
            if (val === data[i].iataCode) {
              sessionStorage.setItem("iatacode_to", data[i].iataCode);
              sessionStorage.setItem("airport_name_to", data[i].name);
              sessionStorage.setItem("country_to", data[i].address.countryCode);
              sessionStorage.setItem("city_to", data[i].address.cityName);
              setState((prevState) => ({
                ...prevState,
                iatacode_to: data[i].iataCode,
                airport_name_to: data[i].name,
                country_to: data[i].address.countryCode,
                city_to: data[i].address.cityName,
              }));
            }
          }
          setToData(data);
        } else {
          alert(JSON.stringify(res));
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const searchflights = () => {
    sessionStorage.setItem("originLocationCode", state.iatacode_from);
    sessionStorage.setItem("destinationLocationCode", state.iatacode_to);
    sessionStorage.setItem("adults", state.adult);
    sessionStorage.setItem("children", state.child);
    sessionStorage.setItem("infants", state.infants);
    sessionStorage.setItem("travelClass", state.t_class_selected);
    sessionStorage.setItem("currencyCode", state.currencyCode);
    sessionStorage.setItem("wayOfFlight", state.trip);
    sessionStorage.setItem("selected_airline", state.selectedAirline);
    sessionStorage.setItem("airport_name_from", state.airport_name_from);
    sessionStorage.setItem("country_from", state.country_from);
    sessionStorage.setItem("city_from", state.city_from);
    sessionStorage.setItem("iatacode_from", state.iatacode_from);
    sessionStorage.setItem("airport_name_to", state.airport_name_to);
    sessionStorage.setItem("country_to", state.country_to);
    sessionStorage.setItem("city_to", state.city_to);
    sessionStorage.setItem("iatacode_to", state.iatacode_to);
    navigate("/flights");
  };

  const returnDay = (day) => {
    const daysOfWeek = [
      "Sunday",
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday",
      "Saturday",
    ];
    const dayName = daysOfWeek[day];
    return dayName;
  };

  return (
    <>
      <div className="d-flex flex-column gap-3">
        <div className="custom-container bg-theme-secondary">
          <div className="home-banner">
            <div className="row">
              <div className="col-md-8 banner-text">
                <p className="text-center mb-0 text-white">
                  Fly&nbsp;
                  <span className="text-primary-dark-theme">Smarter</span>
                </p>
                <p className="text-center mb-0 text-white">
                  Travel&nbsp;
                  <span className="text-primary-dark-theme">Better</span>
                </p>
              </div>
            </div>
          </div>
          <form className="bg-white rounded-10p p-3">
            <div className="d-flex align-items-start justify-content-between gap-3">
              <ul className="trip-tab ps-0">
                <li>
                  <span
                    className={`btn rounded-10p ${
                      state.trip === "one_way"
                        ? "theme-btn-outline-primary-dark"
                        : "btn-light"
                    }`}
                    onClick={() => handleTripChange("one_way")}
                  >
                    One way
                  </span>
                </li>
                <li>
                  <span
                    className={`btn rounded-10p ${
                      state.trip === "round_trip"
                        ? "theme-btn-outline-primary-dark"
                        : "btn-light"
                    }`}
                    onClick={() => handleTripChange("round_trip")}
                  >
                    Round trip
                  </span>
                </li>
              </ul>
              <span
                className="btn theme-btn-outline-primary-dark rounded-10p"
                data-bs-toggle="modal"
                data-bs-target="#add_passengers_modal"
              >
                {state.adult + state.child + state.infants} Traveller,{" "}
                {state.t_class_selected}
              </span>
            </div>
            <div className="row g-3">
              <div className="col-lg-6">
                <div className="card rounded-10p">
                  <div className="card-body py-0">
                    <div className="d-flex align-items-center gap-2">
                      <div className="d-flex flex-column gap-1 w-100">
                        <label htmlFor="" className="mb-0 text-secondary ps-2">
                          From
                        </label>
                        <input
                          type="text"
                          className="banner-form-input w-100"
                          required
                          ref={fromInputRef}
                          list="fromdata"
                          value={locationFrom}
                          onChange={(e) => searchFrom(e.target.value)}
                        />
                        <datalist id="fromdata">
                          {fromData?.length > 0 && (
                            <>
                              {fromData.map((item) => (
                                <option
                                  key={item.iataCode}
                                  value={item.iataCode}
                                >
                                  {item.name}, {item.address.cityName}{" "}
                                  {item.address.countryName}
                                </option>
                              ))}
                            </>
                          )}
                        </datalist>
                      </div>
                      <span
                        className="border p-2 rounded-circle"
                        onClick={swapValues}
                      >
                        <span className="btn btn-icon-lg btn-light rounded-circle">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="16"
                            height="16"
                            fill="#044e04"
                            className="bi bi-arrow-down-up"
                            viewBox="0 0 16 16"
                            style={{ rotate: "90deg" }}
                          >
                            <path
                              fill-rule="evenodd"
                              d="M11.5 15a.5.5 0 0 0 .5-.5V2.707l3.146 3.147a.5.5 0 0 0 .708-.708l-4-4a.5.5 0 0 0-.708 0l-4 4a.5.5 0 1 0 .708.708L11 2.707V14.5a.5.5 0 0 0 .5.5m-7-14a.5.5 0 0 1 .5.5v11.793l3.146-3.147a.5.5 0 0 1 .708.708l-4 4a.5.5 0 0 1-.708 0l-4-4a.5.5 0 0 1 .708-.708L4 13.293V1.5a.5.5 0 0 1 .5-.5"
                            />
                          </svg>
                        </span>
                      </span>
                      <div className="d-flex flex-column gap-1 w-100">
                        <label htmlFor="" className="mb-0 text-secondary ps-2">
                          To
                        </label>
                        <input
                          type="text"
                          className="banner-form-input w-100"
                          required
                          ref={toInputRef}
                          list="todata"
                          value={locationTo}
                          onChange={(e) => searchTo(e.target.value)}
                        />
                        <datalist id="todata">
                          {toData?.length > 0 && (
                            <>
                              {toData.map((item) => (
                                <option
                                  key={item.iataCode}
                                  value={item.iataCode}
                                >
                                  {item.name}, {item.address.cityName}{" "}
                                  {item.address.countryName}
                                </option>
                              ))}
                            </>
                          )}
                        </datalist>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="d-flex justify-content-between gap-3">
                  <div className="w-100 px-4 pt-1">
                    <p className="fs-sm mb-0 fw-bold">
                      {state.airport_name_from}&nbsp;
                      {state.country_from}
                    </p>
                  </div>
                  <div className="w-100 pt-1" style={{ paddingLeft: "2.7rem" }}>
                    <p className="fs-sm mb-0 fw-bold">
                      {state.airport_name_to}&nbsp;
                      {state.country_to}
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="row g-3">
                  <div className="col-md-4 col-sm-6">
                    <div className="card rounded-10p">
                      <div
                        className="card-body"
                        style={{ paddingBlock: "5.5px" }}
                      >
                        <div className="d-flex flex-column gap-1">
                          <label
                            htmlFor=""
                            className="mb-0 text-secondary ps-2"
                          >
                            Departure
                          </label>
                          <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DatePicker
                              value={state.departuredate}
                              onChange={(newValue) =>
                                handleChange("departuredate", newValue)
                              }
                              views={["year", "month", "day"]}
                              minDate={dayjs()}
                              renderInput={(params) => (
                                <input
                                  {...params}
                                  className="banner-form-input w-100"
                                />
                              )}
                            />
                          </LocalizationProvider>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-4 col-sm-6">
                    {state.trip === "round_trip" && (
                      <div className="card rounded-10p">
                        <div
                          className="card-body"
                          style={{ paddingBlock: "5.5px" }}
                        >
                          <div className="d-flex flex-column gap-1">
                            <label
                              htmlFor=""
                              className="mb-0 text-secondary ps-2"
                            >
                              Return
                            </label>
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                              <DatePicker
                                value={state.returndate}
                                onChange={(newValue) =>
                                  handleChange("returndate", newValue)
                                }
                                views={["year", "month", "day"]}
                                minDate={dayjs(state.departuredate)}
                                renderInput={(params) => (
                                  <input
                                    {...params}
                                    className="banner-form-input w-100"
                                  />
                                )}
                              />
                            </LocalizationProvider>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                  <div className="col-md-4">
                    <span
                      className="btn btn-lg btn-rounded theme-btn-primary w-100 h-100"
                      style={{
                        display: "grid",
                        placeItems: "center",
                        fontSize: "26px",
                        fontWeight: "600",
                      }}
                      onClick={searchflights}
                    >
                      Search
                    </span>
                  </div>
                </div>
                <div className="d-flex justify-content-between gap-3 w-75">
                  <div className="w-75 px-2 pt-1">
                    <p className="fs-sm mb-0 fw-bold">
                      {returnDay(dayjs(state.departuredate).day())}
                    </p>
                  </div>
                  {state.trip === "round_trip" && (
                    <div className="w-100 px-2 pt-1">
                      <p className="fs-sm mb-0 fw-bold">
                        {returnDay(dayjs(state.returndate).day())}
                      </p>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </form>
        </div>
        <BeautifulPlaces />
        <ProfessionalPlanners />
        <WhyChooseUs />
        <div className="custom-container py-5 bg-theme-secondary">
          <CardCarousel cardData={cardData} />
        </div>
      </div>

      <PassengersModal
        adults={state.adult}
        children={state.child}
        infants={state.infants}
        travelClass={state.t_class}
        selectedTravelClass={state.t_class_selected}
        setState={setState}
        onChange={handleChange}
      />
    </>
  );
};

export default Home;
