import React, { useEffect, useState } from "react";
import dayjs from "dayjs";
import OtherApiCall from "../../../api/OtherApiCall";
import { BookingRelatedEndpoints } from "../../../api/Endpoints";
import { startApiCall } from "../../../Components/GlobalFunction/GlobalFunction";
import Skeleton from "@mui/material/Skeleton";

const Canceled = ({ login_data }) => {
  const [allBooking, setAllBookings] = useState([]);
  const [loader, setloader] = useState(false);

  const handleGetAllBookings = async () => {
    startApiCall(null, setloader);
    const data = {
      page: 1,
      limit: 200,
      filters: {
        customer: login_data?.user?._id,
        status: "Cancel",
      },
    };
    const res = await OtherApiCall(
      "post",
      BookingRelatedEndpoints.getAllBookings,
      data
    );
    if (res?.success) {
      setAllBookings(res?.result?.data);
      setloader(false);
    } else {
      setloader(false);
    }
  };

  useEffect(() => {
    handleGetAllBookings();
  }, []);

  return (
    <>
      {loader && (
        <>
          <div className="d-flex flex-column">
            <div className="card mt-3">
              <div className="card-body">
                <Skeleton
                  variant="text"
                  sx={{ fontSize: "1.5rem" }}
                  height={50}
                  width={"100%"}
                />
                <Skeleton
                  variant="text"
                  sx={{ fontSize: "1.5rem" }}
                  height={10}
                  width={"100%"}
                />
                <Skeleton
                  variant="text"
                  sx={{ fontSize: "1.5rem" }}
                  height={50}
                  width={"100%"}
                />
                <Skeleton
                  variant="text"
                  sx={{ fontSize: "1.5rem" }}
                  height={50}
                  width={"100%"}
                />
              </div>
            </div>
            <div className="card mt-3">
              <div className="card-body">
                <Skeleton
                  variant="text"
                  sx={{ fontSize: "1.5rem" }}
                  height={50}
                  width={"100%"}
                />
                <Skeleton
                  variant="text"
                  sx={{ fontSize: "1.5rem" }}
                  height={10}
                  width={"100%"}
                />
                <Skeleton
                  variant="text"
                  sx={{ fontSize: "1.5rem" }}
                  height={50}
                  width={"100%"}
                />
                <Skeleton
                  variant="text"
                  sx={{ fontSize: "1.5rem" }}
                  height={50}
                  width={"100%"}
                />
              </div>
            </div>
            <div className="card mt-3">
              <div className="card-body">
                <Skeleton
                  variant="text"
                  sx={{ fontSize: "1.5rem" }}
                  height={50}
                  width={"100%"}
                />
                <Skeleton
                  variant="text"
                  sx={{ fontSize: "1.5rem" }}
                  height={10}
                  width={"100%"}
                />
                <Skeleton
                  variant="text"
                  sx={{ fontSize: "1.5rem" }}
                  height={50}
                  width={"100%"}
                />
                <Skeleton
                  variant="text"
                  sx={{ fontSize: "1.5rem" }}
                  height={50}
                  width={"100%"}
                />
              </div>
            </div>
          </div>
        </>
      )}
      {!loader && allBooking?.length > 0 && (
        <>
          {allBooking?.map((item) => {
            return (
              <div className="d-flex flex-column gap-3">
                <div className="card rounded-10p custom-card-shadow border-primary-theme">
                  <div className="card-body">
                    <div className="d-flex flex-column gap-3">
                      <div className="d-flex align-items-center justify-content-center gap-3 flex-wrap">
                        <img
                          className="flight-ticket-img"
                          src={
                            item?.booking_details?.itineraries?.[0]
                              ?.segments?.[0]?.airlineImage
                          }
                          alt=""
                          loading="lazy"
                        />

                        <div className="d-flex align-items-center gap-3 py-1 px-2">
                          <div className="text-end">
                            <p className="mb-0 fw-bold">
                              {
                                item?.booking_details?.itineraries?.[0]
                                  ?.segments?.[0]?.departure?.iataCode?.[0]
                              }
                            </p>
                            <p className="mb-0">
                              {dayjs(
                                item?.booking_details?.itineraries?.[0]
                                  ?.segments?.[0]?.departure?.at
                              ).format("DD/MM/YYYY - h:mm A")}
                            </p>
                          </div>
                          <div className="d-flex align-items-center">
                            <svg
                              width="20"
                              height="18"
                              viewBox="0 0 20 18"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M14 7H18C18.5304 7 19.0391 7.21071 19.4142 7.58579C19.7893 7.96086 20 8.46957 20 9C20 9.53043 19.7893 10.0391 19.4142 10.4142C19.0391 10.7893 18.5304 11 18 11H14L10 18H7L9 11H5L3 13H0L2 9L0 5H3L5 7H9L7 0H10L14 7Z"
                                fill="#D9D9D9"
                              />
                            </svg>
                            <div className="d-flex flex-column align-items-center gap-1">
                              <p className="mb-0">
                                {
                                  item?.booking_details?.itineraries?.[0]
                                    ?.duration
                                }
                              </p>
                              <svg
                                width="100%"
                                height="1"
                                viewBox="0 0 188 1"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <line
                                  y1="0.5"
                                  x2="188"
                                  y2="0.5"
                                  stroke="#D9D9D9"
                                />
                              </svg>
                              <p className="mb-0">
                                {item?.booking_details?.itineraries?.[0]
                                  ?.segments?.length === 1 ? (
                                  "Direct"
                                ) : (
                                  <span className="pointer">
                                    {item?.booking_details?.itineraries?.[0]
                                      ?.segments?.length - 1}
                                    &nbsp;Stop
                                  </span>
                                )}
                              </p>
                            </div>
                            <svg
                              width="13"
                              height="15"
                              viewBox="0 0 13 15"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M11.0079 2.2842C12.1783 3.45446 12.8477 5.03403 12.8745 6.68891C12.9013 8.34379 12.2835 9.94423 11.1517 11.1518L11.0079 11.3006L8.00241 14.3053C7.62107 14.6864 7.10912 14.9083 6.57027 14.9261C6.03143 14.9438 5.50598 14.7561 5.10037 14.4009L4.99837 14.3053L1.99221 11.2999C0.796654 10.1043 0.125 8.4828 0.125 6.79203C0.125 5.10127 0.796654 3.47975 1.99221 2.2842C3.18776 1.08865 4.80927 0.416992 6.50004 0.416992C8.1908 0.416992 9.81232 1.08865 11.0079 2.2842ZM6.50004 4.66703C6.22098 4.66703 5.94465 4.722 5.68684 4.82879C5.42902 4.93558 5.19476 5.0921 4.99744 5.28943C4.80011 5.48675 4.64359 5.72101 4.53679 5.97883C4.43 6.23665 4.37504 6.51297 4.37504 6.79203C4.37504 7.07109 4.43 7.34742 4.53679 7.60523C4.64359 7.86305 4.80011 8.09731 4.99744 8.29463C5.19476 8.49196 5.42902 8.64848 5.68684 8.75528C5.94465 8.86207 6.22098 8.91703 6.50004 8.91703C7.06362 8.91703 7.60413 8.69315 8.00264 8.29463C8.40116 7.89612 8.62504 7.35562 8.62504 6.79203C8.62504 6.22845 8.40116 5.68794 8.00264 5.28943C7.60413 4.89091 7.06362 4.66703 6.50004 4.66703Z"
                                fill="#D9D9D9"
                              />
                            </svg>
                          </div>
                          <div className="text-start">
                            <p className="mb-0 fw-bold">
                              {
                                item?.booking_details?.itineraries?.[0]
                                  ?.segments[
                                  item?.booking_details?.itineraries?.[0]
                                    ?.segments?.length - 1
                                ]?.arrival?.iataCode?.[0]
                              }
                            </p>
                            <p className="mb-0">
                              {dayjs(
                                item?.booking_details?.itineraries?.[0]
                                  ?.segments[
                                  item?.booking_details?.itineraries?.[0]
                                    ?.segments?.length - 1
                                ]?.arrival?.at
                              ).format("DD/MM/YYYY - h:mm A")}
                            </p>
                          </div>
                        </div>
                      </div>
                      {item?.wayOfFlight === "round_trip" && (
                        <div className="d-flex align-items-center justify-content-center gap-3 flex-wrap">
                          <img
                            className="flight-ticket-img"
                            src={
                              item?.booking_details?.itineraries?.[1]
                                ?.segments?.[0]?.airlineImage
                            }
                            alt=""
                            loading="lazy"
                          />

                          <div className="d-flex align-items-center gap-3 py-1 px-2">
                            <div className="text-end">
                              <p className="mb-0 fw-bold">
                                {
                                  item?.booking_details?.itineraries?.[1]
                                    ?.segments?.[0]?.departure?.iataCode?.[0]
                                }
                              </p>
                              <p className="mb-0">
                                {dayjs(
                                  item?.booking_details?.itineraries?.[1]
                                    ?.segments?.[0]?.departure?.at
                                ).format("DD/MM/YYYY - h:mm A")}
                              </p>
                            </div>
                            <div className="d-flex align-items-center">
                              <svg
                                width="20"
                                height="18"
                                viewBox="0 0 20 18"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M14 7H18C18.5304 7 19.0391 7.21071 19.4142 7.58579C19.7893 7.96086 20 8.46957 20 9C20 9.53043 19.7893 10.0391 19.4142 10.4142C19.0391 10.7893 18.5304 11 18 11H14L10 18H7L9 11H5L3 13H0L2 9L0 5H3L5 7H9L7 0H10L14 7Z"
                                  fill="#D9D9D9"
                                />
                              </svg>
                              <div className="d-flex flex-column align-items-center gap-1">
                                <p className="mb-0">
                                  {
                                    item?.booking_details?.itineraries?.[1]
                                      ?.duration
                                  }
                                </p>
                                <svg
                                  width="100%"
                                  height="1"
                                  viewBox="0 0 188 1"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <line
                                    y1="0.5"
                                    x2="188"
                                    y2="0.5"
                                    stroke="#D9D9D9"
                                  />
                                </svg>
                                <p className="mb-0">
                                  {item?.booking_details?.itineraries?.[1]
                                    ?.segments?.length === 1 ? (
                                    "Direct"
                                  ) : (
                                    <span className="pointer">
                                      {item?.booking_details?.itineraries?.[1]
                                        ?.segments?.length - 1}
                                      &nbsp;Stop
                                    </span>
                                  )}
                                </p>
                              </div>
                              <svg
                                width="13"
                                height="15"
                                viewBox="0 0 13 15"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M11.0079 2.2842C12.1783 3.45446 12.8477 5.03403 12.8745 6.68891C12.9013 8.34379 12.2835 9.94423 11.1517 11.1518L11.0079 11.3006L8.00241 14.3053C7.62107 14.6864 7.10912 14.9083 6.57027 14.9261C6.03143 14.9438 5.50598 14.7561 5.10037 14.4009L4.99837 14.3053L1.99221 11.2999C0.796654 10.1043 0.125 8.4828 0.125 6.79203C0.125 5.10127 0.796654 3.47975 1.99221 2.2842C3.18776 1.08865 4.80927 0.416992 6.50004 0.416992C8.1908 0.416992 9.81232 1.08865 11.0079 2.2842ZM6.50004 4.66703C6.22098 4.66703 5.94465 4.722 5.68684 4.82879C5.42902 4.93558 5.19476 5.0921 4.99744 5.28943C4.80011 5.48675 4.64359 5.72101 4.53679 5.97883C4.43 6.23665 4.37504 6.51297 4.37504 6.79203C4.37504 7.07109 4.43 7.34742 4.53679 7.60523C4.64359 7.86305 4.80011 8.09731 4.99744 8.29463C5.19476 8.49196 5.42902 8.64848 5.68684 8.75528C5.94465 8.86207 6.22098 8.91703 6.50004 8.91703C7.06362 8.91703 7.60413 8.69315 8.00264 8.29463C8.40116 7.89612 8.62504 7.35562 8.62504 6.79203C8.62504 6.22845 8.40116 5.68794 8.00264 5.28943C7.60413 4.89091 7.06362 4.66703 6.50004 4.66703Z"
                                  fill="#D9D9D9"
                                />
                              </svg>
                            </div>
                            <div className="text-start">
                              <p className="mb-0 fw-bold">
                                {
                                  item?.booking_details?.itineraries?.[1]
                                    ?.segments[
                                    item?.booking_details?.itineraries?.[0]
                                      ?.segments?.length - 1
                                  ]?.arrival?.iataCode?.[0]
                                }
                              </p>
                              <p className="mb-0">
                                {dayjs(
                                  item?.booking_details?.itineraries?.[1]
                                    ?.segments[
                                    item?.booking_details?.itineraries?.[1]
                                      ?.segments?.length - 1
                                  ]?.arrival?.at
                                ).format("DD/MM/YYYY - h:mm A")}
                              </p>
                            </div>
                          </div>
                        </div>
                      )}
                      <div className="mx-auto">
                        <div className="d-flex align-items-center gap-3 flex-wrap">
                          <span className="d-flex align-items-center gap-1">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="16"
                              height="16"
                              fill="currentColor"
                              className="bi bi-check-square-fill text-primary-theme"
                              viewBox="0 0 16 16"
                            >
                              <path d="M2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2zm10.03 4.97a.75.75 0 0 1 .011 1.05l-3.992 4.99a.75.75 0 0 1-1.08.02L4.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093 3.473-4.425a.75.75 0 0 1 1.08-.022z" />
                            </svg>
                            <span className="fw-bold text-primary-dark-theme">
                              Class :
                            </span>
                            <span> {item?.travelClass}</span>
                          </span>

                          <span className="d-flex align-items-center gap-1">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="16"
                              height="16"
                              fill="currentColor"
                              className="bi bi-airplane-engines-fill text-primary-theme"
                              viewBox="0 0 16 16"
                            >
                              <path d="M8 0c-.787 0-1.292.592-1.572 1.151A4.35 4.35 0 0 0 6 3v3.691l-2 1V7.5a.5.5 0 0 0-.5-.5h-1a.5.5 0 0 0-.5.5v1.191l-1.17.585A1.5 1.5 0 0 0 0 10.618V12a.5.5 0 0 0 .582.493l1.631-.272.313.937a.5.5 0 0 0 .948 0l.405-1.214 2.21-.369.375 2.253-1.318 1.318A.5.5 0 0 0 5.5 16h5a.5.5 0 0 0 .354-.854l-1.318-1.318.375-2.253 2.21.369.405 1.214a.5.5 0 0 0 .948 0l.313-.937 1.63.272A.5.5 0 0 0 16 12v-1.382a1.5 1.5 0 0 0-.83-1.342L14 8.691V7.5a.5.5 0 0 0-.5-.5h-1a.5.5 0 0 0-.5.5v.191l-2-1V3c0-.568-.14-1.271-.428-1.849C9.292.591 8.787 0 8 0" />
                            </svg>
                            <span className="fw-bold text-primary-dark-theme">
                              Trip type :
                            </span>
                            <span>
                              {item?.wayOfFlight === "one_way"
                                ? "One Way"
                                : "Round Trip"}
                            </span>
                          </span>

                          <span className="d-flex gap-1">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="16"
                              height="16"
                              fill="currentColor"
                              className="bi bi-check-square-fill mt-1 text-primary-theme"
                              viewBox="0 0 16 16"
                            >
                              <path d="M2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2zm10.03 4.97a.75.75 0 0 1 .011 1.05l-3.992 4.99a.75.75 0 0 1-1.08.02L4.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093 3.473-4.425a.75.75 0 0 1 1.08-.022z" />
                            </svg>
                            <span className="fw-bold text-nowrap text-primary-dark-theme">
                              Check-in :
                            </span>
                            <span>
                              Adult:&nbsp;{item?.adults},&nbsp;&nbsp;
                              {item?.children !== "0" && (
                                <>Child: {item?.children},</>
                              )}
                              &nbsp;&nbsp;
                              {item?.infants !== "0" && (
                                <>Infant: {item?.infants}</>
                              )}
                            </span>
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
        </>
      )}
      {!loader && allBooking?.length === 0 && (
        <span className="d-flex justify-content-center mt-5">
          No Cancelled Booking
        </span>
      )}
    </>
  );
};

export default Canceled;
