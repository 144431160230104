import React, { useEffect, useState } from "react";
import { startApiCall } from "../../../Components/GlobalFunction/GlobalFunction";
import { useParams } from "react-router-dom";
import { FlightRelatedEndpoints } from "../../../api/Endpoints";
import DefaultLoader from "../../../Components/Loaders/DefaultLoader";
import ApiCall from "../../../api/ApiCall";
import parse from 'html-react-parser';

const SingleBlog = () => {
  const { id } = useParams();
  const [blogDetails, setBlogDetails] = useState({});
  const [loader, setLoader] = useState(false);

  const handleGetBlogDetails = async () => {
    startApiCall(null, setLoader);
    const data = {
      blogId: id,
    };
    const res = await ApiCall(
      "post",
      FlightRelatedEndpoints.getBlogDetails,
      data
    );
    if (res) {
      setBlogDetails(res);
      setLoader(false);
    } else {
      setLoader(false);
    }
  };

  useEffect(() => {
    handleGetBlogDetails();
  }, []);

  return (
    <>
      {!loader && (
        <div className="bg-theme-secondary w-100 overflow-hidden">
          <div
            className="single-blog-header row"
            style={{
              backgroundImage:`url(${blogDetails?.image})`
            }}
          ></div>
          <div className="custom-container">
            <div className="single-blog-article col-lg-8 offset-lg-2">
              <h1 className="single-blog-title">{blogDetails?.name}</h1>
              <p>{blogDetails?.short_description}</p>
              <p className="text-center">
                <img
                  src={blogDetails?.image}
                  alt=""
                  className="single-blog-image img-fluid rounded-10p"
                  loading="lazy"
                />
              </p>
              {/* <p>{parse(blogDetails?.description)}</p> */}
              {blogDetails.description && (
                <p>{parse(blogDetails.description)}</p>
              )}
            </div>
          </div>
        </div>
      )}
      {loader && <DefaultLoader />}
    </>
  );
};

export default SingleBlog;
