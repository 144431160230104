import React, { useEffect, useState } from "react";
import { startApiCall } from "../../Components/GlobalFunction/GlobalFunction";
import OtherApiCall from "../../api/OtherApiCall";
import { CardRelatedEndpoints } from "../../api/Endpoints";
import InputMask from "react-input-mask";
import { toast } from "react-toastify";

const AddCardModal = ({
  login_data,
  handleGetAllCards,
  editCard,
  setEditCard,
}) => {
  const [loader, setloader] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [cardName, setCardName] = useState("Visa");
  const [cardNo, setCardNo] = useState("");
  const [expDate, setExpDate] = useState("");
  const [cvvNo, setCvvNo] = useState("");
  const [name, setName] = useState("");

  useEffect(() => {
    if (editCard) {
      setCardName(editCard?.card_name);
      setCardNo(editCard?.card_no);
      setCvvNo(editCard?.card_cvv);
      setName(editCard?.name_on_card);
      setExpDate(editCard?.card_expiry_date);
    }
  }, [editCard]);

  const handleReset = () => {
    setloader(false);
    setErrorMessage("");
    setCardName("Visa");
    setName("");
    setExpDate("");
    setCardNo("");
    setCvvNo("");
    setEditCard(null);
    const closeModalDom = document.getElementById("add_new_card");
    if (closeModalDom) closeModalDom.click();
  };

  const handleAddCard = async () => {
    startApiCall(setErrorMessage, setloader);
    const data = {
      user_id: login_data?.user?._id,
      card_name: cardName,
      card_no: cardNo,
      card_expiry_date: expDate,
      card_cvv: cvvNo,
      name_on_card: name,
    };
    const res = await OtherApiCall("post", CardRelatedEndpoints.addCard, data);
    if (res?.success) {
      toast.success("Add User card successfully!");
      handleReset();
      handleGetAllCards();
      setloader(false);
    } else {
      setloader(false);
      setErrorMessage(res.error);
    }
  };

  const handleEditCard = async () => {
    startApiCall(setErrorMessage, setloader);
    const data = {
      cardId: editCard?._id,
      user_id: login_data?.user?._id,
      card_name: cardName,
      card_no: cardNo,
      card_expiry_date: expDate,
      card_cvv: cvvNo,
      name_on_card: name,
    };
    const res = await OtherApiCall("post", CardRelatedEndpoints.editCard, data);
    if (res?.success) {
      toast.success("Edit User card successfully!");
      handleReset();
      handleGetAllCards();
      setloader(false);
    } else {
      setloader(false);
      setErrorMessage(res.error);
    }
  };

  return (
    <div
      class="modal fade"
      id="add_new_card"
      tabindex="-1"
      aria-labelledby="add_new_cardLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-header border-0 pb-0">
            <h5 class="modal-title text-uppercase text-primary-dark-theme">
              {editCard === null ? "Add" : "Edit"} User Card
            </h5>
            <span
              class="btn-close pointer"
              data-bs-dismiss="modal"
              aria-label="Close"
              onClick={handleReset}
            ></span>
          </div>
          <div class="modal-body">
            <div
              className="custom-debit-card-card"
              style={{ maxWidth: "100%" }}
            >
              <div className="custom-debit-card-front p-3 custom-card-shadow">
                <div className="d-flex align-items-center justify-content-between">
                  <div className="col-6 card_type">
                    <div class="form-group">
                      <select
                        class="form-control"
                        id="card_type"
                        value={cardName}
                        onChange={(e) => setCardName(e.target.value)}
                      >
                        <option value="Visa" className="text-dark">Visa</option>
                        <option value="Master Card" className="text-dark">Master Card</option>
                        <option value="Ruppey" className="text-dark">Ruppey</option>
                        <option value="Other" className="text-dark">Other</option>
                      </select>
                    </div>
                  </div>
                  <div className="col-6 card_cvv">
                    <h5>
                      <InputMask
                        className="p-inputtext p-component"
                        mask="999"
                        placeholder="CVV"
                        value={cvvNo}
                        slotChar="CVV"
                        onChange={(e) => setCvvNo(e.target.value)}
                      ></InputMask>
                    </h5>
                  </div>
                </div>
                <div className="card_no">
                  <h5>
                    <InputMask
                      className="p-inputtext p-component"
                      mask="9999-9999-9999-9999"
                      placeholder="XXXX-XXXX-XXXX-XXXX"
                      slotChar="XXXX/XXXX/XXXX/XXXX"
                      value={cardNo}
                      onChange={(e) => setCardNo(e.target.value)}
                    ></InputMask>
                  </h5>
                </div>
                <div className="row">
                  <div className="col-6 card_holder">
                    <p style={{marginBottom: "0.3rem"}}>CARD HOLDER</p>
                    <h5>
                      <input
                        className="p-inputtext p-component"
                        type="text"
                        placeholder="Enter Your Name"
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                      />
                    </h5>
                  </div>
                  <div className="col-6 card_expiry">
                    <p>Expires</p>
                    <h5>
                      <InputMask
                        className="p-inputtext p-component"
                        mask="99/99"
                        placeholder="MM/YY"
                        value={expDate}
                        slotChar="MM/YY"
                        onChange={(e) => setExpDate(e.target.value)}
                      ></InputMask>
                    </h5>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {errorMessage && (
            <div className="d-flex justify-content-center text-danger mb-2">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                fill="currentColor"
                className="bi bi-exclamation-circle mt-1"
                viewBox="0 0 16 16"
              >
                <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14m0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16" />
                <path d="M7.002 11a1 1 0 1 1 2 0 1 1 0 0 1-2 0M7.1 4.995a.905.905 0 1 1 1.8 0l-.35 3.507a.552.552 0 0 1-1.1 0z" />
              </svg>
              &nbsp;
              {errorMessage}
            </div>
          )}
          <div className="modal-footer border-0 pt-0">
            <button
              className="btn theme-btn-primary btn-rounded w-100"
              onClick={() => {
                if (editCard === null) {
                  handleAddCard();
                } else {
                  handleEditCard();
                }
              }}
            >
              {loader ? "Loading....." : editCard === null ? "Add Card" : "Edit Card"}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddCardModal;
