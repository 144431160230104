import React from "react";

const FlightFilterTable = ({
  priceWithAirlines,
  flightAirlineCodes,
  directStopWithAirlinePrice,
  onePlusWithAirlinePrice,
  selectedMaxPrice,
  handleChangePrice,
  handleCheckBoxChange,
}) => {
  return (
    <div className="card rounded-10p bg-white custom-card-shadow d-none-md overflow-hidden">
      <div className="d-flex">
        <div className="table-w-200p overflow-auto">
          <table className="table text-nowrap table-striped mb-0 text-center table-w-200p border-end">
            <thead>
              <tr>
                <th
                  className="text-primary-dark-theme"
                  style={{ paddingBlock: "11px" }}
                >
                  Show All Fares
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                  <div
                    className="text-primary-theme pointer"
                    onClick={() => handleCheckBoxChange("0")}
                  >
                    Direct
                  </div>
                </td>
              </tr>
              <tr>
                <td className="border-bottom-0">
                  <div
                    className="text-primary-theme pointer"
                    onClick={() => handleCheckBoxChange("1")}
                  >
                    1 Stop
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div className="w-100 overflow-auto">
          <table className="table text-nowrap table-striped mb-0 text-center pb-1">
            <thead>
              <tr>
                {priceWithAirlines?.map((flight, index) => (
                  <th
                    className="table-w-200p border-end text-primary-dark-theme"
                    key={index}
                  >
                    <div
                      className="d-flex gap-2 align-items-center justify-content-center"
                      style={{
                        maxWidth: "265px",
                        minWidth: "160px",
                      }}
                    >
                      {flight?.image && (
                        <img
                          style={{
                            width: "40px",
                            height: "30px",
                            objectFit: "contain",
                            objectPosition: "center",
                          }}
                          src={flight?.image}
                          alt=""
                        />
                      )}
                      <span style={{ fontWeight: "400" }}>
                        {flight?.airline_name}
                      </span>
                    </div>
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              <tr>
                {flightAirlineCodes?.map((airlineCode, index) => {
                  const flight = directStopWithAirlinePrice?.find(
                    (flight) => flight.airlineCode === airlineCode
                  );
                  return (
                    <td className="table-w-200p border-end" key={index}>
                      <div
                        className={
                          selectedMaxPrice === flight?.price
                            ? "active_table_td"
                            : ""
                        }
                        style={{
                          maxWidth: "265px",
                          minWidth: "150px",
                          cursor: "pointer",
                        }}
                        data-value={flight ? flight?.price : 0}
                        // onClick={this.handleChangePrice}
                        onClick={flight ? handleChangePrice : null}
                      >
                        {flight
                          ? `$ ${flight.price?.toFixed(2)}`
                          : "-----"}
                      </div>
                    </td>
                  );
                })}
              </tr>
              <tr>
                {flightAirlineCodes.map((airlineCode, index) => {
                  const flight = onePlusWithAirlinePrice?.find(
                    (flight) => flight.airlineCode === airlineCode
                  );
                  return (
                    <td className="table-w-200p border-end" key={index}>
                      <div
                        className={
                          selectedMaxPrice === flight?.price
                            ? "active_table_td"
                            : ""
                        }
                        style={{
                          maxWidth: "265px",
                          minWidth: "150px",
                          cursor: "pointer",
                        }}
                        data-value={flight ? flight.price : 0}
                        onClick={flight ? handleChangePrice : null}
                      >
                        {flight
                          ? `$ ${flight.price?.toFixed(2)}`
                          : "-----"}
                      </div>
                    </td>
                  );
                })}
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default FlightFilterTable;
