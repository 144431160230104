import React, { useState } from "react";

const PassengersModal = ({
  adults,
  children,
  infants,
  onChange,
  travelClass,
  selectedTravelClass,
  setState
}) => {

  const handleClose = () => {
    const closeModalDom = document.getElementById("add_passengers_modal");
    if (closeModalDom) closeModalDom.click();
  };

  return (
    <div
      className="modal fade"
      id="add_passengers_modal"
      tabIndex="-1"
      aria-hidden="true"
    >
      <div
        className="modal-dialog modal-dialog-centered"
        style={{ maxWidth: "650px" }}
      >
        <div className="modal-content rounded-10p overflow-hidden">
          <div>
            <div className="modal-header bg-white">
              <h5 className="modal-title fw-bold text-primary-theme">
                Passengers
              </h5>
              <span
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
                onClick={handleClose}
              ></span>
            </div>
            <div className="modal-body d-flex flex-column gap-3">
              <div className="d-flex justify-content-between gap-3">
                <div>
                  <p className="mb-0">Adults</p>
                  <p className="mb-0 fs-sm">12+ years</p>
                </div>
                <div className="d-flex align-items-center gap-2">
                  <button
                    className="btn btn-icon theme-btn-outline-primary border"
                    // onClick={() => onChange("adult", adults - 1)}
                    onClick={() => {
                      setState((prevState) => {
                        const newAdults = prevState.adult - 1;
                        return {
                          ...prevState,
                          adult: newAdults,
                          infants:
                            newAdults < prevState.infants
                              ? newAdults
                              : prevState.infants,
                        };
                      });
                    }}
                    disabled={adults === 1}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="currentColor"
                      className="bi bi-dash-lg"
                      viewBox="0 0 16 16"
                    >
                      <path
                        fillRule="evenodd"
                        d="M2 8a.5.5 0 0 1 .5-.5h11a.5.5 0 0 1 0 1h-11A.5.5 0 0 1 2 8"
                      />
                    </svg>
                  </button>
                  <span>{adults}</span>
                  <button
                    className="btn btn-icon theme-btn-outline-primary border"
                    onClick={() => onChange("adult", adults + 1)}
                    disabled={parseInt(adults) + parseInt(children) === 9}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="currentColor"
                      className="bi bi-plus-lg"
                      viewBox="0 0 16 16"
                    >
                      <path
                        fillRule="evenodd"
                        d="M8 2a.5.5 0 0 1 .5.5v5h5a.5.5 0 0 1 0 1h-5v5a.5.5 0 0 1-1 0v-5h-5a.5.5 0 0 1 0-1h5v-5A.5.5 0 0 1 8 2"
                      />
                    </svg>
                  </button>
                </div>
              </div>
              <div className="d-flex justify-content-between gap-3">
                <div>
                  <p className="mb-0">Child</p>
                  <p className="mb-0 fs-sm">2-11 years</p>
                </div>
                <div className="d-flex align-items-center gap-2">
                  <button
                    className="btn btn-icon theme-btn-outline-primary border"
                    onClick={() => onChange("child", children - 1)}
                    disabled={children === 0}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="currentColor"
                      className="bi bi-dash-lg"
                      viewBox="0 0 16 16"
                    >
                      <path
                        fillRule="evenodd"
                        d="M2 8a.5.5 0 0 1 .5-.5h11a.5.5 0 0 1 0 1h-11A.5.5 0 0 1 2 8"
                      />
                    </svg>
                  </button>
                  <span>{children}</span>
                  <button
                    className="btn btn-icon theme-btn-outline-primary border"
                    // onClick={() => handleChildrenChange(1)}
                    onClick={() => onChange("child", children + 1)}
                    disabled={parseInt(adults) + parseInt(children) === 9}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="currentColor"
                      className="bi bi-plus-lg"
                      viewBox="0 0 16 16"
                    >
                      <path
                        fillRule="evenodd"
                        d="M8 2a.5.5 0 0 1 .5.5v5h5a.5.5 0 0 1 0 1h-5v5a.5.5 0 0 1-1 0v-5h-5a.5.5 0 0 1 0-1h5v-5A.5.5 0 0 1 8 2"
                      />
                    </svg>
                  </button>
                </div>
              </div>
              <div className="d-flex justify-content-between gap-3">
                <div>
                  <p className="mb-0">Infant</p>
                  <p className="mb-0 fs-sm">Under 2 years</p>
                </div>
                <div className="d-flex align-items-center gap-2">
                  <button
                    className="btn btn-icon theme-btn-outline-primary border"
                    onClick={() => onChange("infants", infants - 1)}
                    disabled={infants === 0}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="currentColor"
                      className="bi bi-dash-lg"
                      viewBox="0 0 16 16"
                    >
                      <path
                        fillRule="evenodd"
                        d="M2 8a.5.5 0 0 1 .5-.5h11a.5.5 0 0 1 0 1h-11A.5.5 0 0 1 2 8"
                      />
                    </svg>
                  </button>
                  <span>{infants}</span>
                  <button
                    className="btn btn-icon theme-btn-outline-primary border"
                    onClick={() => onChange("infants", infants + 1)}
                    disabled={infants === adults}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="currentColor"
                      className="bi bi-plus-lg"
                      viewBox="0 0 16 16"
                    >
                      <path
                        fillRule="evenodd"
                        d="M8 2a.5.5 0 0 1 .5.5v5h5a.5.5 0 0 1 0 1h-5v5a.5.5 0 0 1-1 0v-5h-5a.5.5 0 0 1 0-1h5v-5A.5.5 0 0 1 8 2"
                      />
                    </svg>
                  </button>
                </div>
              </div>
            </div>
            <div className="modal-header bg-white pt-0">
              <h5 className="modal-title fw-bold text-primary-theme">Class</h5>
            </div>
            <div className="modal-body d-flex flex-column gap-3">
              <div className="row g-3">
                {travelClass?.map((item) => (
                  <div className="col-md-3 col-sm-6">
                    <span
                      className={`btn ${
                        item.value === selectedTravelClass
                          ? "theme-btn-primary"
                          : "theme-btn-outline-primary"
                      } rounded-10p w-100 btn-sm`}
                      onClick={() => onChange("t_class_selected", item.value)}
                    >
                      {item?.name}
                    </span>
                  </div>
                ))}
              </div>
            </div>
            <div className="modal-footer border-0 pt-0">
              <button
                type="submit"
                className="btn theme-btn-primary btn-rounded"
                onClick={handleClose}
              >
                Confirm
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PassengersModal;
