import React from "react";
import plannerImg from "../../Assets/Images/plannerImg.png";

const ProfessionalPlanners = () => {
  return (
    <div className="custom-container d-flex flex-column gap-3 py-4 bg-theme-secondary">
      <h4 className="mb-0 text-primary-dark-theme">
        We are professional
        <br />
        Planners for your&nbsp;
        <span className="h3 fw-bold text-primary-theme">Travel</span>
      </h4>
      <div className="row align-items-center col-reverse-sm">
        <div className="col-md-6">
          <div className="d-flex flex-column gap-3">
            <div className="d-flex align-items-center gap-3">
              <span className="p-2 bg-white rounded-circle">
                <svg
                  width="38"
                  height="38"
                  viewBox="0 0 56 56"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M42 44.3333L45.7147 40.6187C47.8235 38.5096 49.0081 35.6492 49.0081 32.6667C49.0081 29.6842 47.8235 26.8238 45.7147 24.7147L35 14M16.3384 23.3333H16.3151M7 18.6667V28.4013C7.00026 29.6389 7.4921 30.8257 8.36733 31.7007L21.6907 45.024C22.7452 46.0784 24.1754 46.6707 25.6667 46.6707C27.1579 46.6707 28.5881 46.0784 29.6427 45.024L38.024 36.6427C39.0784 35.5881 39.6707 34.1579 39.6707 32.6667C39.6707 31.1754 39.0784 29.7452 38.024 28.6907L24.7007 15.3673C23.8257 14.4921 22.6389 14.0003 21.4013 14H11.6667C10.429 14 9.242 14.4917 8.36683 15.3668C7.49167 16.242 7 17.429 7 18.6667Z"
                    stroke="#32CD32"
                    stroke-width="4"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </span>
              <div>
                <p className="mb-0 fw-bold">Competitive Pricing</p>
                <p className="mb-0">
                  With 500+ suppliers and the purchasing power of 300 million
                  members
                </p>
              </div>
            </div>
            <div className="d-flex align-items-center gap-3">
              <span className="p-2 bg-white rounded-circle">
                <svg
                  width="38"
                  height="38"
                  viewBox="0 0 56 56"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M28 35C24.287 35 20.726 33.525 18.1005 30.8995C15.475 28.274 14 24.713 14 21C14 17.287 15.475 13.726 18.1005 11.1005C20.726 8.475 24.287 7 28 7C31.713 7 35.274 8.475 37.8995 11.1005C40.525 13.726 42 17.287 42 21C42 24.713 40.525 28.274 37.8995 30.8995C35.274 33.525 31.713 35 28 35ZM28 35L35.9333 48.7433L39.662 41.1997L48.0573 41.741L40.124 28M15.863 28L7.92969 41.7433L16.325 41.1997L20.0537 48.741L27.987 35"
                    stroke="#32CD32"
                    stroke-width="4"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </span>
              <div>
                <p className="mb-0 fw-bold">Award Winning Service</p>
                <p className="mb-0">
                  Fabulous Travel worry free knowing that we're here if you need
                  us, 24 hours a day
                </p>
              </div>
            </div>
            <div className="d-flex align-items-center gap-3">
              <span className="p-2 bg-white rounded-circle">
                <svg
                  width="38"
                  height="38"
                  viewBox="0 0 56 56"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M28 49C25.2422 49 22.5115 48.4568 19.9636 47.4015C17.4158 46.3461 15.1008 44.7993 13.1508 42.8492C11.2007 40.8992 9.65388 38.5842 8.59853 36.0364C7.54318 33.4885 7 30.7578 7 28C7 25.2422 7.54318 22.5115 8.59853 19.9636C9.65388 17.4158 11.2007 15.1008 13.1508 13.1508C15.1008 11.2007 17.4158 9.65388 19.9636 8.59853C22.5115 7.54318 25.2422 7 28 7M28 49C30.7578 49 33.4885 48.4568 36.0364 47.4015C38.5842 46.3461 40.8992 44.7993 42.8492 42.8492C44.7993 40.8992 46.3461 38.5842 47.4015 36.0364C48.4568 33.4885 49 30.7578 49 28C49 25.2422 48.4568 22.5115 47.4015 19.9636C46.3461 17.4158 44.7993 15.1008 42.8492 13.1508C40.8992 11.2007 38.5842 9.65388 36.0364 8.59853C33.4885 7.54318 30.7578 7 28 7M28 49V7M26.839 7C23.5576 9.43051 20.8915 12.5961 19.0544 16.243C17.2173 19.8899 16.2604 23.9165 16.2604 28C16.2604 32.0835 17.2173 36.1101 19.0544 39.757C20.8915 43.4039 23.5576 46.5695 26.839 49M29.1667 7C32.448 9.43051 35.1141 12.5961 36.9512 16.243C38.7883 19.8899 39.7452 23.9165 39.7452 28C39.7452 32.0835 38.7883 36.1101 36.9512 39.757C35.1141 43.4039 32.448 46.5695 29.1667 49"
                    stroke="#32CD32"
                    stroke-width="4"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </span>
              <div>
                <p className="mb-0 fw-bold">Worldwide Coverage</p>
                <p className="mb-0">
                  1,200,000 hotels in more than 200 countries and regions &
                  flights to over 5,000 cities
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="col-md-6">
          <div className="d-flex align-items-center justify-content-center p-3">
            <img
              loading="lazy"
              className="img-fluid"
              src={plannerImg}
              style={{ maxWidth: "24rem" }}
              alt=""
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProfessionalPlanners;
