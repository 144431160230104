import React from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

const ConfirmModal = () => {
  const navigate = useNavigate();

  const handleLogout = () => {
    toast.success("Logout successfully!")
    sessionStorage.removeItem("login");
    navigate("/");
    const closeModalDom = document.getElementById("confirm_modal");
    if (closeModalDom) closeModalDom.click();
  };

  return (
    <div class="modal fade" id="confirm_modal" tabindex="-1" aria-hidden="true">
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-body">
            <p class="h4 text-center text-primary-dark-theme">
              Are You Sure to Logout?
            </p>
          </div>
          <div class="modal-footer border-0 pt-0 justify-content-center">
            <button
              type="submit"
              class="btn theme-btn-primary px-4"
              // data-bs-dismiss="modal"
              onClick={handleLogout}
            >
              Yes
            </button>
            <button
              type="submit"
              class="btn theme-btn-primary px-4"
              data-bs-dismiss="modal"
            >
              No
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ConfirmModal;
