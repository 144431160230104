import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { FlightRelatedEndpoints } from "../../api/Endpoints";
import DefaultLoader from "../../Components/Loaders/DefaultLoader";
import { startApiCall } from "../../Components/GlobalFunction/GlobalFunction";
import ApiCall from "../../api/ApiCall";

const Blogs = () => {
  const navigate = useNavigate();
  const [bloglist, setBloglist] = useState([]);
  const [loader, setLoader] = useState(false);

  const [visibleBlogs, setVisibleBlogs] = useState(4);
  const handleLoadMore = () => {
    setVisibleBlogs((prevVisibleBlogs) => prevVisibleBlogs + 4);
  };

  const truncateText = (text, wordLimit) => {
    const words = text.split(" ");
    return words.length > wordLimit
      ? words.slice(0, wordLimit).join(" ") + "..."
      : text;
  };

  const handleGetAllBlogs = async () => {
    startApiCall(null, setLoader);
    const data = {
      filters: {},
    };
    const res = await ApiCall("post", FlightRelatedEndpoints.getBlogs, data);
    if (res) {
      setBloglist(res);
      setLoader(false);
    } else {
      setLoader(false);
    }
  };
  useEffect(() => {
    handleGetAllBlogs();
  }, []);

  return (
    <>
      {!loader && (
        <div className="bg-theme-secondary custom-container py-3">
          <div className="container">
            <div className="row g-3">
              <div className="col-md-9">
                <div className="d-flex flex-column gap-3">
                  {bloglist.slice(0, visibleBlogs)?.map((item, index) => (
                    <div
                      className="card rounded-10p custom-card-shadow overflow-hidden"
                      key={index}
                    >
                      <img
                        src={item.image}
                        className="card-img-top"
                        alt="..."
                        loading="lazy"
                        style={{ maxHeight: "24rem" }}
                      />
                      <div className="card-body">
                        <div className="d-flex justify-content-between gap-3">
                          <h5 className="card-title text-decoration-underline text-primary-dark-theme">
                            {item.name}
                          </h5>
                          <span
                            className="btn btn-icon theme-btn-outline-primary border"
                            onClick={() => navigate(`/singleblog/${item?._id}`)}
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="16"
                              height="16"
                              fill="currentColor"
                              className="bi bi-chevron-double-right"
                              viewBox="0 0 16 16"
                            >
                              <path
                                fillRule="evenodd"
                                d="M3.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L9.293 8 3.646 2.354a.5.5 0 0 1 0-.708"
                              />
                              <path
                                fillRule="evenodd"
                                d="M7.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L13.293 8 7.646 2.354a.5.5 0 0 1 0-.708"
                              />
                            </svg>
                          </span>
                        </div>
                        <p className="card-text">
                          {truncateText(item.short_description, 20)}
                        </p>
                      </div>
                    </div>
                  ))}
                  {visibleBlogs < bloglist?.length && (
                    <button
                      className="btn theme-btn-outline-primary border"
                      onClick={handleLoadMore}
                    >
                      Load More
                    </button>
                  )}
                  {bloglist?.length === 0 && (
                    <p className="text-center text-secondary h2 my-5">
                      No Blogs Available
                    </p>
                  )}
                </div>
              </div>
              <div className="col-md-3">
                <div className="d-flex flex-column gap-3">
                  {/* <div className="card rounded-10p custom-card-shadow px-2 py-1">
                  <div className="d-flex align-items-center justify-content-center gap-3 text-primary-dark-theme">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="40"
                      height="40"
                      fill="currentColor"
                      class="bi bi-telephone-fill"
                      viewBox="0 0 16 16"
                    >
                      <path
                        fill-rule="evenodd"
                        d="M1.885.511a1.745 1.745 0 0 1 2.61.163L6.29 2.98c.329.423.445.974.315 1.494l-.547 2.19a.68.68 0 0 0 .178.643l2.457 2.457a.68.68 0 0 0 .644.178l2.189-.547a1.75 1.75 0 0 1 1.494.315l2.306 1.794c.829.645.905 1.87.163 2.611l-1.034 1.034c-.74.74-1.846 1.065-2.877.702a18.6 18.6 0 0 1-7.01-4.42 18.6 18.6 0 0 1-4.42-7.009c-.362-1.03-.037-2.137.703-2.877z"
                      ></path>
                    </svg>
                    <div>
                      <p className="mb-0 h-5 fw-bold">+1 888-315-1615 (US)</p>
                      <p className="mb-0">Call us now to get best offers!</p>
                    </div>
                  </div>
                </div> */}
                  <div className="card rounded-10p custom-card-shadow overflow-hidden">
                    <img
                      src="https://cdn.pixabay.com/photo/2021/01/07/11/00/cabin-crew-5896864_1280.png"
                      className="card-img-top"
                      alt="..."
                      loading="lazy"
                      style={{ maxHeight: "24rem" }}
                    />
                    <div className="card-body">
                      <h5 class="card-title text-primary-dark-theme">
                        About Us
                      </h5>
                      <p class="card-text">
                        {truncateText(
                          "Welcome to Nilhans, your ultimate destination for seamless flight bookings across the United States. At Ogule, we're passionate about transforming your travel dreams into reality. Your hassle-free travel experience begins here, at Ogule",
                          20
                        )}
                        <Link className="text-decoration-none" to="/about">
                          Read more
                        </Link>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      {loader && <DefaultLoader />}
    </>
  );
};

export default Blogs;
