import React from "react";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import StarRating from "../StarRating/StarRating";

const CardCarousel = ({ cardData }) => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 840,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 576,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          dots: false,
        },
      },
    ],
  };

  return (
    <div className="custom-container">
      <Slider {...settings}>
        {cardData.map((card, index) => (
          <div
            key={index}
            className="d-flex align-items-center justify-content-center px-3"
          >
            <div
              className="card rounded-10p py-3 w-100"
              style={{ maxWidth: "18rem" }}
            >
              <div className="card-body">
                <p className="card-text mb-0">{card.text}</p>
                <div className="d-flex align-items-end justify-content-end gap-2">
                  <StarRating initialRating={card.rating} />
                  <p className="mb-2">{card.rating}</p>
                </div>
                <p className="mb-0">-{card.author}</p>
              </div>
            </div>
          </div>
        ))}
      </Slider>
    </div>
  );
};

export default CardCarousel;
