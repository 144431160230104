import React, { useState } from "react";
import OTPBox from "./OTPBox";
import { toast } from "react-toastify";
import ApiCall from "../../../api/ApiCall";
import { startApiCall } from "../../GlobalFunction/GlobalFunction";
import { forgotPasswordEndpoints } from "../../../api/Endpoints";

const ForgotPassword = ({ setForgotPassword, firstStep, setfirstStep }) => {
  const [secondStep, setSecondStep] = useState(false);
  const [thirdStep, setThirdStep] = useState(false);
  const [otpString, setOtpString] = useState("");
  const [otp, setOtp] = useState(new Array(6).fill(""));
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");

  const [isShowPassword, setIsShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [loader, setLoader] = useState(false);

  const handleReset = () => {
    setLoader(false);
    setErrorMessage("");
    setEmail("");
    setOtpString("");
    setOtp("");
    setPassword("");
    setConfirmPassword("");
    setThirdStep(false);
    setForgotPassword(false);
  };

  const completeFirstStep = () => {
    setfirstStep(false);
    setSecondStep(true);
    setThirdStep(false);
  };

  const completeSecondStep = () => {
    setfirstStep(false);
    setSecondStep(false);
    setThirdStep(true);
  };

  // Verify Email
  const handleEmailVerify = async (e) => {
    e.preventDefault();
    startApiCall(setErrorMessage, setLoader);
    const res = await ApiCall(
      "post",
      forgotPasswordEndpoints.sendOtpForResetPassword,
      { email }
    );
    if (res?.success) {
      toast.success("Otp sent successfully");
      setOtp(new Array(6).fill(""));
      setOtpString("");
      setLoader(false);
      completeFirstStep();
    } else {
      setLoader(false);
      setErrorMessage(res.error);
    }
  };

  // OTP Verify
  const handleOtpVerify = async (e) => {
    e.preventDefault();
    startApiCall(setErrorMessage, setLoader);
    const res = await ApiCall(
      "post",
      forgotPasswordEndpoints.validateOtpForResetPassword,
      {
        otp: otpString,
      }
    );
    if (res?.success) {
      toast.success("Otp verified successfully");
      setLoader(false);
      completeSecondStep();
    } else {
      setLoader(false);
      setErrorMessage(res.error);
    }
  };

  // Password Change
  const handleResetPassword = async (e) => {
    e.preventDefault();
    startApiCall(setErrorMessage, setLoader);
    if (password !== confirmPassword) {
      setLoader(false);
      setErrorMessage("Password and confirm password must be same.");
    } else {
      startApiCall(setErrorMessage, setLoader);
      const sendData = {
        password: password,
        otp: otpString,
      };
      const res = await ApiCall(
        "post",
        forgotPasswordEndpoints.resetPassword,
        sendData
      );
      if (res?.success) {
        handleReset();
        toast.success("Password change successfully");
      } else {
        setLoader(false);
        setErrorMessage(res.error);
      }
    }
  };

  return (
    <div
      className="custom-modal"
      tabIndex="-1"
      role="dialog"
      style={{ display: "block" }}
    >
      <div className="modal-dialog modal-dialog-centered">
        <div className="modal-content rounded-40p">
          <div className="modal-body">
            <div>
              <div className="d-flex justify-content-end me-3">
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                  onClick={() => {
                    setForgotPassword(false);
                  }}
                ></button>
              </div>
              <p className="text-center fw-400">Forgot Password</p>
              <div className="border-doubled-theme"></div>
              <form
                onSubmit={(e) => {
                  if (firstStep) {
                    handleEmailVerify(e);
                  } else if (secondStep) {
                    handleOtpVerify(e);
                  } else if (thirdStep) {
                    handleResetPassword(e);
                  }
                }}
              >
                <div className="row gx-3 px-2 mb-3">
                  {firstStep && (
                    <div className="col-sm-12 mt-3">
                      <label
                        className="text-primary-dark-theme mb-2"
                        htmlFor="user_email"
                      >
                        Email Address
                      </label>
                      <input
                        id="user_email"
                        type="email"
                        className="form-control border-0 border-bottom mb-3"
                        placeholder="Enter Email"
                        required
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                      />
                    </div>
                  )}
                  {secondStep && (
                    <OTPBox
                      otp={otp}
                      otpString={otpString}
                      errorMessage={errorMessage}
                      loader={loader}
                      handleOtpVerify={"handleOtpVerify"}
                      setOtpString={setOtpString}
                      setOtp={setOtp}
                      description="Please enter the verification code we sent to your email address"
                      email={email}
                    />
                  )}
                  {thirdStep && (
                    <>
                      <div className="col-sm-12 mt-3 mb-2">
                        <label
                          className="text-primary-dark-theme mb-2"
                          htmlFor="user_password"
                        >
                          Password
                        </label>
                        <div className="input-group">
                          <input
                            id="user_password"
                            type={isShowPassword ? "text" : "password"}
                            className="form-control border-0 border-bottom"
                            placeholder="Enter Password"
                            required
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                          />
                          <div className="input-group-append">
                            <button
                              className="btn theme-btn-outline-primary rounded-left-0"
                              type="button"
                              onClick={() => setIsShowPassword(!isShowPassword)}
                            >
                              {isShowPassword ? (
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="16"
                                  height="16"
                                  fill="currentColor"
                                  className="bi bi-eye-slash-fill"
                                  viewBox="0 0 16 16"
                                >
                                  <path d="m10.79 12.912-1.614-1.615a3.5 3.5 0 0 1-4.474-4.474l-2.06-2.06C.938 6.278 0 8 0 8s3 5.5 8 5.5a7 7 0 0 0 2.79-.588M5.21 3.088A7 7 0 0 1 8 2.5c5 0 8 5.5 8 5.5s-.939 1.721-2.641 3.238l-2.062-2.062a3.5 3.5 0 0 0-4.474-4.474z" />
                                  <path d="M5.525 7.646a2.5 2.5 0 0 0 2.829 2.829zm4.95.708-2.829-2.83a2.5 2.5 0 0 1 2.829 2.829zm3.171 6-12-12 .708-.708 12 12z" />
                                </svg>
                              ) : (
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="16"
                                  height="16"
                                  fill="currentColor"
                                  className="bi bi-eye-fill"
                                  viewBox="0 0 16 16"
                                >
                                  <path d="M10.5 8a2.5 2.5 0 1 1-5 0 2.5 2.5 0 0 1 5 0" />
                                  <path d="M0 8s3-5.5 8-5.5S16 8 16 8s-3 5.5-8 5.5S0 8 0 8m8 3.5a3.5 3.5 0 1 0 0-7 3.5 3.5 0 0 0 0 7" />
                                </svg>
                              )}
                            </button>
                          </div>
                        </div>
                      </div>
                      <div className="col-sm-12 mt-3 mb-2">
                        <label>Confirm Password</label>
                        <div className="input-group">
                          <input
                            id="user_cpassword"
                            type={showConfirmPassword ? "text" : "password"}
                            className="form-control border-0 border-bottom"
                            placeholder="Enter Confirm Password"
                            required
                            value={confirmPassword}
                            onChange={(e) => setConfirmPassword(e.target.value)}
                          />
                          <div className="input-group-append">
                            <button
                              className="btn theme-btn-outline-primary rounded-left-0"
                              type="button"
                              onClick={() =>
                                setShowConfirmPassword(!showConfirmPassword)
                              }
                            >
                              {showConfirmPassword ? (
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="16"
                                  height="16"
                                  fill="currentColor"
                                  className="bi bi-eye-slash-fill"
                                  viewBox="0 0 16 16"
                                >
                                  <path d="m10.79 12.912-1.614-1.615a3.5 3.5 0 0 1-4.474-4.474l-2.06-2.06C.938 6.278 0 8 0 8s3 5.5 8 5.5a7 7 0 0 0 2.79-.588M5.21 3.088A7 7 0 0 1 8 2.5c5 0 8 5.5 8 5.5s-.939 1.721-2.641 3.238l-2.062-2.062a3.5 3.5 0 0 0-4.474-4.474z" />
                                  <path d="M5.525 7.646a2.5 2.5 0 0 0 2.829 2.829zm4.95.708-2.829-2.83a2.5 2.5 0 0 1 2.829 2.829zm3.171 6-12-12 .708-.708 12 12z" />
                                </svg>
                              ) : (
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="16"
                                  height="16"
                                  fill="currentColor"
                                  className="bi bi-eye-fill"
                                  viewBox="0 0 16 16"
                                >
                                  <path d="M10.5 8a2.5 2.5 0 1 1-5 0 2.5 2.5 0 0 1 5 0" />
                                  <path d="M0 8s3-5.5 8-5.5S16 8 16 8s-3 5.5-8 5.5S0 8 0 8m8 3.5a3.5 3.5 0 1 0 0-7 3.5 3.5 0 0 0 0 7" />
                                </svg>
                              )}
                            </button>
                          </div>
                        </div>
                      </div>
                    </>
                  )}
                </div>
                {errorMessage && (
                  <div className="d-flex justify-content-center text-danger mb-2">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="currentColor"
                      className="bi bi-exclamation-circle mt-1"
                      viewBox="0 0 16 16"
                    >
                      <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14m0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16" />
                      <path d="M7.002 11a1 1 0 1 1 2 0 1 1 0 0 1-2 0M7.1 4.995a.905.905 0 1 1 1.8 0l-.35 3.507a.552.552 0 0 1-1.1 0z" />
                    </svg>
                    &nbsp;
                    {errorMessage}
                  </div>
                )}
                <button
                  type="submit"
                  className="btn btn-rounded custom-btn w-100 fw-bold"
                >
                  {loader
                    ? "Loading......."
                    : firstStep
                    ? "Verify Email"
                    : secondStep
                    ? "Verify OTP"
                    : "Reset Password"}
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ForgotPassword;
