import React, { useEffect, useState } from "react";
import { startApiCall } from "../../Components/GlobalFunction/GlobalFunction";
import OtherApiCall from "../../api/OtherApiCall";
import { UserRelatedEndpoints } from "../../api/Endpoints";
import Skeleton from "@mui/material/Skeleton";

const WalletTab = ({ login_data }) => {
  const [loader, setloader] = useState(false);
  const [cardDetails, setCardDetails] = useState([]);

  const handleCardDetails = async () => {
    startApiCall(null, setloader);
    const data = {
      userId: login_data.user._id,
    };
    const res = await OtherApiCall(
      "post",
      UserRelatedEndpoints.getCardDetails,
      data
    );
    if (res?.success) {
      setCardDetails(res?.result);
      setloader(false);
    } else {
      setloader(false);
    }
  };

  useEffect(() => {
    handleCardDetails();
  }, []);

  return (
    <div className="card rounded-10p custom-card-shadow">
      <div className="card-body d-flex flex-column gap-5">
        <p className="h4 text-uppercase">
          Nilhans&nbsp;
          <span className="text-primary-theme">Wallet</span>
        </p>
        {loader && (
          <div className="d-flex justify-content-between">
            <Skeleton
              variant="text"
              sx={{ fontSize: "1.5rem" }}
              height={40}
              width={300}
            />
            <Skeleton
              variant="text"
              sx={{ fontSize: "1.5rem" }}
              height={40}
              width={200}
            />
          </div>
        )}
        {!loader && (
          <div className="d-flex justify-content-between">
            <p className="mb-0">
              Account Holder :&nbsp;
              <span className="fw-bold">{cardDetails?.card?.userId?.name}</span>
            </p>
            <p className="mb-0 d-flex align-items-center">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                fill="#FFC207"
                class="bi bi-star-fill"
                viewBox="0 0 16 16"
              >
                <path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z"></path>
              </svg>
              &nbsp;Points :&nbsp;
              <span className="fw-bold">{cardDetails?.card?.points}</span>
            </p>
          </div>
        )}
      </div>
    </div>
  );
};

export default WalletTab;
