import React from "react";

const About = () => {
  return (
    <div className="bg-theme-secondary custom-container py-3">
      <div className="container d-flex flex-column gap-3">
        <div className="card rounded-10p custom-card-shadow">
          <div className="card-body">
            <p className="card-title text-primary-dark-theme h2 border-doubled-theme mb-3">
              About Us
            </p>
            <div id="our_aim" className="mb-4">
              <p className="mb-1 fw-bold text-uppercase">Our Aim</p>
              <p className="mb-0">
                To give trouvaille travel experience to every customers and
                target to receive numinous response.
              </p>
            </div>
            <div id="our_vision" className="mb-4">
              <p className="mb-1 fw-bold text-uppercase">Our Vision</p>
              <p className="mb-0">
                Travelling can be complex. Deciding and budget planning for the
                next perfect destination can be difficult at times and perhaps
                for thesame reason Nilhans aims at providing holistic guidance
                to customers travel itineraries.
              </p>
            </div>
            <div id="our_story" className="mb-4">
              <p className="mb-1 fw-bold text-uppercase">Our Story</p>
              <p className="mb-0">
                Planning a vacation, going on a holiday is best memories for
                families together. Starting with where to GO where to stay, what
                to eat and most important of all how to TRAVEL. This is where
                Nilhans comes as ansolution to all, with one targeted aim to
                provide holistic travel itinerary.We started with three
                international travel representatives and now we are a team of
                50± employees who are dedicated to work together and towards one
                goal of providing better customer experience.
                <p className="mb-0">
                  <span className="fw-bold">
                    Company Address&nbsp;
                  </span>
                  :- Three Sugar Creek Center Blvd Sugar Land, TX 77479, USA
                </p>
                <p className="mb-0">
                  <span className="fw-bold">
                    USA Email&nbsp;
                  </span>
                  :- Info@Nilhans.com
                </p>
                <p className="mb-0">
                  <span className="fw-bold">Ph&nbsp;</span>
                  :- +1 888-315-1615
                </p>
              </p>
            </div>
            <div id="man_behind_nilhans" className="mb-4">
              <p className="mb-1 fw-bold text-uppercase">Man Behind Nilhans</p>
              <p className="mb-0">
                During his childhood he was aspired with travelling as everyone
                us are at that age and for obvious reasons…I mean who won’t like
                going new places, meeting new people and utilizing time away
                from school as most of us would like to think& believe back
                then..butto notice challenges starting from planning where to
                go, budget planning and its importance, meeting deadlines and
                having a problem solving outlook for the same and holding a firm
                belief that it can be changed for better and being INSPIRED
                towards making air bus travel an option for almost everyone is
                not very common. With back benching schooling and with pragmatic
                questioning towards conventional teaching techniques Nilhans was
                making.
              </p>
            </div>
            <p>
              Our entire operations is handled by BEST IN FLIGHTS which is
              registered in INDIA. We are happy that we got this opportunity at
              the time of need. We now manage a 50+ team of travel
              representatives operating from INDIA and also aim at providing
              every alternate information our customers need. We are happy and
              more so blessed to have repeated customers like YOU. The trust and
              confidence we’ve been getting from the verybeginningtill now is
              motivating enough to keep the work moving forward.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default About;
