import React, { useEffect } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Home from "./Pages/Home/Home";
import Navbar from "./Components/Header/Navbar";
import Footer from "./Components/Footer/Footer";
import Deals from "./Pages/Deals/Deals";
import Blogs from "./Pages/Blogs/Blogs";
import About from "./Pages/About/About";
import Flights from "./Pages/Flights/Flights";
import Checkout from "./Pages/Checkout/Checkout";
import BookSuccessfully from "./Pages/BookSuccessfully/BookSuccessfully";
import SingleBlog from "./Pages/Blogs/SingleBlog/SingleBlog";
import PrivacyPolicy from "./Pages/PrivacyPolicy/PrivacyPolicy";
import TermsAndConditions from "./Pages/TermsAndConditions/TermsAndConditions";
import Dashboard from "./Pages/Profile/Dashboard";
import { ToastContainer } from "react-toastify";

const App = () => {
  useEffect(() => {
    const fetchIP = async () => {
      try {
        const response = await fetch('https://api.ipify.org?format=json');
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        const data = await response.json();
        const fetchedIp = data.ip;
        sessionStorage.setItem('ipAddress', fetchedIp);
      } catch (error) {
        console.error('Error fetching the public IP address:', error);
      }
    };

    fetchIP();
  }, []);

  return (
    <div>
      <Router>
        <Navbar />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/flights" element={<Flights />} />
          <Route path="/checkout" element={<Checkout />} />
          <Route path="/booksuccessfully" element={<BookSuccessfully />} />
          <Route path="/deals" element={<Deals />} />
          <Route path="/blogs" element={<Blogs />} />
          <Route path="/singleblog/:id" element={<SingleBlog />} />
          <Route path="/about" element={<About />} />
          <Route path="/privacypolicy" element={<PrivacyPolicy />} />
          <Route path="/termsandconditions" element={<TermsAndConditions />} />
          <Route path="/dashboard" element={<Dashboard />} />
        </Routes>
        <Footer />
      </Router>
      <ToastContainer
        position="top-right"
        theme="colored"
        autoClose={1500}
        hideProgressBar={true}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        limit={1}
      />
    </div>
  );
};

export default App;
