import React from "react";
import Tooltip from "react-tooltip-lite";

const PriceDetailsCard = ({
  bookingDetails,
  wayOfFlight,
  firstStep,
  isRefundable,
  isAddPack,
  totalPassengers,
  totalPrice
}) => {
  return (
    <div className="card rounded-10p bg-white mb-3 mt-2">
      <div className="card-body">
        <div className="d-flex flex-column gap-2">
          <p className="mb-0 h5 d-flex align-items-center">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="20"
              height="20"
              fill="currentColor"
              className="bi bi-currency-dollar text-primary-theme"
              viewBox="0 0 16 16"
            >
              <path d="M4 10.781c.148 1.667 1.513 2.85 3.591 3.003V15h1.043v-1.216c2.27-.179 3.678-1.438 3.678-3.3 0-1.59-.947-2.51-2.956-3.028l-.722-.187V3.467c1.122.11 1.879.714 2.07 1.616h1.47c-.166-1.6-1.54-2.748-3.54-2.875V1H7.591v1.233c-1.939.23-3.27 1.472-3.27 3.156 0 1.454.966 2.483 2.661 2.917l.61.162v4.031c-1.149-.17-1.94-.8-2.131-1.718zm3.391-3.836c-1.043-.263-1.6-.825-1.6-1.616 0-.944.704-1.641 1.8-1.828v3.495l-.2-.05zm1.591 1.872c1.287.323 1.852.859 1.852 1.769 0 1.097-.826 1.828-2.2 1.939V8.73z" />
            </svg>
            &nbsp;Price Details (USD)
          </p>
          <table className="table border-0 mb-0">
            <thead>
              <tr>
                <th className="border-0">Travellers</th>
                <th className="border-0">Price per person</th>
                <th className="border-0">Subtotal</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td className="border-0">
                  <p className="mb-0 d-flex align-items-center">
                    Adult :&nbsp;{sessionStorage.getItem("adults")}&nbsp;
                    <span className="pointer">
                      <Tooltip content="Above 12 years">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="12"
                          height="12"
                          fill="currentColor"
                          className="bi bi-info-circle mb-1 text-info"
                          viewBox="0 0 16 16"
                        >
                          <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14m0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16" />
                          <path d="m8.93 6.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533zM9 4.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0" />
                        </svg>
                      </Tooltip>
                    </span>
                  </p>
                </td>
                <td className="border-0">
                  <p className="mb-0">
                    $&nbsp;
                    {wayOfFlight === "round_trip"
                      ? parseFloat(bookingDetails?.adult).toFixed(2)
                      : bookingDetails?.flights?.[0]?.propFlightGrDetail?.[0]
                          ?.recommendation?.paxFareProduct?.[0]
                          ?.paxFareDetail?.[0]?.totalFareAmount?.[0]}
                    &nbsp; x {sessionStorage.getItem("adults")}
                  </p>
                </td>
                <td className="border-0 text-nowrap">
                  ${" "}
                  {wayOfFlight === "round_trip"
                    ? sessionStorage.getItem("adults") *
                      parseFloat(bookingDetails?.adult).toFixed(2)
                    : sessionStorage.getItem("adults") *
                      parseFloat(
                        bookingDetails?.flights?.[0]?.propFlightGrDetail?.[0]
                          ?.recommendation?.paxFareProduct?.[0]
                          ?.paxFareDetail?.[0]?.totalFareAmount?.[0]
                      )?.toFixed(2)}
                </td>
              </tr>
              {sessionStorage.getItem("children") !== "0" && (
                <tr>
                  <td className="border-0">
                    <p className="mb-0 d-flex align-items-center">
                      Child :&nbsp;{sessionStorage.getItem("children")}&nbsp;
                      <span className="pointer">
                        <Tooltip content="2 years to 12 years">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="12"
                            height="12"
                            fill="currentColor"
                            className="bi bi-info-circle mb-1 text-info"
                            viewBox="0 0 16 16"
                          >
                            <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14m0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16" />
                            <path d="m8.93 6.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533zM9 4.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0" />
                          </svg>
                        </Tooltip>
                      </span>
                    </p>
                  </td>
                  <td className="border-0">
                    <p className="mb-0">
                      $&nbsp;
                      {wayOfFlight === "round_trip"
                        ? parseFloat(bookingDetails?.child).toFixed(2)
                        : bookingDetails?.flights?.[0]?.propFlightGrDetail?.[0]
                            ?.recommendation?.paxFareProduct?.[1]
                            ?.paxFareDetail?.[0]?.totalFareAmount?.[0]}
                      &nbsp; x {sessionStorage.getItem("children")}
                    </p>
                  </td>
                  <td className="border-0 text-nowrap">
                    ${" "}
                    {wayOfFlight === "round_trip"
                      ? sessionStorage.getItem("children") *
                        parseFloat(bookingDetails?.child).toFixed(2)
                      : sessionStorage.getItem("children") *
                        parseFloat(
                          bookingDetails?.flights?.[0]?.propFlightGrDetail?.[0]
                            ?.recommendation?.paxFareProduct?.[1]
                            ?.paxFareDetail?.[0]?.totalFareAmount?.[0]
                        )?.toFixed(2)}
                  </td>
                </tr>
              )}
              {sessionStorage.getItem("infants") !== "0" && (
                <tr>
                  <td className="border-0">
                    <p className="mb-0 d-flex align-items-center">
                      Infant :&nbsp;{sessionStorage.getItem("infants")}&nbsp;
                      <span className="pointer">
                        <Tooltip content="Below 2 years">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="12"
                            height="12"
                            fill="currentColor"
                            className="bi bi-info-circle mb-1 text-info"
                            viewBox="0 0 16 16"
                          >
                            <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14m0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16" />
                            <path d="m8.93 6.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533zM9 4.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0" />
                          </svg>
                        </Tooltip>
                      </span>
                    </p>
                  </td>
                  <td className="border-0 text-nowrap">$ 0.00</td>
                  <td className="border-0 text-nowrap">$ 0.00</td>
                </tr>
              )}
              {isRefundable === "true" && !firstStep && (
                <tr>
                  <td className="text-left d-flex text-nowrap border-0 fs-14p">
                    Refundable Booking
                  </td>
                  <td className="border-0 text-nowrap">
                    ${" "}
                    {(bookingDetails?.price / totalPassengers / 6)?.toFixed(2)}{" "}
                    X {totalPassengers}
                  </td>
                  <td className="border-0 text-nowrap">
                    ${" "}
                    {parseFloat(
                      bookingDetails?.price / totalPassengers / 6
                    )?.toFixed(2) * parseFloat(totalPassengers)}
                  </td>
                </tr>
              )}
              {isAddPack && !firstStep && (
                <tr>
                  <td className="text-left d-flex text-nowrap border-0 fs-14p">
                    Support Package
                  </td>
                  <td className="border-0 text-nowrap">$ 19.89</td>
                  <td className="border-0 text-nowrap">$ 19.89</td>
                </tr>
              )}
            </tbody>
          </table>
          {bookingDetails?.markUp !== null && (
            <div className="row px-3 justify-content-end mb-2">
              <span
                className="fs-15p"
                style={{
                  fontWeight: 500,
                  color: "#000000",
                  textTransform: "capitalize",
                }}
              >
                {bookingDetails?.markUp?.markup_type} (
                {bookingDetails?.markUp?.markup_amount_type === "percentage"
                  ? "%"
                  : "-"}
                ) : {parseFloat(bookingDetails?.markUp?.markup_amount)}
              </span>
            </div>
          )}
          <div className="d-flex align-items-center justify-content-between gap-3 text-nowrap">
            <p className="mb-0 h5 text-primary-dark-theme">Total Price (USD)</p>
            <p className="mb-0 h5 text-primary-dark-theme">
              $&nbsp;{totalPrice}
            </p>
          </div>

          <div className="border-dashed w-75 mx-auto my-2"></div>
          <p className="mb-0 h5 d-flex align-items-center">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="20"
              height="20"
              fill="currentColor"
              className="bi bi-luggage text-primary-theme"
              viewBox="0 0 16 16"
            >
              <path d="M2.5 1a.5.5 0 0 0-.5.5V5h-.5A1.5 1.5 0 0 0 0 6.5v7a1.5 1.5 0 0 0 1 1.415v.335a.75.75 0 0 0 1.5 0V15H4v-1H1.5a.5.5 0 0 1-.5-.5v-7a.5.5 0 0 1 .5-.5h5a.5.5 0 0 1 .5.5V7h1v-.5A1.5 1.5 0 0 0 6.5 5H6V1.5a.5.5 0 0 0-.5-.5zM5 5H3V2h2z" />
              <path d="M3 7.5a.5.5 0 0 0-1 0v5a.5.5 0 0 0 1 0zM11 6a1.5 1.5 0 0 1 1.5 1.5V8h2A1.5 1.5 0 0 1 16 9.5v5a1.5 1.5 0 0 1-1.5 1.5h-8A1.5 1.5 0 0 1 5 14.5v-5A1.5 1.5 0 0 1 6.5 8h2v-.5A1.5 1.5 0 0 1 10 6zM9.5 7.5V8h2v-.5A.5.5 0 0 0 11 7h-1a.5.5 0 0 0-.5.5M6 9.5v5a.5.5 0 0 0 .5.5H7V9h-.5a.5.5 0 0 0-.5.5m7 5.5V9H8v6zm1.5 0a.5.5 0 0 0 .5-.5v-5a.5.5 0 0 0-.5-.5H14v6z" />
            </svg>
            &nbsp;Luggage Details
          </p>
          <div>
            <p className="mb-0 fw-bold text-primary-dark-theme">
              Personal items
            </p>
            <p className="mb-0 d-flex align-items-center gap-1">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                fill="currentColor"
                className="bi bi-backpack2-fill"
                viewBox="0 0 16 16"
              >
                <path d="M5 13h6v-3h-1v.5a.5.5 0 0 1-1 0V10H5z" />
                <path d="M6 2v.341C3.67 3.165 2 5.388 2 8v1.191l-1.17.585A1.5 1.5 0 0 0 0 11.118V13.5A1.5 1.5 0 0 0 1.5 15h1c.456.607 1.182 1 2 1h7c.818 0 1.544-.393 2-1h1a1.5 1.5 0 0 0 1.5-1.5v-2.382a1.5 1.5 0 0 0-.83-1.342L14 9.191V8a6 6 0 0 0-4-5.659V2a2 2 0 1 0-4 0m2-1a1 1 0 0 1 1 1v.083a6 6 0 0 0-2 0V2a1 1 0 0 1 1-1m0 3a4 4 0 0 1 3.96 3.43.5.5 0 1 1-.99.14 3 3 0 0 0-5.94 0 .5.5 0 1 1-.99-.14A4 4 0 0 1 8 4M4.5 9h7a.5.5 0 0 1 .5.5v4a.5.5 0 0 1-.5.5h-7a.5.5 0 0 1-.5-.5v-4a.5.5 0 0 1 .5-.5" />
              </svg>
              1 x 5kg included for all passengers
            </p>
          </div>
          <div>
            <p className="mb-0 fw-bold text-primary-dark-theme">
              Carry on baggage
            </p>
            <p className="mb-0 d-flex align-items-center gap-1">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                fill="currentColor"
                className="bi bi-suitcase-lg-fill"
                viewBox="0 0 16 16"
              >
                <path d="M7 0a2 2 0 0 0-2 2H1.5A1.5 1.5 0 0 0 0 3.5v9A1.5 1.5 0 0 0 1.5 14H2a.5.5 0 0 0 1 0h10a.5.5 0 0 0 1 0h.5a1.5 1.5 0 0 0 1.5-1.5v-9A1.5 1.5 0 0 0 14.5 2H11a2 2 0 0 0-2-2zM6 2a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1zM3 13V3h1v10zm9 0V3h1v10z" />
              </svg>
              0 - Available at additional cost
            </p>
          </div>
          <div>
            <p className="mb-0 fw-bold text-primary-dark-theme">
              Checked in baggage
            </p>
            <p className="mb-0 d-flex align-items-center gap-1">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                fill="currentColor"
                className="bi bi-suitcase2-fill"
                viewBox="0 0 16 16"
              >
                <path d="M6.5 0a.5.5 0 0 0-.5.5V3H4.5A1.5 1.5 0 0 0 3 4.5v9a1.5 1.5 0 0 0 1.003 1.416A1 1 0 1 0 6 15h4a1 1 0 1 0 1.996-.084A1.5 1.5 0 0 0 13 13.5v-9A1.5 1.5 0 0 0 11.5 3H10V.5a.5.5 0 0 0-.5-.5zM9 3H7V1h2zM4 7V6h8v1z" />
              </svg>
              0 - Available at additional cost
            </p>
          </div>
          <p className="fs-sm text-primary-dark-theme mb-0">
            * Additional baggage fees may apply.
          </p>
        </div>
      </div>
    </div>
  );
};

export default PriceDetailsCard;
