import React, { useEffect, useRef, useState } from "react";
import PassengersModal from "../../Components/PassengersModal/PassengersModal";
import FlightsRootModal from "./FlightsRootModal";
import RoundTripTicket from "./RoundTripTicket";
import OneWayTicket from "./OneWayTicket";
import dayjs from "dayjs";
import { FlightRelatedEndpoints } from "../../api/Endpoints";
import ApiCall from "../../api/ApiCall";
import {
  flightTotalDuration,
  parseTimeValue,
  startApiCall,
  updateArray,
} from "../../Components/GlobalFunction/GlobalFunction";
import FlightsPageLoader from "../../Components/Loaders/FlightsPageLoader";
import FilterSideBar from "./FilterSideBar";
import FilterSideBarSmallScreen from "./FilterSideBarSmallScreen";
import FlightFilterCard from "./FlightFilterCard";
import { useNavigate } from "react-router-dom";
import FlightFilterTable from "./FlightFilterTable";

const Flights = () => {
  const login_data = JSON.parse(sessionStorage.getItem("login"));
  const navigate = useNavigate();
  const [apiToken, setApiToken] = useState("");
  const [loader, setloader] = useState(false);
  const [state, setState] = useState({
    setShow: false,
    iatacode_from: sessionStorage.getItem("iatacode_from") || "BHM",
    airport_name_from:
      sessionStorage.getItem("airport_name_from") || "SHUTTLESWORTH INTL",
    country_from: sessionStorage.getItem("country_from") || "US",
    city_from: sessionStorage.getItem("city_from") || "BIRMINGHAM",
    iatacode_to: sessionStorage.getItem("iatacode_to") || "DEN",
    airport_name_to:
      sessionStorage.getItem("airport_name_to") || "DENVER INTERNATIONAL",
    country_to: sessionStorage.getItem("country_to") || "US",
    city_to: sessionStorage.getItem("city_to") || "DENVER",
    departuredate: dayjs(sessionStorage.getItem("departureDate") || new Date()),
    returndate: dayjs(
      sessionStorage.getItem("returnDate") || dayjs().add(1, "day")
    ),
    adult: sessionStorage.getItem("adults")
      ? parseInt(sessionStorage.getItem("adults"))
      : 1,
    child: sessionStorage.getItem("children")
      ? parseInt(sessionStorage.getItem("children"))
      : 0,
    infants: sessionStorage.getItem("infants")
      ? parseInt(sessionStorage.getItem("infants"))
      : 0,
    totalCounts:
      sessionStorage.getItem("adults") && sessionStorage.getItem("children")
        ? parseInt(sessionStorage.getItem("adults")) +
          parseInt(sessionStorage.getItem("children"))
        : 0,
    t_class: [
      { name: "Economy", value: "ECONOMY" },
      { name: "Premium Economy", value: "PREMIUM_ECONOMY" },
      { name: "Business", value: "BUSINESS" },
      { name: "First", value: "FIRST" },
    ],
    t_class_checked: 0,
    t_class_selected: sessionStorage.getItem("travelClass") || "ECONOMY",
    trip: sessionStorage.getItem("wayOfFlight") || "round_trip",
    currencyCode: "USD",
    selectedCurrency: "USD",
    selectedAirline: "",
    isDirect: false,
    showFormModal: false,
    showTimeOutModal: false,
    timer: null,
  });
  const [visibleTickets, setVisibleTickets] = useState(15);
  const [filteredFlights, setFilteredFlights] = useState([]);
  const [flightsData, setFlightsData] = useState([]);

  // Price filter
  const [minPrice, setMinPrice] = useState(0);
  const [maxPrice, setMaxPrice] = useState(0);
  const [selectedMaxPrice, setselectedMaxPrice] = useState(null);
  const [filterApplied, setFilterApplied] = useState(false);
  const [filterType, setFilterType] = useState(null);

  // Time filter
  const [deptValue, setDeptValue] = useState([0, 0]);
  const [arrivValue, setArrivValue] = useState([0, 0]);
  const [departureData, setdepartureData] = useState([]);
  const [arrivalData, setarrivalData] = useState([]);

  // For airlines
  const [selectedAirlines, setSelectedAirlines] = useState([]);
  const [priceWithAirlines, setpriceWithAirlines] = useState([]);
  const [selectAllFlights, setSelectAllFlights] = useState(false);

  // For stops
  const [updateStopCount, setUpdateStopCount] = useState([]);
  const [totalStopCount, settotalStopCount] = useState([]);
  const stops = ["0", "1", "2+"];

  //cheapest,best,fastest
  const [cheapestFlight, setcheapestFlight] = useState([]);
  const [bestFlight, setbestFlight] = useState([]);
  const [fastestFlight, setfastestFlight] = useState([]);

  //show airlines in table
  const [flightAirlineCodes, setflightAirlineCodes] = useState([]);
  const [directStopWithAirlinePrice, setdirectStopWithAirlinePrice] = useState(
    []
  );
  const [onePlusWithAirlinePrice, setonePlusWithAirlinePrice] = useState([]);

  const handleLoadMore = () => {
    setVisibleTickets((prevVisibleTickets) => prevVisibleTickets + 20);
  };

  // fetching token
  const fetchToken = async () => {
    try {
      const response = await fetch(
        "https://test.api.amadeus.com/v1/security/oauth2/token",
        {
          method: "POST",
          headers: new Headers({
            "Content-Type": "application/x-www-form-urlencoded", // <-- Specifying the Content-Type
          }),
          body: "grant_type=client_credentials&client_id=lFD9B6KFg1j3kMKGLzFgadbAKFEzOwVw&client_secret=XmvZgKrjBJAiAMfE", // <-- Post parameters
        }
      );

      const res = await response.json();
      if (res.access_token) {
        setApiToken(res.access_token);
      } else {
        alert(JSON.stringify(res));
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    fetchToken();
  }, []);

  // change input field
  const handleChange = (field, value) => {
    if (field === "departuredate") {
      const newDepartureDate = dayjs(value);
      const newReturnDate = newDepartureDate.add(1, "day");
      setState((prevState) => ({
        ...prevState,
        departuredate: newDepartureDate,
        returndate: newReturnDate,
      }));
    } else {
      setState((prevState) => ({
        ...prevState,
        [field]: value,
      }));
    }
  };

  useEffect(() => {
    sessionStorage.setItem(
      "departureDate",
      state.departuredate.format("YYYY-MM-DD")
    );
    sessionStorage.setItem("returnDate", state.returndate.format("YYYY-MM-DD"));
  }, [state.departuredate, state.returndate]);

  const [isActive, setIsActive] = useState("Cheapest");
  const fromInputRef = useRef(null);
  const toInputRef = useRef(null);
  const handleToggle = () => {
    const fromValue = fromInputRef.current.value;
    const toValue = toInputRef.current.value;

    fromInputRef.current.value = toValue;
    toInputRef.current.value = fromValue;
  };

  useEffect(() => {
    setState((prevState) => ({
      ...prevState,
      totalCounts:
        parseInt(state.adult) +
        parseInt(state.child),
    }));
  }, [state.adult, state.child]);

  console.log("totalCounts",state.totalCounts);

  // Get flights
  const handleGetFlights = async () => {
    startApiCall(null, setloader);
    const data = {
      ip_address: sessionStorage.getItem("ipAddress"),
      user_id: login_data ? login_data.user._id : "",
      origin: sessionStorage.getItem("originLocationCode"),
      destination: sessionStorage.getItem("destinationLocationCode"),
      startingDate: dayjs(sessionStorage.getItem("departureDate")).format(
        "DDMMYY"
      ),
      returnDate: dayjs(sessionStorage.getItem("returnDate")).format("DDMMYY"),
      adult: parseInt(sessionStorage.getItem("adults")),
      child: parseInt(sessionStorage.getItem("children")),
      infants: parseInt(sessionStorage.getItem("infants")),
      flightClass:
        sessionStorage.getItem("travelClass") === "BASIC ECONOMY"
          ? "ECONOMY"
          : sessionStorage.getItem("travelClass"),
      wayOfflight:
        sessionStorage.getItem("wayOfFlight") === "round_trip"
          ? "return"
          : "one",
      totalPassengers:
        sessionStorage.getItem("adults") && sessionStorage.getItem("children")
          ? parseInt(sessionStorage.getItem("adults")) +
            parseInt(sessionStorage.getItem("children"))
          : 0,
    };
    const res = await ApiCall("post", FlightRelatedEndpoints.getFlights, data);
    if (res) {
      if (sessionStorage.getItem("wayOfFlight") === "round_trip") {
        const departureFromDest = res.flightIndex?.[0].groupOfFlights
          .map(
            (flight) =>
              flight?.flightDetails?.[0]?.flightInformation?.[0]
                ?.productDateTime?.[0]?.timeOfDeparture?.[0]
          )
          .sort((a, b) => new Date(a) - new Date(b));
        const departureFromOrigiin = res.flightIndex?.[1]?.groupOfFlights
          .map(
            (flight) =>
              flight?.flightDetails?.[0]?.flightInformation?.[0]
                ?.productDateTime?.[0]?.timeOfDeparture?.[0]
          )
          .sort((a, b) => new Date(a) - new Date(b));
        const stopWisePrice = [];
        const flightWisePrice = res.combinationsArray
          ?.map((data) => {
            const flight1 =
              res.flightIndex?.[0]?.groupOfFlights[data?.outboundFlight];
            const flight2 =
              res.flightIndex?.[1]?.groupOfFlights[data?.inboundFlight];
            const f1Airline =
              flight1?.flightDetails?.[0]?.flightInformation?.[0]
                ?.companyId?.[0]?.customCarriers?.airline_name;
            const f2Airline =
              flight2?.flightDetails?.[0]?.flightInformation?.[0]
                ?.companyId?.[0]?.customCarriers?.airline_name;
            const stopsMinPrice = {};
            stopsMinPrice.airlineName =
              f1Airline === f2Airline ? f1Airline : "Mixed Airline";
            stopsMinPrice.layovers = data.layovers;
            stopsMinPrice.price = data.customPrice / state.totalCounts;
            stopsMinPrice.airlineCode =
              f1Airline === f2Airline
                ? data?.inboundFlightAirline
                : "MixedAirline123";
            updateArray(stopWisePrice, stopsMinPrice);
            const totalPrice =
              parseFloat(data?.customPrice) / state.totalCounts;
            const finalPrice = parseFloat(data?.customPrice);
            const adultPrice = parseFloat(data?.ADT);
            const childPrice = data?.CNN ? parseFloat(data?.CNN) : 0;
            const flight1Duration = flightTotalDuration(
              flight1?.flightDetails?.[0]?.flightInformation?.[0]
                ?.productDateTime?.[0]?.timeOfDeparture?.[0],
              flight1?.flightDetails?.[0]?.flightInformation?.[0]
                ?.productDateTime?.[0]?.dateOfDeparture?.[0],
              flight1?.flightDetails[flight1?.flightDetails?.length - 1]
                ?.flightInformation?.[0]?.productDateTime?.[0]
                ?.timeOfArrival?.[0],
              flight1?.flightDetails[flight1?.flightDetails?.length - 1]
                ?.flightInformation?.[0]?.productDateTime?.[0]
                ?.dateOfArrival?.[0]
            );
            const flight2Duration = flightTotalDuration(
              flight2?.flightDetails?.[0]?.flightInformation?.[0]
                ?.productDateTime?.[0]?.timeOfDeparture?.[0],
              flight2?.flightDetails?.[0]?.flightInformation?.[0]
                ?.productDateTime?.[0]?.dateOfDeparture?.[0],
              flight2?.flightDetails[flight2?.flightDetails?.length - 1]
                ?.flightInformation?.[0]?.productDateTime?.[0]
                ?.timeOfArrival?.[0],
              flight2?.flightDetails[flight2?.flightDetails?.length - 1]
                ?.flightInformation?.[0]?.productDateTime?.[0]
                ?.dateOfArrival?.[0]
            );
            const totalDuration = flight1Duration + flight2Duration;
            return {
              flight1,
              flight2,
              totalPrice,
              finalPrice,
              adultPrice,
              childPrice,
              markUp: data?.markUp,
              totalDuration,
            };
          })
          .sort((a, b) => a.totalPrice - b.totalPrice);
        const allAirlines = flightWisePrice?.map((data) => {
          const flight1 =
            data?.flight1?.flightDetails?.[0]?.flightInformation?.[0]
              ?.companyId?.[0]?.customCarriers?.airline_name;
          const flight2 =
            data?.flight2?.flightDetails?.[0]?.flightInformation?.[0]
              ?.companyId?.[0]?.customCarriers?.airline_name;
          const totalPrice = parseFloat(data?.totalPrice);
          const airlineCode =
            data?.flight1?.flightDetails?.[0]?.flightInformation?.[0]
              ?.companyId?.[0]?.customCarriers?.airline_code;
          const image =
            data?.flight1?.flightDetails?.[0]?.flightInformation?.[0]
              ?.companyId?.[0]?.customCarriers?.image;
          return {
            airline: flight1 === flight2 ? flight1 : "Mixed Airline",
            totalPrice,
            airline_code: flight1 === flight2 ? airlineCode : "MixedAirline123",
            image: flight1 === flight2 ? image : "",
          };
        });
        const airlineMinPrices = allAirlines?.reduce((acc, curr) => {
          const { airline, totalPrice, airline_code, image } = curr;
          if (!acc[airline] || totalPrice < acc[airline]) {
            acc[airline] = {
              totalPrice: totalPrice.toFixed(2),
              airline_code,
              image,
            };
          }
          return acc;
        }, {});
        const uniqueAirlines = Object.keys(airlineMinPrices).map((airline) => ({
          airline_name: airline,
          totalPrice: airlineMinPrices[airline].totalPrice,
          airline_code: airlineMinPrices[airline].airline_code,
          image: airlineMinPrices[airline].image,
        }));
        const cheapBestFastFlight = flightWisePrice?.map(
          ({ flight1, flight2, totalPrice }) => {
            const flight1Duration = flightTotalDuration(
              flight1?.flightDetails?.[0]?.flightInformation?.[0]
                ?.productDateTime?.[0]?.timeOfDeparture?.[0],
              flight1?.flightDetails?.[0]?.flightInformation?.[0]
                ?.productDateTime?.[0]?.dateOfDeparture?.[0],
              flight1?.flightDetails[flight1?.flightDetails?.length - 1]
                ?.flightInformation?.[0]?.productDateTime?.[0]
                ?.timeOfArrival?.[0],
              flight1?.flightDetails[flight1?.flightDetails?.length - 1]
                ?.flightInformation?.[0]?.productDateTime?.[0]
                ?.dateOfArrival?.[0]
            );
            const flight2Duration = flightTotalDuration(
              flight2?.flightDetails?.[0]?.flightInformation?.[0]
                ?.productDateTime?.[0]?.timeOfDeparture?.[0],
              flight2?.flightDetails?.[0]?.flightInformation?.[0]
                ?.productDateTime?.[0]?.dateOfDeparture?.[0],
              flight2?.flightDetails[flight2?.flightDetails?.length - 1]
                ?.flightInformation?.[0]?.productDateTime?.[0]
                ?.timeOfArrival?.[0],
              flight2?.flightDetails[flight2?.flightDetails?.length - 1]
                ?.flightInformation?.[0]?.productDateTime?.[0]
                ?.dateOfArrival?.[0]
            );
            const totalDuration = flight1Duration + flight2Duration;
            const priceWithduration = totalPrice * totalDuration;
            return {
              duration: totalDuration,
              price: totalPrice,
              priceWithduration,
            };
          }
        );
        const checkCheapestFlight = cheapBestFastFlight.reduce((min, pair) => {
          return pair.price < min.price ? pair : min;
        }, cheapBestFastFlight[0]);
        const checkFastestFlight = cheapBestFastFlight.reduce((min, pair) => {
          return pair.duration < min.duration ? pair : min;
        }, cheapBestFastFlight[0]);
        const checkBestFlight = cheapBestFastFlight.reduce((min, pair) => {
          return pair.priceWithduration < min.priceWithduration ? pair : min;
        }, cheapBestFastFlight[0]);
        setFilteredFlights(flightWisePrice);
        setFlightsData(flightWisePrice);
        setMinPrice(flightWisePrice?.[0]?.totalPrice);
        setMaxPrice(flightWisePrice[flightWisePrice?.length - 1]?.totalPrice);
        setselectedMaxPrice(
          flightWisePrice[flightWisePrice?.length - 1]?.totalPrice
        );
        setDeptValue([
          parseTimeValue(departureFromDest[0]),
          parseTimeValue(departureFromDest[departureFromDest.length - 1]),
        ]);
        setArrivValue([
          parseTimeValue(departureFromOrigiin[0]),
          parseTimeValue(departureFromOrigiin[departureFromOrigiin.length - 1]),
        ]);
        setdepartureData(departureFromDest);
        setarrivalData(departureFromOrigiin);
        settotalStopCount(res.totalStopCount);
        setUpdateStopCount(res.totalStopCount);
        setpriceWithAirlines(uniqueAirlines);
        setSelectedAirlines(res.selectedAirline);
        setcheapestFlight(checkCheapestFlight);
        setfastestFlight(checkFastestFlight);
        setbestFlight(checkBestFlight);
        setflightAirlineCodes(uniqueAirlines?.map((data) => data.airline_code));
        setdirectStopWithAirlinePrice(
          stopWisePrice?.filter((data) => data.layovers === 0)
        );
        setonePlusWithAirlinePrice(
          stopWisePrice?.filter((data) => data.layovers === 1)
        );
      } else {
        const departureFromDest = res.flightIndex?.[0].groupOfFlights
          .map(
            (flight) =>
              flight?.flightDetails?.[0]?.flightInformation?.[0]
                ?.productDateTime?.[0]?.timeOfDeparture?.[0]
          )
          .sort((a, b) => new Date(a) - new Date(b));
        const stopWisePrice = [];
        const flightWisePrice = res.combinationsArray
          ?.map((data) => {
            const flight1 =
              res.flightIndex?.[0]?.groupOfFlights[data?.outboundFlight];
            const f1Airline =
              flight1?.flightDetails?.[0]?.flightInformation?.[0]
                ?.companyId?.[0]?.customCarriers?.airline_name;
            const stopsMinPrice = {};
            stopsMinPrice.airlineName = f1Airline;
            stopsMinPrice.layovers = data.layovers;
            stopsMinPrice.price = data.customPrice / state.totalCounts;
            stopsMinPrice.airlineCode = data?.outboundFlightAirline;
            updateArray(stopWisePrice, stopsMinPrice);
            const totalPrice =
              parseFloat(data?.customPrice) / state.totalCounts;
            const finalPrice = parseFloat(data?.customPrice);
            const adultPrice = parseFloat(data?.ADT);
            const childPrice = data?.CNN ? parseFloat(data?.CNN) : 0;
            const flight1Duration = flightTotalDuration(
              flight1?.flightDetails?.[0]?.flightInformation?.[0]
                ?.productDateTime?.[0]?.timeOfDeparture?.[0],
              flight1?.flightDetails?.[0]?.flightInformation?.[0]
                ?.productDateTime?.[0]?.dateOfDeparture?.[0],
              flight1?.flightDetails[flight1?.flightDetails?.length - 1]
                ?.flightInformation?.[0]?.productDateTime?.[0]
                ?.timeOfArrival?.[0],
              flight1?.flightDetails[flight1?.flightDetails?.length - 1]
                ?.flightInformation?.[0]?.productDateTime?.[0]
                ?.dateOfArrival?.[0]
            );
            const totalDuration = flight1Duration;
            return {
              flight1,
              totalPrice,
              finalPrice,
              adultPrice,
              childPrice,
              markUp: data?.markUp,
              totalDuration,
            };
          })
          .sort((a, b) => a.totalPrice - b.totalPrice);
        const allAirlines = flightWisePrice?.map((data) => {
          const flight1 =
            data?.flight1?.flightDetails?.[0]?.flightInformation?.[0]
              ?.companyId?.[0]?.customCarriers?.airline_name;
          const totalPrice = parseFloat(data?.totalPrice);
          const airlineCode =
            data?.flight1?.flightDetails?.[0]?.flightInformation?.[0]
              ?.companyId?.[0]?.customCarriers?.airline_code;
          const image =
            data?.flight1?.flightDetails?.[0]?.flightInformation?.[0]
              ?.companyId?.[0]?.customCarriers?.image;

          return {
            airline: flight1,
            totalPrice,
            airline_code: airlineCode,
            image,
          };
        });
        const airlineMinPrices = allAirlines?.reduce((acc, curr) => {
          const { airline, totalPrice, airline_code, image } = curr;
          if (!acc[airline] || totalPrice < acc[airline]) {
            acc[airline] = {
              totalPrice: totalPrice.toFixed(2),
              airline_code,
              image,
            };
          }
          return acc;
        }, {});
        const uniqueAirlines = Object.keys(airlineMinPrices).map((airline) => ({
          airline_name: airline,
          totalPrice: airlineMinPrices[airline].totalPrice,
          airline_code: airlineMinPrices[airline].airline_code,
          image: airlineMinPrices[airline].image,
        }));
        const cheapBestFastFlight = flightWisePrice?.map(
          ({ flight1, totalPrice }) => {
            const flight1Duration = flightTotalDuration(
              flight1?.flightDetails?.[0]?.flightInformation?.[0]
                ?.productDateTime?.[0]?.timeOfDeparture?.[0],
              flight1?.flightDetails?.[0]?.flightInformation?.[0]
                ?.productDateTime?.[0]?.dateOfDeparture?.[0],
              flight1?.flightDetails[flight1?.flightDetails?.length - 1]
                ?.flightInformation?.[0]?.productDateTime?.[0]
                ?.timeOfArrival?.[0],
              flight1?.flightDetails[flight1?.flightDetails?.length - 1]
                ?.flightInformation?.[0]?.productDateTime?.[0]
                ?.dateOfArrival?.[0]
            );
            const totalDuration = flight1Duration;
            const priceWithduration = totalPrice * totalDuration;
            return {
              duration: totalDuration,
              price: totalPrice,
              priceWithduration,
            };
          }
        );
        const checkCheapestFlight = cheapBestFastFlight.reduce((min, pair) => {
          return pair.price < min.price ? pair : min;
        }, cheapBestFastFlight[0]);
        const checkFastestFlight = cheapBestFastFlight.reduce((min, pair) => {
          return pair.duration < min.duration ? pair : min;
        }, cheapBestFastFlight[0]);
        const checkBestFlight = cheapBestFastFlight.reduce((min, pair) => {
          return pair.priceWithduration < min.priceWithduration ? pair : min;
        }, cheapBestFastFlight[0]);
        setFilteredFlights(flightWisePrice);
        setFlightsData(flightWisePrice);
        setMinPrice(flightWisePrice?.[0]?.totalPrice);
        setMaxPrice(flightWisePrice[flightWisePrice?.length - 1]?.totalPrice);
        setselectedMaxPrice(
          flightWisePrice[flightWisePrice?.length - 1]?.totalPrice
        );
        setDeptValue([
          parseTimeValue(departureFromDest[0]),
          parseTimeValue(departureFromDest[departureFromDest.length - 1]),
        ]);
        setdepartureData(departureFromDest);
        settotalStopCount(res.totalStopCount);
        setUpdateStopCount(res.totalStopCount);
        setpriceWithAirlines(uniqueAirlines);
        setSelectedAirlines(res.selectedAirline);
        setcheapestFlight(checkCheapestFlight);
        setfastestFlight(checkFastestFlight);
        setbestFlight(checkBestFlight);
        setflightAirlineCodes(uniqueAirlines?.map((data) => data.airline_code));
        setdirectStopWithAirlinePrice(
          stopWisePrice.filter((data) => data.layovers === 0)
        );
        setonePlusWithAirlinePrice(
          stopWisePrice.filter((data) => data.layovers === 1)
        );
      }
      setloader(false);
    } else {
      setloader(false);
    }
  };

  useEffect(() => {
    handleGetFlights();
  }, []);

  // Get flights click by update button
  const handleUpdate = async () => {
    sessionStorage.setItem(
      "departureDate",
      state.departuredate.format("YYYY-MM-DD")
    );
    sessionStorage.setItem("returnDate", state.returndate.format("YYYY-MM-DD"));
    sessionStorage.setItem("originLocationCode", state.iatacode_from);
    sessionStorage.setItem("iatacode_from", state.iatacode_from);
    sessionStorage.setItem("airport_name_from", state.airport_name_from);
    sessionStorage.setItem("country_from", state.country_from);
    sessionStorage.setItem("city_from", state.city_from);
    sessionStorage.setItem("destinationLocationCode", state.iatacode_to);
    sessionStorage.setItem("iatacode_to", state.iatacode_to);
    sessionStorage.setItem("airport_name_to", state.airport_name_to);
    sessionStorage.setItem("country_to", state.country_to);
    sessionStorage.setItem("city_to", state.city_to);
    sessionStorage.setItem("adults", state.adult);
    sessionStorage.setItem("children", state.child);
    sessionStorage.setItem("infants", state.infants);
    sessionStorage.setItem("travelClass", state.t_class_selected);
    sessionStorage.setItem("wayOfFlight", state.trip);
    startApiCall(null, setloader);
    const data = {
      ip_address: sessionStorage.getItem("ipAddress"),
      user_id: login_data ? login_data.user._id : "",
      origin: state.iatacode_from,
      destination: state.iatacode_to,
      startingDate: dayjs(state.departuredate).format("DDMMYY"),
      returnDate: dayjs(state.returndate).format("DDMMYY"),
      adult: parseInt(state.adult),
      child: parseInt(state.child),
      infants: parseInt(state.infants),
      flightClass:
        state.t_class_selected === "BASIC ECONOMY"
          ? "ECONOMY"
          : state.t_class_selected,
      wayOfflight: state.trip === "round_trip" ? "return" : "one",
      totalPassengers: state.adult + state.child,
    };
    const res = await ApiCall("post", FlightRelatedEndpoints.getFlights, data);
    if (res) {
      if (sessionStorage.getItem("wayOfFlight") === "round_trip") {
        const departureFromDest = res.flightIndex?.[0].groupOfFlights
          .map(
            (flight) =>
              flight?.flightDetails?.[0]?.flightInformation?.[0]
                ?.productDateTime?.[0]?.timeOfDeparture?.[0]
          )
          .sort((a, b) => new Date(a) - new Date(b));
        const departureFromOrigiin = res.flightIndex?.[1]?.groupOfFlights
          .map(
            (flight) =>
              flight?.flightDetails?.[0]?.flightInformation?.[0]
                ?.productDateTime?.[0]?.timeOfDeparture?.[0]
          )
          .sort((a, b) => new Date(a) - new Date(b));
        const stopWisePrice = [];
        const flightWisePrice = res.combinationsArray
          ?.map((data) => {
            const flight1 =
              res.flightIndex?.[0]?.groupOfFlights[data?.outboundFlight];
            const flight2 =
              res.flightIndex?.[1]?.groupOfFlights[data?.inboundFlight];
            const f1Airline =
              flight1?.flightDetails?.[0]?.flightInformation?.[0]
                ?.companyId?.[0]?.customCarriers?.airline_name;
            const f2Airline =
              flight2?.flightDetails?.[0]?.flightInformation?.[0]
                ?.companyId?.[0]?.customCarriers?.airline_name;
            const stopsMinPrice = {};
            stopsMinPrice.airlineName =
              f1Airline === f2Airline ? f1Airline : "Mixed Airline";
            stopsMinPrice.layovers = data.layovers;
            stopsMinPrice.price = data.customPrice / state.totalCounts;
            stopsMinPrice.airlineCode =
              f1Airline === f2Airline
                ? data?.inboundFlightAirline
                : "MixedAirline123";
            updateArray(stopWisePrice, stopsMinPrice);
            const totalPrice =
              parseFloat(data?.customPrice) / state.totalCounts;
            const finalPrice = parseFloat(data?.customPrice);
            const adultPrice = parseFloat(data?.ADT);
            const childPrice = data?.CNN ? parseFloat(data?.CNN) : 0;
            const flight1Duration = flightTotalDuration(
              flight1?.flightDetails?.[0]?.flightInformation?.[0]
                ?.productDateTime?.[0]?.timeOfDeparture?.[0],
              flight1?.flightDetails?.[0]?.flightInformation?.[0]
                ?.productDateTime?.[0]?.dateOfDeparture?.[0],
              flight1?.flightDetails[flight1?.flightDetails?.length - 1]
                ?.flightInformation?.[0]?.productDateTime?.[0]
                ?.timeOfArrival?.[0],
              flight1?.flightDetails[flight1?.flightDetails?.length - 1]
                ?.flightInformation?.[0]?.productDateTime?.[0]
                ?.dateOfArrival?.[0]
            );
            const flight2Duration = flightTotalDuration(
              flight2?.flightDetails?.[0]?.flightInformation?.[0]
                ?.productDateTime?.[0]?.timeOfDeparture?.[0],
              flight2?.flightDetails?.[0]?.flightInformation?.[0]
                ?.productDateTime?.[0]?.dateOfDeparture?.[0],
              flight2?.flightDetails[flight2?.flightDetails?.length - 1]
                ?.flightInformation?.[0]?.productDateTime?.[0]
                ?.timeOfArrival?.[0],
              flight2?.flightDetails[flight2?.flightDetails?.length - 1]
                ?.flightInformation?.[0]?.productDateTime?.[0]
                ?.dateOfArrival?.[0]
            );
            const totalDuration = flight1Duration + flight2Duration;
            return {
              flight1,
              flight2,
              totalPrice,
              finalPrice,
              adultPrice,
              childPrice,
              markUp: data?.markUp,
              totalDuration,
            };
          })
          .sort((a, b) => a.totalPrice - b.totalPrice);
        const allAirlines = flightWisePrice?.map((data) => {
          const flight1 =
            data?.flight1?.flightDetails?.[0]?.flightInformation?.[0]
              ?.companyId?.[0]?.customCarriers?.airline_name;
          const flight2 =
            data?.flight2?.flightDetails?.[0]?.flightInformation?.[0]
              ?.companyId?.[0]?.customCarriers?.airline_name;
          const totalPrice = parseFloat(data?.totalPrice);
          const airlineCode =
            data?.flight1?.flightDetails?.[0]?.flightInformation?.[0]
              ?.companyId?.[0]?.customCarriers?.airline_code;
          const image =
            data?.flight1?.flightDetails?.[0]?.flightInformation?.[0]
              ?.companyId?.[0]?.customCarriers?.image;
          return {
            airline: flight1 === flight2 ? flight1 : "Mixed Airline",
            totalPrice,
            airline_code: flight1 === flight2 ? airlineCode : "MixedAirline123",
            image: flight1 === flight2 ? image : "",
          };
        });
        const airlineMinPrices = allAirlines?.reduce((acc, curr) => {
          const { airline, totalPrice, airline_code, image } = curr;
          if (!acc[airline] || totalPrice < acc[airline]) {
            acc[airline] = {
              totalPrice: totalPrice.toFixed(2),
              airline_code,
              image,
            };
          }
          return acc;
        }, {});
        const uniqueAirlines = Object.keys(airlineMinPrices).map((airline) => ({
          airline_name: airline,
          totalPrice: airlineMinPrices[airline].totalPrice,
          airline_code: airlineMinPrices[airline].airline_code,
          image: airlineMinPrices[airline].image,
        }));
        const cheapBestFastFlight = flightWisePrice?.map(
          ({ flight1, flight2, totalPrice }) => {
            const flight1Duration = flightTotalDuration(
              flight1?.flightDetails?.[0]?.flightInformation?.[0]
                ?.productDateTime?.[0]?.timeOfDeparture?.[0],
              flight1?.flightDetails?.[0]?.flightInformation?.[0]
                ?.productDateTime?.[0]?.dateOfDeparture?.[0],
              flight1?.flightDetails[flight1?.flightDetails?.length - 1]
                ?.flightInformation?.[0]?.productDateTime?.[0]
                ?.timeOfArrival?.[0],
              flight1?.flightDetails[flight1?.flightDetails?.length - 1]
                ?.flightInformation?.[0]?.productDateTime?.[0]
                ?.dateOfArrival?.[0]
            );
            const flight2Duration = flightTotalDuration(
              flight2?.flightDetails?.[0]?.flightInformation?.[0]
                ?.productDateTime?.[0]?.timeOfDeparture?.[0],
              flight2?.flightDetails?.[0]?.flightInformation?.[0]
                ?.productDateTime?.[0]?.dateOfDeparture?.[0],
              flight2?.flightDetails[flight2?.flightDetails?.length - 1]
                ?.flightInformation?.[0]?.productDateTime?.[0]
                ?.timeOfArrival?.[0],
              flight2?.flightDetails[flight2?.flightDetails?.length - 1]
                ?.flightInformation?.[0]?.productDateTime?.[0]
                ?.dateOfArrival?.[0]
            );
            const totalDuration = flight1Duration + flight2Duration;
            const priceWithduration = totalPrice * totalDuration;
            return {
              duration: totalDuration,
              price: totalPrice,
              priceWithduration,
            };
          }
        );
        const checkCheapestFlight = cheapBestFastFlight.reduce((min, pair) => {
          return pair.price < min.price ? pair : min;
        }, cheapBestFastFlight[0]);
        const checkFastestFlight = cheapBestFastFlight.reduce((min, pair) => {
          return pair.duration < min.duration ? pair : min;
        }, cheapBestFastFlight[0]);
        const checkBestFlight = cheapBestFastFlight.reduce((min, pair) => {
          return pair.priceWithduration < min.priceWithduration ? pair : min;
        }, cheapBestFastFlight[0]);
        setFilteredFlights(flightWisePrice);
        setFlightsData(flightWisePrice);
        setMinPrice(flightWisePrice?.[0]?.totalPrice);
        setMaxPrice(flightWisePrice[flightWisePrice?.length - 1]?.totalPrice);
        setselectedMaxPrice(
          flightWisePrice[flightWisePrice?.length - 1]?.totalPrice
        );
        setDeptValue([
          parseTimeValue(departureFromDest[0]),
          parseTimeValue(departureFromDest[departureFromDest.length - 1]),
        ]);
        setArrivValue([
          parseTimeValue(departureFromOrigiin[0]),
          parseTimeValue(departureFromOrigiin[departureFromOrigiin.length - 1]),
        ]);
        setdepartureData(departureFromDest);
        setarrivalData(departureFromOrigiin);
        settotalStopCount(res.totalStopCount);
        setUpdateStopCount(res.totalStopCount);
        setpriceWithAirlines(uniqueAirlines);
        setSelectedAirlines(res.selectedAirline);
        setcheapestFlight(checkCheapestFlight);
        setfastestFlight(checkFastestFlight);
        setbestFlight(checkBestFlight);
        setflightAirlineCodes(uniqueAirlines?.map((data) => data.airline_code));
        setdirectStopWithAirlinePrice(
          stopWisePrice?.filter((data) => data.layovers === 0)
        );
        setonePlusWithAirlinePrice(
          stopWisePrice?.filter((data) => data.layovers === 1)
        );
      } else {
        const departureFromDest = res.flightIndex?.[0].groupOfFlights
          .map(
            (flight) =>
              flight?.flightDetails?.[0]?.flightInformation?.[0]
                ?.productDateTime?.[0]?.timeOfDeparture?.[0]
          )
          .sort((a, b) => new Date(a) - new Date(b));
        const stopWisePrice = [];
        const flightWisePrice = res.combinationsArray
          ?.map((data) => {
            const flight1 =
              res.flightIndex?.[0]?.groupOfFlights[data?.outboundFlight];
            const f1Airline =
              flight1?.flightDetails?.[0]?.flightInformation?.[0]
                ?.companyId?.[0]?.customCarriers?.airline_name;
            const stopsMinPrice = {};
            stopsMinPrice.airlineName = f1Airline;
            stopsMinPrice.layovers = data.layovers;
            stopsMinPrice.price = data.customPrice / state.totalCounts;
            stopsMinPrice.airlineCode = data?.outboundFlightAirline;
            updateArray(stopWisePrice, stopsMinPrice);
            const totalPrice =
              parseFloat(data?.customPrice) / state.totalCounts;
            const finalPrice = parseFloat(data?.customPrice);
            const adultPrice = parseFloat(data?.ADT);
            const childPrice = data?.CNN ? parseFloat(data?.CNN) : 0;
            const flight1Duration = flightTotalDuration(
              flight1?.flightDetails?.[0]?.flightInformation?.[0]
                ?.productDateTime?.[0]?.timeOfDeparture?.[0],
              flight1?.flightDetails?.[0]?.flightInformation?.[0]
                ?.productDateTime?.[0]?.dateOfDeparture?.[0],
              flight1?.flightDetails[flight1?.flightDetails?.length - 1]
                ?.flightInformation?.[0]?.productDateTime?.[0]
                ?.timeOfArrival?.[0],
              flight1?.flightDetails[flight1?.flightDetails?.length - 1]
                ?.flightInformation?.[0]?.productDateTime?.[0]
                ?.dateOfArrival?.[0]
            );
            const totalDuration = flight1Duration;
            return {
              flight1,
              totalPrice,
              finalPrice,
              adultPrice,
              childPrice,
              markUp: data?.markUp,
              totalDuration,
            };
          })
          .sort((a, b) => a.totalPrice - b.totalPrice);
        const allAirlines = flightWisePrice?.map((data) => {
          const flight1 =
            data?.flight1?.flightDetails?.[0]?.flightInformation?.[0]
              ?.companyId?.[0]?.customCarriers?.airline_name;
          const totalPrice = parseFloat(data?.totalPrice);
          const airlineCode =
            data?.flight1?.flightDetails?.[0]?.flightInformation?.[0]
              ?.companyId?.[0]?.customCarriers?.airline_code;
          const image =
            data?.flight1?.flightDetails?.[0]?.flightInformation?.[0]
              ?.companyId?.[0]?.customCarriers?.image;

          return {
            airline: flight1,
            totalPrice,
            airline_code: airlineCode,
            image,
          };
        });
        const airlineMinPrices = allAirlines?.reduce((acc, curr) => {
          const { airline, totalPrice, airline_code, image } = curr;
          if (!acc[airline] || totalPrice < acc[airline]) {
            acc[airline] = {
              totalPrice: totalPrice.toFixed(2),
              airline_code,
              image,
            };
          }
          return acc;
        }, {});
        const uniqueAirlines = Object.keys(airlineMinPrices).map((airline) => ({
          airline_name: airline,
          totalPrice: airlineMinPrices[airline].totalPrice,
          airline_code: airlineMinPrices[airline].airline_code,
          image: airlineMinPrices[airline].image,
        }));
        const cheapBestFastFlight = flightWisePrice?.map(
          ({ flight1, totalPrice }) => {
            const flight1Duration = flightTotalDuration(
              flight1?.flightDetails?.[0]?.flightInformation?.[0]
                ?.productDateTime?.[0]?.timeOfDeparture?.[0],
              flight1?.flightDetails?.[0]?.flightInformation?.[0]
                ?.productDateTime?.[0]?.dateOfDeparture?.[0],
              flight1?.flightDetails[flight1?.flightDetails?.length - 1]
                ?.flightInformation?.[0]?.productDateTime?.[0]
                ?.timeOfArrival?.[0],
              flight1?.flightDetails[flight1?.flightDetails?.length - 1]
                ?.flightInformation?.[0]?.productDateTime?.[0]
                ?.dateOfArrival?.[0]
            );
            const totalDuration = flight1Duration;
            const priceWithduration = totalPrice * totalDuration;
            return {
              duration: totalDuration,
              price: totalPrice,
              priceWithduration,
            };
          }
        );
        const checkCheapestFlight = cheapBestFastFlight.reduce((min, pair) => {
          return pair.price < min.price ? pair : min;
        }, cheapBestFastFlight[0]);
        const checkFastestFlight = cheapBestFastFlight.reduce((min, pair) => {
          return pair.duration < min.duration ? pair : min;
        }, cheapBestFastFlight[0]);
        const checkBestFlight = cheapBestFastFlight.reduce((min, pair) => {
          return pair.priceWithduration < min.priceWithduration ? pair : min;
        }, cheapBestFastFlight[0]);
        setFilteredFlights(flightWisePrice);
        setFlightsData(flightWisePrice);
        setMinPrice(flightWisePrice?.[0]?.totalPrice);
        setMaxPrice(flightWisePrice[flightWisePrice?.length - 1]?.totalPrice);
        setselectedMaxPrice(
          flightWisePrice[flightWisePrice?.length - 1]?.totalPrice
        );
        setDeptValue([
          parseTimeValue(departureFromDest[0]),
          parseTimeValue(departureFromDest[departureFromDest.length - 1]),
        ]);
        setdepartureData(departureFromDest);
        settotalStopCount(res.totalStopCount);
        setUpdateStopCount(res.totalStopCount);
        setpriceWithAirlines(uniqueAirlines);
        setSelectedAirlines(res.selectedAirline);
        setcheapestFlight(checkCheapestFlight);
        setfastestFlight(checkFastestFlight);
        setbestFlight(checkBestFlight);
        setflightAirlineCodes(uniqueAirlines?.map((data) => data.airline_code));
        setdirectStopWithAirlinePrice(
          stopWisePrice.filter((data) => data.layovers === 0)
        );
        setonePlusWithAirlinePrice(
          stopWisePrice.filter((data) => data.layovers === 1)
        );
      }
      setloader(false);
    } else {
      setloader(false);
    }
  };

  // Over all filter for tickets
  const handleFilterFlights = ({
    selectedAirlines,
    deptValue,
    arrivValue,
    updateStopCount,
    selectedMaxPrice,
    filterType,
  }) => {
    if (selectedAirlines?.length === 0) {
      setFilteredFlights([]);
      return;
    }

    if (sessionStorage.getItem("wayOfFlight") === "round_trip") {
      let filteredArray = flightsData?.filter((flight) => {
        const departureTime = parseTimeValue(
          flight?.flight1?.flightDetails?.[0]?.flightInformation?.[0]
            ?.productDateTime?.[0]?.timeOfDeparture?.[0]
        );
        const arrivalTime = parseTimeValue(
          flight?.flight2?.flightDetails?.[0]?.flightInformation?.[0]
            ?.productDateTime?.[0]?.timeOfDeparture?.[0]
        );
        const selectedMinArrivalTime = arrivValue[0];
        const selectedMaxArrivalTime = arrivValue[1];
        const selectedMinTime = deptValue[0];
        const selectedMaxTime = deptValue[1];
        return (
          departureTime >= selectedMinTime &&
          departureTime <= selectedMaxTime &&
          arrivalTime >= selectedMinArrivalTime &&
          arrivalTime <= selectedMaxArrivalTime
        );
      });
      filteredArray = filteredArray?.filter((flight) => {
        return filterType === undefined
          ? flight?.totalPrice >= selectedMaxPrice
          : flight?.totalPrice <= selectedMaxPrice;
      });
      filteredArray = filteredArray?.filter((flight) => {
        return (
          flight?.flight1?.flightDetails.some((detail) =>
            detail.flightInformation.some((info) =>
              info.companyId.some((company) =>
                selectedAirlines.includes(company.marketingCarrier?.[0])
              )
            )
          ) &&
          flight?.flight2?.flightDetails.some((detail) =>
            detail.flightInformation.some((info) =>
              info.companyId.some((company) =>
                selectedAirlines.includes(company.marketingCarrier?.[0])
              )
            )
          )
        );
      });

      filteredArray = filteredArray?.filter((flight) => {
        if (updateStopCount.includes("2+")) {
          return (
            ((updateStopCount.includes(
              (flight?.flight1?.flightDetails?.length - 1).toString()
            ) ||
              flight?.flight1?.flightDetails?.length - 1 > 1) &&
              updateStopCount.includes(
                (flight?.flight2?.flightDetails?.length - 1).toString()
              )) ||
            flight?.flight2?.flightDetails?.length - 1 > 1
          );
        } else {
          return (
            updateStopCount.includes(
              (flight?.flight1?.flightDetails?.length - 1).toString()
            ) &&
            updateStopCount.includes(
              (flight?.flight2?.flightDetails?.length - 1).toString()
            )
          );
        }
      });

      setFilteredFlights(filteredArray);
    } else {
      let filteredFlights = flightsData?.filter((flight) => {
        const departureTime = parseTimeValue(
          flight?.flight1?.flightDetails?.[0]?.flightInformation?.[0]
            ?.productDateTime?.[0]?.timeOfDeparture?.[0]
        );
        const selectedMinTime = deptValue[0];
        const selectedMaxTime = deptValue[1];
        return (
          departureTime >= selectedMinTime && departureTime <= selectedMaxTime
        );
      });

      filteredFlights = filteredFlights?.filter((flightGroup) => {
        return flightGroup?.flight1?.flightDetails.some((detail) =>
          detail.flightInformation.some((info) =>
            info.companyId.some((company) =>
              selectedAirlines.includes(company.marketingCarrier?.[0])
            )
          )
        );
      });

      filteredFlights = filteredFlights?.filter((flight) => {
        if (updateStopCount.includes("2+")) {
          return (
            updateStopCount.includes(
              (flight?.flight1?.flightDetails?.length - 1).toString()
            ) && flight?.flight1?.flightDetails?.length - 1 > 1
          );
        } else {
          return updateStopCount.includes(
            (flight?.flight1?.flightDetails?.length - 1).toString()
          );
        }
      });

      filteredFlights = filteredFlights?.filter((flight) => {
        return filterType === undefined
          ? selectedMaxPrice <= flight?.totalPrice
          : selectedMaxPrice >= flight?.totalPrice;
      });
      setFilteredFlights(filteredFlights);
    }
  };

  const handleChangePrice = (event, newValue, filterType) => {
    if (newValue !== undefined) {
      setselectedMaxPrice(newValue);
      setFilterType(filterType);
      handleFilterFlights({
        selectedAirlines,
        deptValue,
        arrivValue,
        updateStopCount,
        selectedMaxPrice: newValue,
        filterType,
      });
    } else {
      const clickedValue = event.target.getAttribute("data-value");
      if (clickedValue) {
        const parsedValue = parseFloat(clickedValue);
        setselectedMaxPrice(parsedValue);
        setFilterApplied(true);
        setFilterType(filterType);
        handleFilterFlights({
          selectedAirlines,
          deptValue,
          arrivValue,
          updateStopCount,
          selectedMaxPrice: parsedValue,
          filterType,
        });
      }
    }
  };

  // stops checkbox function
  // const handleCheckBoxChange = (name) => {
  //   if (updateStopCount.includes(name)) {
  //     setUpdateStopCount(updateStopCount.filter((dt) => dt !== name));
  //   } else {
  //     setUpdateStopCount([...updateStopCount, name]);
  //   }
  // };
  const handleCheckBoxChange = (name) => {
    let updatedStopCount;
    if (updateStopCount.includes(name)) {
      updatedStopCount = updateStopCount.filter((dt) => dt !== name);
    } else {
      updatedStopCount = [...updateStopCount, name];
    }
    setUpdateStopCount(updatedStopCount);
    handleFilterFlights({
      selectedAirlines,
      deptValue,
      arrivValue,
      updateStopCount: updatedStopCount,
      selectedMaxPrice,
      filterType,
    });
  };

  // airline checkbox function
  const handleSelectAll = () => {
    const allAirlines = priceWithAirlines.map((d) => d.airline_code);
    setSelectAllFlights(true);
    setSelectedAirlines(allAirlines);
    handleFilterFlights({
      selectedAirlines: allAirlines,
      deptValue,
      arrivValue,
      updateStopCount,
      selectedMaxPrice,
      filterType,
    });
  };

  const handleClearAll = () => {
    setSelectAllFlights(false);
    setSelectedAirlines([]);
    handleFilterFlights({
      selectedAirlines: [],
      deptValue,
      arrivValue,
      updateStopCount,
      selectedMaxPrice,
      filterType,
    });
  };

  const handleAirlineCheckboxChange = (airline) => {
    setSelectedAirlines((prevSelectedAirlines) => {
      let updatedAirlines;
      if (prevSelectedAirlines.includes(airline)) {
        updatedAirlines = prevSelectedAirlines.filter(
          (selectedAirline) => selectedAirline !== airline
        );
      } else {
        updatedAirlines = [...prevSelectedAirlines, airline];
      }
      if (
        updatedAirlines.includes("MixedAirline123") &&
        updatedAirlines.length === 1
      ) {
        updatedAirlines = [];
      }
      handleFilterFlights({
        selectedAirlines: updatedAirlines,
        deptValue,
        arrivValue,
        updateStopCount,
        selectedMaxPrice,
        filterType,
      });
      return updatedAirlines;
    });
  };

  // time slider function
  const handleChangeDeptValue = (event, newValue) => {
    setDeptValue(newValue);
    handleFilterFlights({
      selectedAirlines,
      deptValue: newValue,
      arrivValue,
      updateStopCount,
      selectedMaxPrice,
      filterType,
    });
  };

  const handleChangeArrivValue = (event, newValue) => {
    setArrivValue(newValue);
    handleFilterFlights({
      selectedAirlines,
      deptValue,
      arrivValue: newValue,
      updateStopCount,
      selectedMaxPrice,
      filterType,
    });
  };

  // clear price filter
  const handleCleaPriceFilter = () => {
    setFilterApplied(false);
    handleUpdate();
    setFilterType(false);
    setselectedMaxPrice(null);
  };

  const submit = (item) => {
    sessionStorage.setItem("booking_details", JSON.stringify(item));
    sessionStorage.setItem("booking_login", "yes");
    navigate("/checkout");
  };

  return (
    <>
      <div className="d-flex flex-column">
        {/* Large Screen Header */}
        <header className="sticky-top bg-light p-3 flight-page-header-lg">
          <div className="mt-0 shadow-none">
            <div className="d-flex align-items-center justify-content-center gap-3 w-100 overflow-auto text-nowrap">
              <span
                className="btn bg-white rounded-10p text-capitalize"
                data-bs-toggle="modal"
                data-bs-target="#flight_root_modal"
              >
                {state.trip === "round_trip" ? "Round Trip" : "One Way"}
              </span>
              <div className="d-flex align-items-center gap-1">
                <div
                  className="form-group"
                  data-bs-toggle="modal"
                  data-bs-target="#flight_root_modal"
                >
                  <input
                    type="text"
                    className="banner-form-input text-secondary"
                    required
                    placeholder="From"
                    ref={fromInputRef}
                    value={`${state.city_from} (${state.iatacode_from})`}
                  />
                </div>
                <span
                  className="btn btn-icon-sm theme-btn-primary p-2"
                  data-bs-toggle="modal"
                  data-bs-target="#flight_root_modal"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="currentColor"
                    className="bi bi-arrow-down-up"
                    viewBox="0 0 16 16"
                    style={{ rotate: "90deg" }}
                  >
                    <path
                      fill-rule="evenodd"
                      d="M11.5 15a.5.5 0 0 0 .5-.5V2.707l3.146 3.147a.5.5 0 0 0 .708-.708l-4-4a.5.5 0 0 0-.708 0l-4 4a.5.5 0 1 0 .708.708L11 2.707V14.5a.5.5 0 0 0 .5.5m-7-14a.5.5 0 0 1 .5.5v11.793l3.146-3.147a.5.5 0 0 1 .708.708l-4 4a.5.5 0 0 1-.708 0l-4-4a.5.5 0 0 1 .708-.708L4 13.293V1.5a.5.5 0 0 1 .5-.5"
                    />
                  </svg>
                </span>
                <div
                  className="form-group"
                  data-bs-toggle="modal"
                  data-bs-target="#flight_root_modal"
                >
                  <input
                    type="text"
                    className="banner-form-input text-secondary"
                    required
                    placeholder="To"
                    ref={toInputRef}
                    value={`${state.city_to} (${state.iatacode_to})`}
                  />
                </div>
              </div>
              <div className="d-flex align-items-center gap-1">
                <div
                  className="form-group"
                  data-bs-toggle="modal"
                  data-bs-target="#flight_root_modal"
                >
                  <input
                    value={state.departuredate.format("MM-DD-YYYY")}
                    className="banner-form-input text-secondary"
                  />
                </div>
                {state.trip === "round_trip" && (
                  <div
                    className="form-group"
                    data-bs-toggle="modal"
                    data-bs-target="#flight_root_modal"
                  >
                    <input
                      value={state.returndate.format("MM-DD-YYYY")}
                      className="banner-form-input text-secondary"
                    />
                  </div>
                )}
              </div>
              <span
                className="btn btn-sm theme-btn-outline-primary-dark rounded-10p"
                data-bs-toggle="modal"
                data-bs-target="#add_passengers_modal"
              >
                {parseInt(state.adult) +
                  parseInt(state.child) +
                  parseInt(state.infants)}{" "}
                Traveller, {state.t_class_selected}
              </span>
              <button
                type="submit"
                className="btn theme-btn-primary btn-sm rounded-10p d-flex align-items-center gap-2"
                onClick={handleUpdate}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="currentColor"
                  className="bi bi-search"
                  viewBox="0 0 16 16"
                >
                  <path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001q.044.06.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1 1 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0" />
                </svg>
                Update
              </button>
            </div>
          </div>
        </header>
        {/* Small Screen Header */}
        <header className="sticky-top bg-light flight-page-header-sm px-1">
          <div className="d-flex gap-3 container">
            <div
              className="d-flex flex-column align-items-center btn btn-rounded bg-white custom-card-shadow w-responsive"
              data-bs-toggle="modal"
              data-bs-target="#flight_root_modal"
              style={{ marginTop: "7.5px" }}
            >
              <p className="mb-0 fw-bold d-flex align-items-center gap-3">
                {state.iatacode_from}
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="currentColor"
                  className="bi bi-arrow-down-up"
                  viewBox="0 0 16 16"
                  style={{ rotate: "90deg" }}
                >
                  <path
                    fill-rule="evenodd"
                    d="M11.5 15a.5.5 0 0 0 .5-.5V2.707l3.146 3.147a.5.5 0 0 0 .708-.708l-4-4a.5.5 0 0 0-.708 0l-4 4a.5.5 0 1 0 .708.708L11 2.707V14.5a.5.5 0 0 0 .5.5m-7-14a.5.5 0 0 1 .5.5v11.793l3.146-3.147a.5.5 0 0 1 .708.708l-4 4a.5.5 0 0 1-.708 0l-4-4a.5.5 0 0 1 .708-.708L4 13.293V1.5a.5.5 0 0 1 .5-.5"
                  />
                </svg>
                {state.iatacode_to}
              </p>
              <span className="d-flex align-items-center gap-1 fs-sm d-block-mw-420p">
                {state.departuredate.format("MM-DD-YYYY")}
                {state.trip === "round_trip" && (
                  <>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="currentColor"
                      className="bi bi-dash"
                      viewBox="0 0 16 16"
                    >
                      <path d="M4 8a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7A.5.5 0 0 1 4 8" />
                    </svg>
                    {state.returndate.format("MM-DD-YYYY")}
                  </>
                )}
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="currentColor"
                  className="bi bi-dot"
                  viewBox="0 0 16 16"
                >
                  <path d="M8 9.5a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3" />
                </svg>
                {parseInt(state.adult) +
                  parseInt(state.child) +
                  parseInt(state.infants)}{" "}
                Traveller
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="currentColor"
                  className="bi bi-dot"
                  viewBox="0 0 16 16"
                >
                  <path d="M8 9.5a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3" />
                </svg>
                {state.t_class_selected}
              </span>
              <div className="marquee d-none-mw-420p">
                <span className="d-flex align-items-center gap-1 fs-sm">
                  {state.departuredate.format("MM-DD-YYYY")}
                  {state.trip === "round_trip" && (
                    <>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        fill="currentColor"
                        className="bi bi-dash"
                        viewBox="0 0 16 16"
                      >
                        <path d="M4 8a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7A.5.5 0 0 1 4 8" />
                      </svg>
                      {state.returndate.format("MM-DD-YYYY")}
                    </>
                  )}
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="currentColor"
                    className="bi bi-dot"
                    viewBox="0 0 16 16"
                  >
                    <path d="M8 9.5a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3" />
                  </svg>
                  {state.adult + state.child + state.infants} Traveller
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="currentColor"
                    className="bi bi-dot"
                    viewBox="0 0 16 16"
                  >
                    <path d="M8 9.5a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3" />
                  </svg>
                  {state.t_class_selected}
                </span>
              </div>
            </div>
            <div
              className="btn rounded-10p bg-white custom-card-shadow flight-page-sidebar-sm"
              data-bs-toggle="modal"
              data-bs-target="#filter_modal"
              style={{ marginTop: "7.5px" }}
            >
              <span className="d-flex align-items-center h-100">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="currentColor"
                  className="bi bi-funnel-fill"
                  viewBox="0 0 16 16"
                >
                  <path d="M1.5 1.5A.5.5 0 0 1 2 1h12a.5.5 0 0 1 .5.5v2a.5.5 0 0 1-.128.334L10 8.692V13.5a.5.5 0 0 1-.342.474l-3 1A.5.5 0 0 1 6 14.5V8.692L1.628 3.834A.5.5 0 0 1 1.5 3.5z" />
                </svg>
              </span>
            </div>
          </div>
        </header>
        {loader ? (
          <FlightsPageLoader
            origin={state.iatacode_from}
            destination={state.iatacode_to}
          />
        ) : (
          <div className="d-flex justify-content-center bg-theme-secondary custom-container">
            {/* Sidebar */}
            <FilterSideBar
              minPrice={minPrice}
              maxPrice={maxPrice}
              selectedMaxPrice={selectedMaxPrice}
              handleChangePrice={handleChangePrice}
              deptValue={deptValue}
              arrivValue={arrivValue}
              departureData={departureData}
              arrivalData={arrivalData}
              stops={stops}
              updateStopCount={updateStopCount}
              totalStopCount={totalStopCount}
              handleCheckBoxChange={handleCheckBoxChange}
              priceWithAirlines={priceWithAirlines}
              selectedAirlines={selectedAirlines}
              handleSelectAll={handleSelectAll}
              handleClearAll={handleClearAll}
              handleAirlineCheckboxChange={handleAirlineCheckboxChange}
              handleChangeDeptValue={handleChangeDeptValue}
              handleChangeArrivValue={handleChangeArrivValue}
            />
            {/* Body */}
            <div className="d-flex flex-column p-3 flight-body gap-3">
              <>
                <FlightFilterTable
                  priceWithAirlines={priceWithAirlines}
                  flightAirlineCodes={flightAirlineCodes}
                  directStopWithAirlinePrice={directStopWithAirlinePrice}
                  onePlusWithAirlinePrice={onePlusWithAirlinePrice}
                  selectedMaxPrice={selectedMaxPrice}
                  handleChangePrice={handleChangePrice}
                  handleCheckBoxChange={handleCheckBoxChange}
                />
                <FlightFilterCard
                  isActive={isActive}
                  setIsActive={setIsActive}
                  handleChangePrice={handleChangePrice}
                  cheapestFlight={cheapestFlight}
                  bestFlight={bestFlight}
                  fastestFlight={fastestFlight}
                />
                {filterApplied && (
                  <div className="d-flex gap-2 overflow-auto p-1">
                    <span className="active_price_chip">
                      <span className="px-2 text-nowrap">
                        $ {selectedMaxPrice?.toFixed(2)}
                      </span>
                      <span
                        className="clear_active_price_chip"
                        onClick={handleCleaPriceFilter}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="16"
                          height="16"
                          fill="currentColor"
                          class="bi bi-x-lg"
                          viewBox="0 0 16 16"
                        >
                          <path d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8z" />
                        </svg>
                      </span>
                    </span>
                  </div>
                )}
                {filteredFlights?.length > 0 && (
                  <>
                    {sessionStorage.getItem("wayOfFlight") === "round_trip" ? (
                      <RoundTripTicket
                        filteredFlights={filteredFlights}
                        visibleTickets={visibleTickets}
                        handleLoadMore={handleLoadMore}
                        selectedMaxPrice={selectedMaxPrice}
                        filterType={filterType}
                        submit={submit}
                        isActive={isActive}
                      />
                    ) : (
                      <OneWayTicket
                        filteredFlights={filteredFlights}
                        visibleTickets={visibleTickets}
                        handleLoadMore={handleLoadMore}
                        submit={submit}
                        isActive={isActive}
                      />
                    )}
                  </>
                )}
                {(filteredFlights?.length === 0 ||
                  updateStopCount?.length === 0) && (
                  <h3 className=" d-flex justify-content-center mt-5 text-dark">
                    No Flights Available
                  </h3>
                )}
              </>
            </div>
          </div>
        )}
      </div>

      <FlightsRootModal
        handleToggle={handleToggle}
        fromInputRef={fromInputRef}
        toInputRef={toInputRef}
        state={state}
        setState={setState}
        handleChange={handleChange}
        apiToken={apiToken}
        handleUpdate={handleUpdate}
      />

      {/* Filter Modal For Small Screens */}
      <FilterSideBarSmallScreen
        minPrice={minPrice}
        maxPrice={maxPrice}
        selectedMaxPrice={selectedMaxPrice}
        handleChangePrice={handleChangePrice}
        deptValue={deptValue}
        arrivValue={arrivValue}
        departureData={departureData}
        arrivalData={arrivalData}
        stops={stops}
        updateStopCount={updateStopCount}
        totalStopCount={totalStopCount}
        handleCheckBoxChange={handleCheckBoxChange}
        priceWithAirlines={priceWithAirlines}
        selectedAirlines={selectedAirlines}
        handleSelectAll={handleSelectAll}
        handleClearAll={handleClearAll}
        handleAirlineCheckboxChange={handleAirlineCheckboxChange}
        handleChangeDeptValue={handleChangeDeptValue}
        handleChangeArrivValue={handleChangeArrivValue}
      />

      <PassengersModal
        adults={state.adult}
        children={state.child}
        infants={state.infants}
        travelClass={state.t_class}
        selectedTravelClass={state.t_class_selected}
        setState={setState}
        onChange={handleChange}
      />
    </>
  );
};

export default Flights;
